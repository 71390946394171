import * as React from 'react';

export const PlayIcon = () => (
  <svg
    width="9"
    height="12"
    viewBox="0 0 9 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 6L4.64275e-07 11.1962L9.18537e-07 0.803847L9 6Z"
      fill="black"
    ></path>
  </svg>
);

export const CloseIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="0.646447"
      y1="12.667"
      x2="12.6673"
      y2="0.646139"
      stroke="black"
    ></line>
    <line
      x1="0.353553"
      y1="0.666954"
      x2="12.3744"
      y2="12.6878"
      stroke="black"
    ></line>
  </svg>
);

export const CalendarIcon = () => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.25 0.25H4.75V2V2.25H5H13H13.25V2V0.25H14.75V2V2.25H15H16C16.9619 2.25 17.75 3.03807 17.75 4V18C17.75 18.9619 16.9619 19.75 16 19.75H2C1.02892 19.75 0.25005 18.9628 0.25 18.0001C0.25 18.0001 0.25 18 0.25 18L0.26 4.00018V4C0.26 3.03561 1.03052 2.25 2 2.25H3H3.25V2V0.25ZM1.75 6V6.25H2H16H16.25V6V4V3.75H16H2H1.75V4V6ZM16 18.25H16.25V18V8V7.75H16H2H1.75V8V18V18.25H2H16ZM5.75 9.25V10.75H4.25V9.25H5.75ZM13.75 9.25V10.75H12.25V9.25H13.75ZM9.75 9.25V10.75H8.25V9.25H9.75Z"
      strokeWidth="0.5"
    ></path>
  </svg>
);

export const CaretIcon = ({ thin }) => {
  return (
    <svg
      width="5"
      height="5"
      viewBox="0 0 5 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {thin && (
        <mask id="path-1-inside-1" fill="white">
          <path d="M0.5875 0.958008L2.5 2.90341L4.4125 0.958008L5 1.55692L2.5 4.10548L0 1.55692L0.5875 0.958008Z"></path>
        </mask>
      )}
      <path
        d="M0.5875 0.958008L1.30061 0.256957L0.586722 -0.469215L-0.126376 0.257735L0.5875 0.958008ZM2.5 2.90341L1.78689 3.60446L2.5 4.32984L3.21311 3.60446L2.5 2.90341ZM4.4125 0.958008L5.12638 0.257735L4.41328 -0.469215L3.69939 0.256957L4.4125 0.958008ZM5 1.55692L5.71388 2.25719L6.4008 1.55692L5.71388 0.856648L5 1.55692ZM2.5 4.10548L1.78612 4.80576L2.5 5.5335L3.21388 4.80576L2.5 4.10548ZM0 1.55692L-0.713876 0.856648L-1.4008 1.55692L-0.713876 2.25719L0 1.55692ZM-0.125612 1.65906L1.78689 3.60446L3.21311 2.20236L1.30061 0.256957L-0.125612 1.65906ZM3.21311 3.60446L5.12561 1.65906L3.69939 0.256957L1.78689 2.20236L3.21311 3.60446ZM3.69862 1.65828L4.28612 2.25719L5.71388 0.856648L5.12638 0.257735L3.69862 1.65828ZM4.28612 0.856648L1.78612 3.40521L3.21388 4.80576L5.71388 2.25719L4.28612 0.856648ZM3.21388 3.40521L0.713876 0.856648L-0.713876 2.25719L1.78612 4.80576L3.21388 3.40521ZM0.713876 2.25719L1.30138 1.65828L-0.126376 0.257735L-0.713876 0.856648L0.713876 2.25719Z"
        fill="black"
        mask={thin ? 'url(#path-1-inside-1)' : undefined}
      ></path>
    </svg>
  );
};

export const ChartIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 16.25H16.25V16V2V1.75H16H2H1.75V2V16V16.25H2H16ZM2 0.25H16C16.9619 0.25 17.75 1.03807 17.75 2V16C17.75 16.9619 16.9619 17.75 16 17.75H2C1.03807 17.75 0.25 16.9619 0.25 16V2C0.25 1.03807 1.03807 0.25 2 0.25ZM4.25 7.25H5.75V13.75H4.25V7.25ZM8.25 4.25H9.75V13.75H8.25V4.25ZM12.25 10.25H13.75V13.75H12.25V10.25Z"
        strokeWidth="0.5"
      ></path>
    </svg>
  );
};

export const ClipboardIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5849 2.08511L11.6446 2.25H11.82H16C16.9619 2.25 17.75 3.03807 17.75 4V18C17.75 18.9619 16.9619 19.75 16 19.75H2C1.87464 19.75 1.75924 19.7412 1.64419 19.7238C1.30903 19.6538 1.00532 19.4809 0.769141 19.2456C0.605656 19.0726 0.475958 18.8883 0.390769 18.6838C0.302242 18.4714 0.25 18.234 0.25 18V4C0.25 3.7561 0.302205 3.52993 0.389268 3.32968L0.389295 3.32969L0.390769 3.32615C0.477788 3.11731 0.609078 2.92447 0.766777 2.76678C1.0047 2.52885 1.31156 2.35437 1.65024 2.2849L1.65025 2.28497L1.65621 2.2836C1.76199 2.25919 1.87188 2.25 2 2.25H6.18H6.35537L6.41507 2.08511C6.80137 1.01817 7.8114 0.25 9 0.25C10.1886 0.25 11.1986 1.01817 11.5849 2.08511ZM16 18.25H16.25V18V4V3.75H16H2H1.75V4V18V18.25H2H16ZM4.25 14.25H10.75V15.75H4.25V14.25ZM4.25 10.25H13.75V11.75H4.25V10.25ZM4.25 6.25H13.75V7.75H4.25V6.25ZM10 2.5C10 1.95193 9.54807 1.5 9 1.5C8.45193 1.5 8 1.95193 8 2.5C8 3.04807 8.45193 3.5 9 3.5C9.54807 3.5 10 3.04807 10 2.5Z"
        strokeWidth="0.5"
      ></path>
    </svg>
  );
};

export const CrossIcon = () => {
  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        y1="-0.5"
        x2="13.1442"
        y2="-0.5"
        transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 1 9.29395)"
        stroke="#4A0080"
      ></line>
      <line
        x1="10.5046"
        y1="9.64848"
        x2="1.21024"
        y2="0.354118"
        stroke="#4A0080"
      ></line>
    </svg>
  );
};

export const VideoIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 12C2.5 6.75614 6.75614 2.5 12 2.5C17.2439 2.5 21.5 6.75614 21.5 12C21.5 17.2439 17.2439 21.5 12 21.5C6.75614 21.5 2.5 17.2439 2.5 12Z"
      stroke="white"
    />
    <path d="M11 10L13.6667 12L11 14V10Z" stroke="white" />
  </svg>
);

export const MicrophoneIcon = () => (
  <svg
    width="14"
    height="19"
    viewBox="0 0 14 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.25 15.9201V15.7037L6.03576 15.6727C2.84645 15.2117 0.374236 12.5393 0.254541 9.25012H1.75586C1.88648 12.0322 4.18573 14.2501 7 14.2501C9.81427 14.2501 12.1135 12.0322 12.2441 9.25012H13.7455C13.6258 12.5393 11.1536 15.2117 7.96424 15.6727L7.75 15.7037V15.9201V18.7501H6.25V15.9201ZM9.75 9.00012C9.75 10.5221 8.52193 11.7501 7 11.7501C5.47807 11.7501 4.25 10.5221 4.25 9.00012V3.00012C4.25 1.47819 5.47807 0.250122 7 0.250122C8.52193 0.250122 9.75 1.47819 9.75 3.00012V9.00012ZM7 1.75012C6.31193 1.75012 5.75 2.31205 5.75 3.00012V9.00012C5.75 9.68819 6.31193 10.2501 7 10.2501C7.68807 10.2501 8.25 9.68819 8.25 9.00012V3.00012C8.25 2.31205 7.68807 1.75012 7 1.75012Z"
      fill="white"
      stroke="black"
      strokeWidth="0.5"
    />
  </svg>
);

export const InsightTypeIcon = ({ type = '' }) => {
  const lowerType = type.toLowerCase();
  return (
    <>
      {/* @TODO: check */}
      {lowerType === 'report' && <ClipboardIcon />}
      {lowerType === 'case study' && <ChartIcon />}
      {lowerType === 'video series' && <VideoIcon />}
      {lowerType === 'event' && <CalendarIcon />}
      {lowerType === 'podcast' && <MicrophoneIcon />}
      {lowerType === 'redefiners podcast' && <MicrophoneIcon />}
      {/* @TODO: fix */}
      {lowerType === 'article' && <CalendarIcon />}
      {lowerType === 'refiners podcast' && <CalendarIcon />}
    </>
  );
};
