import * as React from 'react';
import { uniq, flatten } from 'lodash';
import classNames from 'classnames';
import { initializeAnimation } from '../global-animation';
import { getImagePath } from '../util';

export const Pager = ({
  gutterTop = true,
  gutterBottom = true,
  page,
  totalResults,
  pageSize,
  onChange,
  updateUrl = false,
}) => {
  const location = window.location;
  const [itemHovered, setItemHovered] = React.useState(null);
  const pagerRef = React.useRef(null);
  const pagerItemsRef = React.useRef(new Array());

  function moveUnderline(target) {
    if (!target) return;
    setItemHovered({
      width: target.getBoundingClientRect().width,
      left: target.getBoundingClientRect().left,
    });
  }

  function handlePagerChange(nextPage) {
    if (!onChange || page === nextPage) return;
    onChange(nextPage);
    setUrl(nextPage);
  }

  const handlePagerItemClick = (item) => (e) => {
    e.preventDefault();
    if (onChange) onChange(item);
    setUrl(item);
  };

  function setUrl(page){
    if(updateUrl){
      let url = new URL(location.href);
      let params = new URLSearchParams(url.search);
      params.set('page', page);
      window.history.pushState(null, 'filter', location.pathname + '?' + decodeURIComponent(params.toString()));
    }
  }

  React.useLayoutEffect(() => {
    initializeAnimation(pagerRef.current);
  }, [pagerRef]);

  React.useLayoutEffect(() => {
    setTimeout(() => {
      if (
        !pagerItemsRef.current ||
        !pagerItemsRef.current.filter(Boolean).length
      )
        return;
      const pagerItemRef = pagerItemsRef.current.find(
        (i) => i.innerText === String(page)
      );
      if (!pagerItemRef || !pagerItemRef.children.length) return;
      const a = pagerItemRef.querySelector('a');
      if (!a) return;
      // first attempt to move underline to correct spot
      moveUnderline(a);
      // additional insurance underline is in the right spot
      setTimeout(() => moveUnderline(a), 500);
    }, 0);
  }, [page, totalResults]);

  const totalPages = Math.ceil(totalResults / pageSize);
  const visiblePages = uniq([1, page - 1, page, page + 1, totalPages])
    .filter((x) => x > 0)
    .filter((x) => x <= totalPages)
    .sort((a, b) => a - b);
  // map produces [1, [2, '...'], 45]
  // flatten modifies to [1, 2, '...', 45]
  const pages = flatten(
    visiblePages.map((page, index) =>
      index !== 0 && visiblePages[index - 1] + 1 < page ? ['...', page] : page
    )
  );

  return (
    <div
      className={classNames({
        pagination: true,
        'pagination--no-gutter-top': !gutterTop,
        'pagination--no-gutter-bottom': !gutterBottom,
      })}
      data-animation="false"
      ref={pagerRef}
    >
      <div className="pagination__inner">
        <button
          className="pagination__arrow pagination__arrow--left"
          data-glide-dir="<"
          aria-label="Previous"
          disabled={page === 1}
          onClick={() => handlePagerChange(page - 1)}
        >
          <span className="visually-hidden">Previous</span>
          <img
            className="icon carousel-caret"
            src={getImagePath('/images/carousel-caret.svg')}
            alt=""
            srcSet=""
          />
        </button>
        <ul className="pagination__items">
          {pages.map((item, index) => (
            <li
              ref={(el) => {
                pagerItemsRef.current[index] = el;
              }}
              className={classNames({ active: page === item })}
              key={index}
            >
              {Number.isInteger(item) && (
                <a
                  className={classNames({ selected: page === item })}
                  style={{}}
                  href="#"
                  onClick={handlePagerItemClick(item)}
                  onMouseEnter={(e) => moveUnderline(e.target)}
                >
                  {item}
                </a>
              )}
              {item === '...' && <span>{item}</span>}
            </li>
          ))}
        </ul>
        <button
          className="pagination__arrow pagination__arrow--right"
          data-glide-dir=">"
          aria-label="Next"
          disabled={page === totalPages}
          onClick={() => handlePagerChange(page + 1)}
        >
          <span className="visually-hidden">Next</span>
          <img
            className="icon carousel-caret"
            src={getImagePath('/images/carousel-caret.svg')}
            alt=""
            srcSet=""
          />
        </button>
        <span
          className={classNames({
            pagination__underline: true,
            hover: !!itemHovered,
          })}
          style={{
            width: itemHovered?.width,
            left: itemHovered?.left,
          }}
        ></span>
      </div>
    </div>
  );
};
