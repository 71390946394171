import classNames from 'classnames';
import moment from 'moment';
import { isArray } from 'core-js/core/array';
import { _sortPropTweensByPriority } from 'gsap/gsap-core';
import * as React from 'react';
import { getImagePath, placeholder1xImagePath } from '../util';
import { CaretIcon, CrossIcon, CalendarIcon } from './Icons';

export const HiddenFilterItem = ({ type = '', index = 0 }) => {
  return (
    <div
      id={type && Number.isInteger(index) ? `${type}${index}` : undefined}
      aria-label="no tag active"
      role="option"
      className="visually-hidden"
    ></div>
  );
};

export const FilterItem = ({
  index,
  tagName,
  tagCount,
  tagId,
  variant = 'insights',
  type = '',
  onToggleFilter,
  selected,
}) => {
  return (
    <div
      id={type && Number.isInteger(index) ? `${type}${index}` : undefined}
      aria-label={`${type ? `${type} ` : ''}${tagName}`}
      role="option"
      className={classNames({
        [`latest-insights-${variant}__item`]: true,
        [`latest-insights-${variant}__item--selected`]: selected,
      })}
      onClick={(e) => {
        e.stopPropagation();
        if (onToggleFilter) onToggleFilter({ tagName, tagId });
      }}
      onKeyDown={(e) => {}}
      onKeyPress={(e) => {
        if (e.key === 'Enter' && onToggleFilter)
          onToggleFilter({ tagName, tagId });
      }}
    >
      <CrossIcon />
      <p>{tagName}</p>
      <span>{tagCount}</span>
    </div>
  );
};

export const FilterNavItem = ({ className, children, ...props }) => {
  return (
    <div
      role="menuitem"
      className={classNames({
        'filter__nav-item': true,
        [className]: !!className,
      })}
      {...props}
    >
      {children}
    </div>
  );
};

export const FilterFocusNavItem = ({ className, onClick, children }) => {
  const [focus, setFocus] = React.useState(false);
  const [hover, setHover] = React.useState(false);
  return (
    <FilterNavItem
      className={classNames({
        [className]: !!className,
        hover: !!hover || !!focus,
      })}
    >
      <div
        tabIndex="0"
        onClick={onClick}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        onMouseEnter={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        {children}
      </div>
    </FilterNavItem>
  );
};

export const FilterSeparator = () => {
  return (
    <FilterNavItem className="filter__nav-item-separator">|</FilterNavItem>
  );
};

export const FilterDateRange = ({ active, name }) => {
  return (
    <FilterFocusNavItem
      className={classNames({
        'filter__dropdown-nav-item': true,
        hover: !!active,
      })}
    >
      {name}
    </FilterFocusNavItem>
  );
};

export const FilterDateRangeDropdown = ({ variant = 'insights', onChange }) => {
  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');

  function handleStartDateChange(e) {
    e.stopPropagation();
    const currentStartDate = e.target.value;
    setStartDate(currentStartDate);
    const currentEndDate =
      endDate && moment(endDate).isBefore(moment(currentStartDate))
        ? moment(currentStartDate).add(1, 'day').format('YYYY-MM-DD')
        : endDate;
    if (currentEndDate !== endDate) setEndDate(currentEndDate);
    if (!currentStartDate || !currentEndDate || !onChange) return;
    onChange(currentStartDate, currentEndDate);
  }

  function handleEndDateChange(e) {
    e.stopPropagation();
    const currentEndDate = e.target.value;
    setEndDate(currentEndDate);
    const currentStartDate =
      startDate && moment(startDate).isAfter(moment(currentEndDate))
        ? moment(currentEndDate).subtract(1, 'day').format('YYYY-MM-DD')
        : startDate;
    if (currentStartDate !== startDate) setStartDate(currentStartDate);
    if (!currentStartDate || !currentEndDate || !onChange) return;
    onChange(currentStartDate, currentEndDate);
  }

  return (
    <div className={`latest-insights-${variant}__filters-dropdown`}>
      <div className="layout-container">
        <div className={`latest-insights-${variant}__filters-dropdown-inner`}>
          <div className={`latest-insights-${variant}__content`}>
            <div className={`latest-insights-${variant}__items`}>
              <div className="filter__date-fields">
                <div className="filter__date-field">
                  <label
                    className="filter__date-field-label"
                    htmlFor="filter-start-date"
                  >
                    <CalendarIcon />
                  </label>
                  <input
                    className="filter__date-input"
                    id="filter-start-date"
                    type="date"
                    value={startDate}
                    onClick={(e) => e.stopPropagation()}
                    onChange={handleStartDateChange}
                  />
                </div>
                <h3>to</h3>
                <div className="filter__date-field">
                  <label
                    className="filter__date-field-label"
                    htmlFor="filter-end-date"
                  >
                    <CalendarIcon />
                  </label>
                  <input
                    className="filter__date-input"
                    id="filter-end-date"
                    type="date"
                    value={endDate}
                    onClick={(e) => e.stopPropagation()}
                    onChange={handleEndDateChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FilterTextLinkNavItem = ({ text, onClick, active }) => {
  return (
    <FilterFocusNavItem
      className={classNames({
        'filter__text-link-nav-item': true,
        hover: !!active,
      })}
      onClick={onClick}
    >
      {text}
    </FilterFocusNavItem>
  );
};

export const FilterImageLinkNavItem = ({ url, imageUrl, alt }) => {
  return (
    <FilterFocusNavItem
      className={classNames({
        'filter__image-link-nav-item': true,
      })}
    >
      <a href={url} target="_blank">
        <img src={imageUrl} alt={alt}></img>
      </a>
    </FilterFocusNavItem>
  );
};

export const FilterDropdownNavItem = ({ type, name }) => {
  return (
    <FilterNavItem
      className={classNames({
        'filter__dropdown-nav-item': true,
      })}
    >
      <div
        tabIndex="0"
        role="listbox"
        aria-label={type}
        aria-activedescendant={`${type}0`}
      >
        {name}
      </div>
      <CaretIcon thin />
    </FilterNavItem>
  );
};

export const FilterDropdown = ({
  variant = 'insights',
  filterType,
  type,
  filterItems = [],
  onToggleFilter,
  selectedFilters,
}) => {
  return (
    <div className={`latest-insights-${variant}__filters-dropdown`}>
      <div className="layout-container">
        <div className={`latest-insights-${variant}__filters-dropdown-inner`}>
          <div className={`latest-insights-${variant}__content`}>
            <div className={`latest-insights-${variant}__items`}>
              <HiddenFilterItem type={type} />
              {filterItems.map((filterItemProps, index) => (
                <FilterItem
                  variant={variant}
                  selected={
                    Array.isArray(selectedFilters)
                      ? selectedFilters.indexOf(filterItemProps.tagId) > -1
                      : selectedFilters === filterItemProps.tagId
                  }
                  index={index + 1}
                  type={type}
                  onToggleFilter={(tag) => onToggleFilter(filterType, tag)}
                  key={index}
                  {...filterItemProps}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DesktopFilter = ({
  variant = 'insights',
  dataFilters = {},
  filters = [],
  selectedTags = [],
  selectedFilters,
  containerRef,
  showTags = true,
  onClearFilters,
  onFilterChange,
  onToggleFilter,
  onRemoveFilter,
  filtersLabel,
  clearAllFiltersLabel,
}) => {
  return (
    <div
      className={`latest-insights-${variant}__filters-container`}
      data-animation="false"
      ref={containerRef}
    >
      <div className={`latest-insights-${variant}__filters`}>
        <div className="layout-container">
          <div
            role="menu"
            className={`latest-insights-${variant}__filters-nav`}
          >
            {filters.map((filter, index) => {
              return (
                <React.Fragment key={index}>
                  {filter.control === 'dropdown' && (
                    <FilterDropdownNavItem
                      type={filter.type}
                      name={filter.name}
                    />
                  )}
                  {filter.control === 'separator' && <FilterSeparator />}
                  {filter.control === 'text-link' && (
                    <FilterTextLinkNavItem
                      text={filter.text}
                      active={
                        !filter.onShowActive || filter.onShowActive(dataFilters)
                      }
                      onClick={() => {
                        if (!filter.onCreateFilters) return;
                        // setTimeout(() => )
                        onFilterChange(filter.onCreateFilters(dataFilters));
                      }}
                    />
                  )}
                  {filter.control === 'image-link' && (
                    <FilterImageLinkNavItem
                      active={
                        !filter.onShowActive || filter.onShowActive(dataFilters)
                      }
                      imageUrl={filter.imageUrl}
                      url={filter.url ? filter.url : '#'}
                      alt={filter.alt}
                    />
                  )}
                  {filter.control === 'date-range' && (
                    <FilterDateRange
                      active={
                        !filter.onShowActive || filter.onShowActive(dataFilters)
                      }
                      name={filter.name}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <div className={`latest-insights-${variant}__filters-dropdowns`}>
          {filters.map((filter, index) => (
            <React.Fragment key={index}>
              {filter.control === 'dropdown' && (
                <FilterDropdown
                  variant={variant}
                  type={filter.type}
                  selectedFilters={selectedFilters[filter.filterType]}
                  filterType={filter.filterType}
                  filterItems={filter.filterItems}
                  key={index}
                  onToggleFilter={onToggleFilter}
                />
              )}
              {filter.control === 'date-range' && (
                <FilterDateRangeDropdown
                  variant={variant}
                  type={filter.type}
                  selectedFilters={selectedFilters[filter.filterType]}
                  filterType={filter.filterType}
                  filterItems={filter.filterItems}
                  key={index}
                  onToggleFilter={onToggleFilter}
                  onChange={(startDate, endDate) =>
                    filter.onCreateFilters &&
                    setTimeout(
                      () =>
                        onFilterChange(
                          filter.onCreateFilters(
                            dataFilters,
                            startDate,
                            endDate
                          )
                        ),
                      1750
                    )
                  }
                />
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="layout-container">
          <a
            href="#"
            className={`latest-insights-${variant}__mobile-nav-link link--button-white`}
          >
            <img src={getImagePath('images/filter-menu-white.svg')} alt="" />
            {filtersLabel}
          </a>
        </div>
      </div>
      <div className="layout-container">
        <div className={`latest-insights-${variant}__tags`}>
          {showTags && (
            <>
              {selectedTags.map((selectedTag, index) => (
                <div className={`latest-insights-${variant}__tag`} key={index}>
                  <a
                    href="#"
                    aria-label={selectedTag.tagName}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (onRemoveFilter) onRemoveFilter(selectedTag);
                    }}
                  >
                    <img src={getImagePath('/images/close.svg')} alt="" />
                  </a>

                  {selectedTag.tagName}
                </div>
              ))}

              <a
                href="#"
                className={`latest-insights-${variant}__clear-tags link--button`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (onClearFilters) onClearFilters();
                }}
              >
                {clearAllFiltersLabel}
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export const FilterMobileDateRange = ({
  active,
  variant = 'insights',
  name,
  open = false,
  onOpen,
  onChange,
}) => {
  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');

  function handleStartDateChange(e) {
    e.stopPropagation();
    const currentStartDate = e.target.value;
    setStartDate(currentStartDate);
    const currentEndDate =
      endDate && moment(endDate).isBefore(moment(currentStartDate))
        ? moment(currentStartDate).add(1, 'day').format('YYYY-MM-DD')
        : endDate;
    if (currentEndDate !== endDate) setEndDate(currentEndDate);
    if (!currentStartDate || !currentEndDate || !onChange) return;
    onChange(currentStartDate, currentEndDate);
  }

  function handleEndDateChange(e) {
    e.stopPropagation();
    const currentEndDate = e.target.value;
    setEndDate(currentEndDate);
    const currentStartDate =
      startDate && moment(startDate).isAfter(moment(currentEndDate))
        ? moment(currentEndDate).subtract(1, 'day').format('YYYY-MM-DD')
        : startDate;
    if (currentStartDate !== startDate) setStartDate(currentStartDate);
    if (!currentStartDate || !currentEndDate || !onChange) return;
    onChange(currentStartDate, currentEndDate);
  }

  return (
    <div
      className={classNames({
        [`latest-insights-${variant}__mobile-nav__item`]: true,
        active: open,
        selected: !!active,
      })}
      onClick={() => onOpen && onOpen(!open)}
    >
      <div className={`latest-insights-${variant}__mobile-nav__item-title`}>
        {name}
        <CaretIcon thin />
      </div>
      <div className={`latest-insights-${variant}__mobile-nav__item-filters`}>
        <div className="filter__date-fields">
          <div className="filter__date-field">
            <label
              className="filter__date-field-label"
              htmlFor="filter-start-date"
            >
              <CalendarIcon />
            </label>
            <input
              className="filter__date-input"
              id="filter-start-date"
              type="date"
              value={startDate}
              onClick={(e) => e.stopPropagation()}
              onChange={handleStartDateChange}
            />
          </div>
          <span className="heading3">to</span>
          <div className="filter__date-field">
            <label
              className="filter__date-field-label"
              htmlFor="filter-end-date"
            >
              <CalendarIcon />
            </label>
            <input
              className="filter__date-input"
              id="filter-end-date"
              type="date"
              value={endDate}
              onClick={(e) => e.stopPropagation()}
              onChange={handleEndDateChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const FilterMobileNavItem = ({
  active,
  name,
  variant = 'insights',
  filterType,
  selectedFilters,
  filterItems = [],
  open,
  onToggleFilter,
  onOpen,
}) => {
  return (
    <div
      className={classNames({
        [`latest-insights-${variant}__mobile-nav__item`]: true,
        active: !!open,
        selected: !!active,
      })}
      onClick={() => onOpen && onOpen(!open)}
    >
      <div className={`latest-insights-${variant}__mobile-nav__item-title`}>
        {name}
        <CaretIcon thin />
      </div>
      <div className={`latest-insights-${variant}__mobile-nav__item-filters`}>
        <div className={`latest-insights-${variant}__items`}>
          {filterItems.map((filterItemProps, index) => (
            <FilterItem
              {...filterItemProps}
              selected={
                Array.isArray(selectedFilters)
                  ? selectedFilters.indexOf(filterItemProps.tagId) > -1
                  : selectedFilters === filterItemProps.tagId
              }
              key={index}
              onToggleFilter={(tag) => {
                if (!onOpen) return;
                onOpen(false);
                if (!onToggleFilter) return;
                onToggleFilter(filterType, tag);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export const MobileFilter = ({
  variant = 'insights',
  filters,
  dataFilters,
  selectedTags,
  selectedFilters,
  onClearFilters,
  onFilterChange,
  onToggleFilter,
  closeLabel,
  clearAllLabel,
}) => {
  const defaultOpen = null;
  const closeRef = React.useRef(null);
  const [open, setOpen] = React.useState(defaultOpen);
  return (
    <div className={`latest-insights-${variant}__mobile-nav`}>
      <div className={`latest-insights-${variant}__mobile-nav__inner`}>
        <a
          href="#"
          className={`latest-insights-${variant}__mobile-nav-close`}
          ref={closeRef}
          onClick={() => setOpen(defaultOpen)}
        >
          {closeLabel}
          <img src={getImagePath('/images/close.svg')} role="presentation" />
        </a>
        <div className={`latest-insights-${variant}__mobile-nav__items`}>
          {filters.map((filter, index) => {
            return (
              <React.Fragment key={index}>
                {filter.control === 'dropdown' && (
                  <FilterMobileNavItem
                    variant={variant}
                    name={filter.name}
                    filterType={filter.filterType}
                    filterItems={filter.filterItems}
                    selectedFilters={selectedFilters[filter.filterType]}
                    open={open === index}
                    onOpen={() => setOpen(open !== index ? index : defaultOpen)}
                    active={
                      !filter.onShowActive || filter.onShowActive(dataFilters)
                    }
                    onToggleFilter={(filterType, tag) => {
                      setOpen(defaultOpen);
                      closeRef.current.click();
                      if (onToggleFilter)
                        setTimeout(() => onToggleFilter(filterType, tag), 1750);
                    }}
                  />
                )}
                {filter.control === 'separator' && <FilterSeparator />}
                {filter.control === 'text-link' && (
                  <FilterTextLinkNavItem
                    text={filter.text}
                    variant={variant}
                    active={
                      !filter.onShowActive || filter.onShowActive(dataFilters)
                    }
                    onClick={() => {
                      if (!filter.onCreateFilters || !onFilterChange) return;
                      closeRef.current.click();
                      setTimeout(
                        () =>
                          onFilterChange(filter.onCreateFilters(dataFilters)),
                        1750
                      );
                    }}
                  />
                )}
                {filter.control === 'image-link' && (
                  <FilterImageLinkNavItem
                    variant={variant}
                    imageUrl={filter.imageUrl}
                    url={filter.url ? filter.url : '#'}
                    alt={filter.alt}
                    onClick={() => {
                      if (!filter.onCreateFilters || !onFilterChange) return;
                      closeRef.current.click();
                      setTimeout(
                        () =>
                          onFilterChange(filter.onCreateFilters(dataFilters)),
                        1750
                      );
                    }}
                  />
                )}
                {filter.control === 'date-range' && (
                  <FilterMobileDateRange
                    variant={variant}
                    active={
                      !filter.onShowActive || filter.onShowActive(dataFilters)
                    }
                    name={filter.name}
                    onOpen={() => setOpen(open !== index ? index : defaultOpen)}
                    open={open === index}
                    onChange={(startDate, endDate) => {
                      if (!filter.onCreateFilters || !onFilterChange) return;
                      closeRef.current.click();
                      setTimeout(
                        () =>
                          onFilterChange(
                            filter.onCreateFilters(
                              dataFilters,
                              startDate,
                              endDate
                            )
                          ),
                        1750
                      );
                    }}
                  />
                )}
              </React.Fragment>
            );
          })}
        </div>
        {!!selectedTags.length && (
          <div className={`latest-insights-${variant}__mobile-nav__bottom`}>
            {/* <a
            href="#"
            className={`latest-insights-${variant}__mobile-nav__apply-filters btn btn--white`}
          >
            Apply
          </a> */}
            <a
              href="#"
              className={`latest-insights-${variant}__mobile-nav__clear-filters link--button-white`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpen(defaultOpen);
                closeRef.current.click();
                if (onClearFilters) setTimeout(() => onClearFilters(), 1750);
              }}
            >
              {clearAllLabel}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export const Filter = ({
  variant = 'insights',
  dataFilters = {},
  filters = [],
  selectedTags = [],
  selectedFilters,
  containerRef,
  showTags = true,
  onClearFilters,
  onFilterChange,
  onToggleDropdownFilter,
  onRemoveDropdownFilter,
  filtersLabel,
  clearAllLabel,
  clearAllFiltersLabel,
  closeLabel,
}) => {
  return (
    <>
      <MobileFilter
        variant={variant}
        dataFilters={dataFilters}
        filters={filters}
        selectedFilters={selectedFilters}
        selectedTags={selectedTags}
        onFilterChange={onFilterChange}
        onToggleFilter={onToggleDropdownFilter}
        onClearFilters={onClearFilters}
        closeLabel={closeLabel}
        clearAllLabel={clearAllLabel}
      />
      <DesktopFilter
        variant={variant}
        dataFilters={dataFilters}
        filters={filters}
        selectedFilters={selectedFilters}
        selectedTags={selectedTags}
        containerRef={containerRef}
        showTags={showTags}
        onFilterChange={onFilterChange}
        onClearFilters={onClearFilters}
        onToggleFilter={onToggleDropdownFilter}
        onRemoveFilter={onRemoveDropdownFilter}
        filtersLabel={filtersLabel}
        clearAllFiltersLabel={clearAllFiltersLabel}
      />
    </>
  );
};

export function toggleMultipleFilter(filters, type, id) {
  const filterTypeTags = [...(filters[type] || [])];
  const filterIndex = filterTypeTags.indexOf(id);
  if (filterIndex > -1) {
    const remaining = [...filters[type]];
    remaining.splice(filterIndex, 1);
    return {
      ...filters,
      [type]: remaining,
    };
  } else {
    return {
      ...filters,
      [type]: _.uniq([...filterTypeTags, id]),
    };
  }
}

export function toggleSingleFilter(filters, type, id) {
  return {
    ...filters,
    [type]: filters[type] !== id ? id : undefined,
  };
}

export function removeFilter(filters, tag) {
  let newFilters = {};
  Object.keys(filters).forEach((key) => {
    if (Array.isArray(filters[key])) {
      const filter = [...filters[key]];
      const index = filter.indexOf(tag.tagId);
      if (index > -1) filter.splice(index, 1);
      if (filter.length) newFilters[key] = filter;
    } else {
      newFilters[key] = filters[key] === tag.tagId ? undefined : filters[key];
    }
  });
  return newFilters;
}

export function isFilterSelected(filters, tagId) {
  return Object.keys(filters).some((key) =>
    Array.isArray(filters[key])
      ? filters[key].indexOf(tagId) > -1
      : filters[key] === tagId
  );
}

export function selectTags(selectedTags, tag) {
  const index = selectedTags.findIndex((t) => t.tagId === tag.tagId);
  let currentSelectedTags = [...selectedTags];
  if (index > -1) {
    currentSelectedTags.splice(index, 1);
    return [...currentSelectedTags];
  } else {
    return [...currentSelectedTags, tag];
  }
}

export const knownInsightTagTypes = [
  { type: 'assetTypeTags', single: false },
  { type: 'insightTopicTags', single: false },
  { type: 'businessIssueTags', single: false },
  { type: 'calculatedIndustryTags', single: false },
  { type: 'calculatedSubIndustryDescriptions', single: false },
  { type: 'areaOfExpertiseTags', single: false },
  { type: 'capabilityTags', single: false },
  { type: 'oldestFirst', single: true },
  { type: 'startDisplayDate', single: true },
  { type: 'endDisplayDate', single: true },
  { type: 'cities', single: false },
  { type: 'authorPath', single: true },
];

export const knownDirectoryTagTypes = [
  { type: 'areaOfExpertiseTags', single: false },
  { type: 'calculatedIndustryTags', single: false },
  { type: 'capabilityTags', single: false },
  { type: 'city', single: true },
];

export const knownConsultantTagTypes = [
  { type: 'assetTypeTag', single: true },
  { type: 'authorPath', single: true },
];

export function getDefaultFilters(knownTagTypes, qs = window.location.search) {
  const filters = {};
  const params = new URLSearchParams(qs);
  knownTagTypes.map((tagType) => {
    if (!params.has(tagType.type)) return;
    filters[tagType.type] = tagType.single ? params.get(tagType.type) : params.get(tagType.type).split(',');
  });

  return filters;
}

export function getPreselectedTags(data, filters = {}, selectedTags = []) {
  const initialSelectedTags = [...selectedTags];
  Object.keys(filters).map((key) => {
    const tags = data[key];
    if (!tags) return;
    if (Array.isArray(filters[key])) {
      if (!filters[key].length) return;
      filters[key].forEach((filterValue) => {
        const tag = tags.find((tag) => tag.tagId === filterValue);
        if (!tag) return;
        initialSelectedTags.push(tag);
      });
    } else {
      filterValue = filters[key];
      if (!filterValue) return;
      const tag = tags.find((tag) => tag.tagId === filterValue);
      if (!tag) return;
      initialSelectedTags.push(tag);
    }
  });
  return initialSelectedTags;
}


export function getPreselectedTagsFromFacets(data, filters = {}, selectedTags = []) {
  const initialSelectedTags = [...selectedTags];
  Object.keys(filters).map((key) => {
    const tags = key == 'city' ? data['cities'] : data[key];
    let tag, city;
    if (!tags) return;
    if (Array.isArray(filters[key])) {
      if (!filters[key].length) return;
      filters[key].forEach((filterValue) => {
        if(key == 'cities'){
          city = tags.facetCities.find((tag) => tag.cityId === filterValue);
          if(city) tag = { tagCount: city.cityCount, tagId: city.cityId, tagName: city.cityName };
        } else {
          tag = tags.facetValues.find((tag) => tag.tagId === filterValue);
        }
        if (!tag) return;
        initialSelectedTags.push(tag);
      });
    } else {
      let filterValue = filters[key];
      if (!filterValue) return;
      if(key == 'city'){
        city = tags.facetCities.find((tag) => tag.cityId === filterValue);
        if(city) tag = { tagCount: city.cityCount, tagId: city.cityId, tagName: city.cityName };
      } else {
        tag = tags.facetValues.find((tag) => tag.tagId === filterValue);
      }
      if (!tag) return;
      initialSelectedTags.push(tag);
    }
  });
  return initialSelectedTags;
}