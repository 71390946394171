export function initializeRelatedArticles() {
  const relatedArticles = document.querySelector('.related-articles');

  if (!relatedArticles) {
    return;
  }

  const articles = relatedArticles.querySelectorAll('.related-article');
  articles.forEach((article) => {
    const articleImage = article.querySelector('.related-article img');
    const articleTitle = article.querySelector('.related-article__title span');
    if (!articleImage || !articleTitle) return;
    article.addEventListener('mouseover', () => {
      articleImage.classList.add('hover');
      articleTitle.classList.add('hover');
    });
    article.addEventListener('mouseout', () => {
      articleImage.classList.remove('hover');
      articleTitle.classList.remove('hover');
    });
  });
}
