import * as React from 'react';
import { render } from 'react-dom';
import { getDefaultFilters, knownInsightTagTypes } from './components/Filter';
import { LatestInsightsInsights } from './components/LatestInsightsInsights';
import { decorateInsightHover } from './latest-insights-common';
import { initializeSearchFilter } from './search-filter';

export function decorateLatestInsightsInsights(demo = false) {
  const latestInsightsInsights = document.querySelector(
    '.latest-insights-insights-container'
  );
  if (!latestInsightsInsights) return;

  initializeSearchFilter('.latest-insights-insights', demo);

  if (demo) {
    decorateInsightHover(latestInsightsInsights);
  }
}

export function initializeLatestInsightsInsights() {
  const root = document.getElementById('latest-insights-insights-root');
  if (typeof root?.dataset?.datasourceId === 'undefined') return;
  if (root)
    render(
      <LatestInsightsInsights
        datasourceId={root.dataset.datasourceId}
        contextPageId={root.dataset.pagecontext}
        defaultFilters={getDefaultFilters(knownInsightTagTypes)}
        language={root.dataset.language}
      />,
      root
    );
}
