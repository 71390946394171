import { initializeAnimation } from './global-animation';

export function initializeAccordion() {
  const richTextAccordion = document.querySelector('.rich-text-accordion');

  if (!richTextAccordion) {
    return;
  }

  const openClass = 'rich-text-accordion--open';
  const morePanel = document.querySelector('#rta-more');
  const moreButton = document.querySelector('#rta-btn-more');
  const lessButton = document.querySelector('#rta-btn-less');
  const input = richTextAccordion.querySelector('input');

  const closeHeader = () => {
    input.checked = true;
  };

  const readMoreLess = () => {
    if (richTextAccordion.classList.contains(openClass)) {
      richTextAccordion.classList.remove(openClass);
      closeHeader();
    } else {
      closeHeader();
      richTextAccordion.classList.add(openClass);
      morePanel.dataset.animation = false;
      initializeAnimation(morePanel);
    }
  };

  moreButton.addEventListener('click', readMoreLess);
  lessButton.addEventListener('click', readMoreLess);
}
