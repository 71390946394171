import Glide from '@glidejs/glide';

export function initializeCrossLinks() {
  const crossLinksCarousel = document.querySelector('.cross-links__carousel');
  const sizeMq = window.matchMedia('(max-width: 60rem)');
  let glide;

  function buildCarousel() {
    if (!crossLinksCarousel || !sizeMq.matches) {
      return;
    }

    const linkItems = crossLinksCarousel.querySelector('.link-items');
    delete linkItems.dataset.animation;

    glide = new Glide(crossLinksCarousel, {
      type: 'slider',
    }).mount();

    const gslides = Array.from(linkItems.querySelectorAll('.glide__slide'));

    glide.on('move.after', () => {
      const currentIndex = gslides.findIndex((slide) =>
        slide.classList.contains('glide__slide--active')
      );
      // reset animations on all non-current slides
      gslides.forEach((slide) => {
        // eslint-disable-next-line no-param-reassign
        slide.dataset.animation = false;
      });
      if (currentIndex > -1) gslides[currentIndex].dataset.animation = true;
    });
  }

  function destroyCarousel() {
    if (glide) {
      glide.destroy();
    }
  }

  function handleWindowResize(e) {
    if (e.matches) {
      buildCarousel();
    } else {
      destroyCarousel();
    }
  }

  sizeMq.addEventListener('change', handleWindowResize);

  buildCarousel();
}
