export default function () {
  function openCloseMenu(event, dataset) {
    let clickedElement = event.target;
    // check if element is span or li. dataset is in span
    if (clickedElement.localName === 'li')
      clickedElement = clickedElement.children[0]; // span element

    // get selected menu id. 'parent' for breadcrumb and 'child' for menu child
    const selectedMenuId = clickedElement.dataset[dataset];
    // get selected menu
    const selectedMenu = document.getElementById(selectedMenuId);
    if (selectedMenu) {
      // get current menu (from the breadcrumb to current)
      const currentMenu = event.srcElement.closest('div');
      // remove class active from current menu
      currentMenu.classList.remove('nhun-active');
      // add class nhun-active on selected menu
      selectedMenu.classList.add('nhun-active');
    }
  }

  const links = document.querySelectorAll('.navigation-header--utility-navigation li');
  for (const link of links)
    link.addEventListener('click', (e) => openCloseMenu(e, 'child'));

  const breadcrumbs = document.querySelectorAll('.navigation-header--breadcrumbs li');
  for (const breadcrumb of breadcrumbs)
    breadcrumb.addEventListener('click', (e) => openCloseMenu(e, 'parent'));
}
