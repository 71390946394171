// import classNames from 'classnames';
import * as React from 'react';
import classNames from 'classnames';
import { initializeAnimation } from '../global-animation';
import { getImagePath } from '../util';

export const Author = ({ imageUrl, imageAlt, fullName }) => {
  return (
    <div className="speaker">
      <img
        // @TODO: acquire default author image
        src={imageUrl}
        alt={imageAlt || fullName}
      />
    </div>
  );
};

export const AuthorsList = ({
  authors = [],
  authorsLabel,
  showAuthorList = false,
}) => {
  const [open, setOpen] = React.useState(false);
  const [handle, setHandle] = React.useState(null);
  const authorsRef = React.useRef(null);

  React.useLayoutEffect(() => {
    if (!showAuthorList) return;
    if (open && authorsRef) {
      setHandle(
        setTimeout(() => {
          authorsRef.current.dataset.animation = true;
        }, 1500)
      );
    } else if (handle) {
      setHandle(clearTimeout(handle));
    }
    if (!open) authorsRef.current.dataset.animation = false;
  }, [open]);

  return (
    <div
      className={classNames({
        insight__authors: true,
        speakers: true,
        'speakers--hide-author-list': !showAuthorList,
      })}
      onClick={() => showAuthorList && setOpen(!open)}
    >
      {authors.map((authorProps, index) => (
        <Author {...authorProps} key={index} />
      ))}
      {authorsLabel && <span>{authorsLabel}</span>}
      {showAuthorList && (
        <div
          className={classNames({
            'authors-list--wrapper': true,
            open: open,
            close: !open,
          })}
        >
          <div className="authors-list--container">
            <div className="authors-list--content">
              <div className="authors-list--first-line">
                <button className="authors-list--btn-close">
                  ClOSE
                  <img
                    src={getImagePath('/images/close.svg')}
                    role="presentation"
                  />
                </button>
              </div>

              <div
                className="authors-list--authors"
                data-animation="false"
                ref={authorsRef}
              >
                {authors.map((author, index) => (
                  <div className="author" key={index}>
                    <div className="image">
                      <a href="#">
                        <img
                          src={author.imageUrl}
                          alt={author.imageAlt || author.fullName}
                        />
                      </a>
                    </div>

                    <div className="details">
                      <div className="name">
                        <a href={author.url}>{author.fullName}</a>
                      </div>

                      {/* {author.about && (
                      <div className="about">
                        <a href={author.aboutUrl}>{author.about}</a>
                      </div>
                    )} */}

                      {/* {author.city && (
                      <div className="location">
                        <a href="#">
                          <img src={getImagePath('/images/map-icon.svg')} alt="map icon" />
                          {author.city}
                        </a>
                      </div>
                    )} */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
