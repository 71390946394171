const scrollable = document.querySelectorAll('.scrollable');

function init(){
	scrollable.forEach((parent) => {
		const scrollableContainer = parent.querySelector('.scrollable-container__list');
		const scrollableItems = scrollableContainer.querySelectorAll('.scrollable-container__list--item');
		const scrollLeft = parent.querySelector('.scrollable-container__left');
		const scrollRight = parent.querySelector('.scrollable-container__right');
		let scrollIndex = 0;
		let scrollAmount = 0;
		let scrollMax = scrollableItems.length - 1;
		let linkArray = [];

		scrollableItems.forEach((el) => {
			let style = getComputedStyle(el);
			let marginLeft = parseInt(style.marginLeft);
			let marginRight = parseInt(style.marginRight);
			linkArray.push(el.getBoundingClientRect().width + marginLeft + marginRight);
		})

		scrollLeft.addEventListener('click', (e) => {
			e.preventDefault();
			if(scrollIndex > 0){
				scrollIndex--;
				if(scrollIndex == 0){
					scrollLeft.classList.add('disabled');
					scrollRight.classList.remove('disabled');
				} else if(scrollIndex > 0 && scrollIndex < scrollMax) {					
					scrollLeft.classList.remove('disabled');
					scrollRight.classList.remove('disabled');
				} else {
					scrollLeft.classList.remove('disabled');
					scrollRight.classList.add('disabled');					
				}
				scrollAmount += linkArray[scrollIndex];
				scrollableItems.forEach((item) => {
					item.parentElement.style.left = scrollAmount + 'px';
				})
			}
		})

		scrollRight.addEventListener('click', (e) => {
			e.preventDefault();
			if(scrollIndex < scrollMax){
				scrollAmount -= linkArray[scrollIndex];
				scrollableItems.forEach((item) => {
					item.parentElement.style.left = scrollAmount + 'px';
				})
				scrollIndex++;
				if(scrollIndex == 0){
					scrollLeft.classList.add('disabled');
					scrollRight.classList.remove('disabled');
				} else if(scrollIndex > 0 && scrollIndex < scrollMax) {					
					scrollLeft.classList.remove('disabled');
					scrollRight.classList.remove('disabled');
				} else {
					scrollLeft.classList.remove('disabled');
					scrollRight.classList.add('disabled');					
				}
			}
		})
	});
}

export function initializeScrollable(){
	init();
}