import * as React from 'react';
import { initializeAnimation } from '../global-animation';
import * as api from '../api/request';
import { Pager } from './Pager';
import { placeholder1xImagePath } from '../util';
import { consultantDirectoryData } from '../api/mockdata';

export const RelatedConsultants = ({ title, datasourceId, language = "en" }) => {
  const defaultPage = 1;
  const [page, setPage] = React.useState(defaultPage);
  const [loaded, setLoaded] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [data, setData] = React.useState(null);
  const containerContainerRef = React.useRef(null);
  const consultantsContainerRef = React.useRef(null);

  async function loadData() {
    try {
      const hasBeenLoaded = loaded;
      setLoaded(false);
      const response = await api.getConsultantDirectoryData(
        page,
        {},
        datasourceId,
        language
      );
      // const response = consultantDirectoryData;
      setData(response);
      setLoaded(true);
      setError(null);
      if (hasBeenLoaded && consultantsContainerRef)
        consultantsContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    } catch (e) {
      setError(e);
    }
  }

  React.useEffect(() => {
    loadData();
  }, [page]);

  const isValidData = !!data;

  React.useLayoutEffect(() => {
    if (!loaded || !isValidData) return;
    setTimeout(() => {
      initializeAnimation(containerContainerRef.current);
      initializeAnimation(consultantsContainerRef.current);
    }, 250);
  }, [loaded]);

  return (
    <>
      {isValidData && (
        <div>
          <div
            className="related-consultants-list-container"
            data-animation="false"
            ref={containerContainerRef}
          >
            <div className="related-consultants-list--carousel-title">
              <h2 className="heading1">{title}</h2>
            </div>
            <div className="related-consultants-list--card-row">
              <ul data-animation="false" ref={consultantsContainerRef}>
                {(data.profiles || []).map((profile, index) => (
                  <li key={index}>
                    <a href={profile.url}>
                      <div className="related-consultants-list--picture">
                        <img
                          src={profile.imageUrl || placeholder1xImagePath}
                          alt={profile.imageAlt || profile.fullName}
                        />
                      </div>
                      <div className="related-consultants-list--info">
                        <h3 class="heading4">{profile.fullName}</h3>
                        <span>
                          {/*<span>{profile.location.city.cityName}</span> */}
                          {(profile.cities || []).map((city, index) => (
                            <span>{city.cityName}</span>
                          ))}
                        </span>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <Pager
            gutterTop={false}
            gutterBottom={true}
            page={page}
            pageSize={data.pageSize}
            totalResults={data.totalNumberOfResults}
            onChange={(pageNumber) => setPage(pageNumber)}
          />
        </div>
      )}
    </>
  );
};
