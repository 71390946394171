import Glide from '@glidejs/glide';

export default function () {
  const eventsCarousel = document.querySelector('.feature-carousel--container');

  if (!eventsCarousel) {
    return;
  }

  /**
   * Instantiate the events carousel.
   */
  new Glide('.feature-carousel--container', {
    type: 'carousel',
    perView: 1,
  }).mount();
}
