import * as React from 'react';
import { LatestInsights } from './LatestInsights';
import { getLatestInsightsPodcastData } from '../api/request';
import { decorateLatestInsightsPodcasts } from '../latest-insights-podcasts';
import { getImagePath } from '../util';
import { Insight } from './Insights';
import { latestInsightsPodcastsData } from '../api/mockdata';

export const LatestInsightsPodcasts = ({ datasourceId, contextPageId, defaultFilters, language="en" }) => (
  <LatestInsights
    variant="podcasts"
    datasourceId={datasourceId}
    language={language}
    contextPageId={contextPageId}
    defaultFilters={defaultFilters}
    // onGetData={async () => latestInsightsPodcastsData}
    onGetData={getLatestInsightsPodcastData}
    onRenderInsight={(data) => <Insight {...data} />}
    onDecorate={() => decorateLatestInsightsPodcasts()}
    onBuildFilters={(data) => [
      {
        control: 'image-link',
        imageUrl: getImagePath('images/spotify-podcast.svg'),
        url: data.podcastLinks[0]?.value,
        alt: 'Listen on Spotify',
      },
      {
        control: 'image-link',
        imageUrl: getImagePath('images/apple-podcast.svg'),
        url: data.podcastLinks[1]?.value,
        alt: 'Listen on Apple',
      },
      {
        control: 'image-link',
        imageUrl: getImagePath('images/google-podcast.svg'),
        url: data.podcastLinks[2]?.value,
        alt: 'Listen on Google',
      },
      {
        control: 'separator',
      },
      {
        control: 'text-link',
        text: 'Most Recent',
        onShowActive: (dataFilters) => {
          return dataFilters.oldestFirst === false;
        },
        onCreateFilters: (dataFilters) => {
          return { ...dataFilters, oldestFirst: false };
        },
      },
      {
        control: 'text-link',
        text: 'Oldest',
        onShowActive: (dataFilters) => {
          return dataFilters.oldestFirst === true;
        },
        onCreateFilters: (dataFilters) => {
          return { ...dataFilters, oldestFirst: true };
        },
      },
    ]}
    onBuildTags={(insight) => [
      ...(insight.insightTopicTags || []),
      ...(insight.businessIssueTags || []),
      ...(insight.calculatedIndustryTags || []),
      ...(insight.calculatedSubIndustryDescriptions || []),
      ...(insight.areaOfExpertiseTags || []),
      ...(insight.capabilityTags || []),
    ]}
  />
);
