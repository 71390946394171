import { initializeAnimation } from './global-animation';

export function initializeAuthorsList() {
  const els = document.querySelectorAll('.authors-list-btn');
  if (!els || !els.length) return;

  for (let index = 0; index < els.length; index += 1) {
    const el = els[index];

    const targetId = el.dataset.target;

    // eslint-disable-next-line func-names
    const toggleDialogs = function (evt) {
      const els = document.querySelectorAll(
        `.authors-list--wrapper[data-id="${targetId}"]`
      );

      els.forEach((dialog) => {
        let closed =
          !dialog.classList.contains('open') ||
          (!dialog.classList.contains('open') &&
            !dialog.classList.contains('close')) ||
          dialog.classList.contains('close');

        const contentEl = dialog.querySelector('.authors-list--authors');
        contentEl.dataset.animation = false;

        if (closed) {
          dialog.classList.remove('close');
          dialog.classList.add('open');
          setTimeout(() => {
            initializeAnimation(contentEl);
          }, 1400);
        } else {
          contentEl.dataset.animation = false;
          dialog.classList.add('close');
          dialog.classList.remove('open');
        }
      });
    };

    const contentEl = document.querySelector(
      `.authors-list--wrapper[data-id="${targetId}"] .authors-list--container`
    );
    if (contentEl) {
      // eslint-disable-next-line func-names
      const contentOnClick = function (e) {
        e.stopPropagation();
      };

      contentEl.addEventListener('click', contentOnClick);
    }

    const closeBtn = document.querySelector(
      `.authors-list--wrapper[data-id="${targetId}"] .authors-list--btn-close`
    );
    if (contentEl) {
      const contentOnClick = function (e) { };

      closeBtn.addEventListener('click', toggleDialogs);
    }

    const backdropEl = document.querySelector(
      `.authors-list--wrapper[data-id="${targetId}"]`
    );
    if (backdropEl) {
      // eslint-disable-next-line func-names
      const toggleDialogBackdrop = function (event) {
        const dialogEl = event.srcElement;
        let closed =
          !dialogEl.classList.contains('open') ||
          (!dialogEl.classList.contains('open') &&
            !dialogEl.classList.contains('close')) ||
          dialogEl.classList.contains('close');

        if (closed) {
          dialogEl.classList.remove('close');
          dialogEl.classList.add('open');
        } else {
          dialogEl.classList.add('close');
          dialogEl.classList.remove('open');
        }
      };

      backdropEl.addEventListener('click', toggleDialogBackdrop);
    }

    el.addEventListener('click', toggleDialogs);
  }
}
