import { initializeAnimation } from './global-animation';

export default function () {
	const mediaList = document.querySelector('.inthemedia-container__list');

	if(mediaList==null){
		return;
	}

	const mediaListItem = mediaList.querySelectorAll('.inthemedia-container__list--item');
	const mediaNav = document.querySelector('.inthemedia-container__nav');
	const mediaNavPrev = mediaNav.querySelector('.pagination__arrow--left');
	const mediaNavNext = mediaNav.querySelector('.pagination__arrow--right');

	let multiplier = window.innerWidth < 980 ? 1 : 2;	
	let maxScroll = Math.ceil((mediaListItem.length  / multiplier) - 1);
	let scrollDistance = multiplier * 100;

	window.addEventListener('resize', function(){
		multiplier = window.innerWidth < 980 ? 1 : 2;
		maxScroll = Math.ceil((mediaListItem.length  / multiplier) - 1);
		scrollDistance = multiplier * 100;
	})


	if(mediaListItem.length > 2){
		mediaNav.classList.add('show');
	}	

	let scrollCount = 0;

	mediaNavPrev.addEventListener('click', () => {
		if(scrollCount >= 0){
			scrollCount--;
			mediaListItem.forEach((el) => {
				//el.style.transform = "translateX(-" + scrollCount * 200 + "%) \!important";
				el.style.setProperty("transform", "translate(-" + scrollCount * scrollDistance + "%, 0)", "important");
				el.style.transitionDelay = 0;
			})
		}
	});

	mediaNavNext.addEventListener('click', () => {
		if(scrollCount < maxScroll){
			scrollCount++;
			mediaListItem.forEach((el) => {
				//el.style.transform = "translateX(-" + scrollCount * 200 + "%) \!important";
				el.style.setProperty("transform", "translate(-" + scrollCount * scrollDistance + "%, 0)", "important");
				el.style.transitionDelay = 0;
			})
		}
	});

	//mediaListItem.forEach((el) => {
		initializeAnimation(mediaList);
	//})
}
