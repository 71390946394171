import * as React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { CrossIcon, CaretIcon } from './Icons';
import { InsightImage } from './InsightImage';
import { AuthorsList } from './AuthorsList';
import { initializeAnimation } from '../global-animation';
import { decorateLatestInsightsConsultant } from '../latest-insights-consultant';
import { Pager } from './Pager';
import * as api from '../api/request';
import {
  isFilterSelected,
  toggleMultipleFilter,
  toggleSingleFilter,
} from './Filter';
import { latestInsightsConsultantsData } from '../api/mockdata';

// @TODO:  unify with primary FilterItem
const FilterItem = ({
  index,
  selected = false,
  tagName,
  tagCount,
  tagId,
  type = '',
  onToggleFilter,
}) => {
  return (
    <div
      id={type && Number.isInteger(index) ? `${type}${index}` : undefined}
      aria-label={`${type ? `${type} ` : ''}${tagName}`}
      role="option"
      className={classNames({
        filter__item: true,
        'filter__item--selected': selected,
      })}
      onClick={(e) => {
        e.stopPropagation();
        if (onToggleFilter) onToggleFilter({ tagName, tagId });
      }}
    >
      <CrossIcon />
      <p>{tagName}</p>
      <span>{tagCount}</span>
    </div>
  );
};

const ResultItem = ({
  assetTypeTag: { tagName: type = '' } = { tagName: '' },
  featured,
  isFeatured,
  url,
  imageUrl,
  imageAlt,
  title,
  displayDate,
  readTime,
  businessIssueTags,
  calculatedIndustryTags,
  calculatedSubIndustryDescriptions,
  areaOfExpertiseTags,
  capabilityTags,
  authors = [],
  authorsLabel,
  featuredLabel,
}) => {
  const [highlight, setHighlight] = React.useState(false);
  let displaySpan;
  if (isFeatured) {
    displaySpan = <span>{featuredLabel}</span>;
  } else {
    displaySpan = <span>{moment(displayDate).format('MMMM D, YYYY')}</span>;
  }

  function handleHighlight(currentHighlight) {
    setHighlight(currentHighlight);
  }

  return (
    <div
      className={classNames({
        insight: true,
        'insight--featured': featured || isFeatured,
      })}
    >
      <InsightImage
        url={url}
        imageUrl={imageUrl}
        imageAlt={imageAlt}
        type={type}
        highlight={highlight}
        onHighlight={handleHighlight}
      />
      <div className="insight__content">
        <div className="insight__meta">
          {authors && authors.length > 0 && (
            <div className="insight__authors speakers">
              <AuthorsList
                higlight={highlight}
                authors={authors}
                authorsLabel={authorsLabel}
              />
            </div>
          )}
          <div className="insight__date-read">
            <p className={`copy--sm ${typeof readTime === 'undefined' || readTime === '' ? 'no-bar' : ''}`}>
              {displaySpan}
              <span>{readTime}</span>
            </p>
          </div>
        </div>
        <div className="insight__title">
          <h3>
            <a
              className={classNames({ hover: highlight })}
              href={url}
              onMouseEnter={() => handleHighlight(true)}
              onMouseLeave={() => handleHighlight(false)}
              onFocus={() => handleHighlight(true)}
              onBlur={() => handleHighlight(false)}
            >
              {title}
            </a>
          </h3>
        </div>
        <div className="insight__tags">
          {[
            ...businessIssueTags,
            ...calculatedIndustryTags,
            ...calculatedSubIndustryDescriptions,
            ...areaOfExpertiseTags,
            ...capabilityTags,
          ].map((tag, index) => (
            <a href={tag.tagUrl || 'javascript:void(0)'} key={index}>
              {tag.tagName}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export const LatestInsightsConsultant = ({
  authorPath,
  contextPageId,
  defaultFilters = {},
  language = 'en',
}) => {
  const location = window.location;
  const defaultSelectedTags = [];

  let url = new URL(location.href);
  let params = new URLSearchParams(url.search);

  const defaultPage = params.has('page') ? Number(params.get('page')) : 1;
  const firstPage = 1;
  const [page, setPage] = React.useState(defaultPage);
  const [loaded, setLoaded] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [showFilter, setShowFilter] = React.useState(false);
  const [showFilterContent, setShowFilterContent] = React.useState(false);
  const [showFilterTimeoutHandle, setShowFilterTimeoutHandle] =
    React.useState(null);
  const [filters, setFilters] = React.useState(
    Object.keys(defaultFilters).length > 0 ? defaultFilters : { authorPath },
  );
  const containerRef = React.useRef(null);
  const headerContainerRef = React.useRef(null);
  const resultsContainerRef = React.useRef(null);

  async function loadData() {
    try {
      const hasBeenLoaded = loaded;
      setLoaded(false);
      const response = await api.getLatestInsightsConsultantsData(
        page,
        contextPageId,
        filters,
        language,
      );
      // const response = latestInsightsConsultantsData;
      setData(response);
      setError(null);
      setLoaded(true);

      if (hasBeenLoaded && resultsContainerRef) {
        resultsContainerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (e) {
      setError(e);
    }
  }

  function handleSingleFilterChange(type, tag) {
    handleToggleFilterMenu();
    setFilters(toggleSingleFilter(filters, type, tag.tagId));
    setPage(firstPage);
    setUrl(type, tag, firstPage);
  }

  function handleToggleFilterMenu() {
    if (showFilterTimeoutHandle) {
      clearTimeout(showFilterTimeoutHandle);
    }
    if (showFilter) {
      setShowFilterContent(false);
      const timeoutHandle = setTimeout(() => {
        setShowFilter(false);
      }, 1000);
      setShowFilterTimeoutHandle(timeoutHandle);
    } else {
      setShowFilter(true);
      const timeoutHandle = setTimeout(() => {
        setShowFilterContent(true);
      }, 1000);
      setShowFilterTimeoutHandle(timeoutHandle);
    }
  }

  function setUrl(type, tag, currentPage) {
    let tagIds = [];

    let url = new URL(location.href);
    let params = new URLSearchParams(url.search);

    if (params.has(type) && params.get(type).length > 0) {
      tagIds = params.get(type).split(',');
    }

    if (!tagIds.includes(tag.tagId)) {
      tagIds.push(tag.tagId);
    } else {
      tagIds.splice(tagIds.indexOf(tag.tagId), 1);
    }

    params.set('authorPath', authorPath);

    params.set(type, tagIds.toString());
    params.set('page', currentPage);

    window.history.pushState(
      null,
      'filter',
      location.pathname + '?' + decodeURIComponent(params.toString()),
    );
  }

  React.useEffect(() => {
    loadData();
  }, [page, filters]);

  const isValidData =
    data && Array.isArray(data.insights) && !!data.insights.length;

  React.useEffect(() => {
    if (!loaded || !isValidData) return;
    setTimeout(() => {
      initializeAnimation(headerContainerRef.current);
      initializeAnimation(resultsContainerRef.current);
      decorateLatestInsightsConsultant();
    }, 250);
  }, [loaded, filters]);

  return (
    <>
      {isValidData && (
        <>
          <div
            className="latest-insights-consultant-container"
            ref={containerRef}
          >
            <div className="layout-container">
              <div className="latest-insights-consultant">
                <div
                  className="latest-insights-consultant__header"
                  data-animation="false"
                  ref={headerContainerRef}
                >
                  <h2 className="heading1">{data.title}</h2>
                  <div className="filter">
                    <p>{data.assetTypeTags.label}</p>
                    <span
                      id="filter__button"
                      className={classNames({
                        filter__button: true,
                        'filter__button--active': showFilter,
                      })}
                      onClick={handleToggleFilterMenu}
                    >
                      {data.assetTypeTags.description}
                      <CaretIcon thin />
                    </span>
                    <div
                      className={classNames({
                        filter__container: true,
                        'filter__container--show': showFilter,
                      })}
                    >
                      <div className="filter__container-inner">
                        <div
                          className={classNames({
                            filter__content: true,
                            'filter__content--show': showFilterContent,
                          })}
                        >
                          <div className="filter__items">
                            {(data.assetTypeTags.facetValues || []).map(
                              (filterItemProps, index) => (
                                <FilterItem
                                  key={index}
                                  selected={isFilterSelected(
                                    filters,
                                    filterItemProps.tagId,
                                  )}
                                  {...filterItemProps}
                                  onToggleFilter={(tag) =>
                                    handleSingleFilterChange(
                                      'assetTypeTag',
                                      tag,
                                    )
                                  }
                                />
                              ),
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {data && (
                  <div
                    className="latest-insights-consultant__insights"
                    data-animation="false"
                    ref={resultsContainerRef}
                  >
                    {(data.insights || []).map((resultItemProps, index) => (
                      <ResultItem key={index} {...resultItemProps} />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          {data && (
            <Pager
              page={page}
              gutterTop={false}
              gutterBottom={false}
              totalResults={data.totalNumberOfResults}
              pageSize={data.pageSize}
              onChange={(pageNumber) => setPage(pageNumber)}
              updateUrl={true}
            />
          )}
        </>
      )}
    </>
  );
};
