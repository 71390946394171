import * as React from 'react';
import classNames from 'classnames';
import playerjs from 'player.js';
import { CloseIcon, InsightTypeIcon, PlayIcon } from './Icons';
import { placeholder2xImagePath } from '../util';
import { setEngagementGoal, ENGAGEMENT_GOAL_TYPES } from './../api/request';

export const InsightImage = ({
  url,
  imageUrl,
  imageAlt,
  type,
  highlight,
  onHighlight,
  podcastUrl,
  podcastTitle,
}) => {
  const [podcastOpen, setPodcastOpen] = React.useState(false);
  const podcastFrameEl = React.useRef(null);

  function handleTogglePodcastActive(e) {
    e.preventDefault();
    e.stopPropagation();

    const open = !podcastOpen;
    setPodcastOpen(open);
    let goalTriggered = false;
    const player = new playerjs.Player(podcastFrameEl.current);
    player.on('ready', () => {
      if (open) player.play();
      else player.pause();

      player.on('play', () => {
        if (!goalTriggered) {
          // API call to Sitecore backend to set user engagement score for successfully playing a unique podcast
          setEngagementGoal(ENGAGEMENT_GOAL_TYPES.INTERVIEW_PODCAST);
          goalTriggered = true;
        }
      });
    });
  }

  React.useLayoutEffect(() => {
    if (!podcastFrameEl.current) return;

    // get other podcast frames
    const podcastFrames = Array.from(
      document.querySelectorAll('.insight__podcast iframe')
    );
    const index = podcastFrames.indexOf(podcastFrameEl.current);
    if (index > -1) {
      podcastFrames.splice(index, 1);
    }

    // when playing current player, pause rest
    const player = new playerjs.Player(podcastFrameEl.current);
    player.on('ready', () => {
      player.on('play', () => {
        podcastFrames.forEach((podcastFrame) => {
          const p = new playerjs.Player(podcastFrame);
          p.on('ready', () => {
            p.pause();
          });
        });
      });
    });
  }, [podcastOpen]);

  return (
    <div
      className={classNames({
        insight__image: true,
        'insight__image--hover': highlight,
        'insight__image--inverted': type === 'video series',
        'insight__image--active': podcastOpen,
      })}
      onMouseEnter={() => onHighlight && onHighlight(true)}
      onMouseLeave={() => onHighlight && onHighlight(false)}
      onFocus={() => onHighlight && onHighlight(true)}
      onBlur={() => onHighlight && onHighlight(false)}
    >
      <a href={url}>
        {podcastUrl && podcastTitle && (
          <div
            className="insight__podcast-icon"
            onClick={handleTogglePodcastActive}
          >
            <div className="play">
              <PlayIcon />
            </div>
            <div className="close">
              <CloseIcon />
            </div>
          </div>
        )}
        <img src={imageUrl || placeholder2xImagePath} alt={imageAlt} />
        {type && (
          <div className="insight__type">
            <InsightTypeIcon type={type} />
            <span>{type}</span>
          </div>
        )}
      </a>
    </div>
  );
};
