export function initializeSingleConsultantProfileAlt() {
  const el = document.querySelector('.single-consultant-profile-alt--wrapper');
  const nav = document.querySelector('.navigation');
  
  if (!el || !nav) {
    return;
  }

  const getInTouchButton = el.querySelector(
    '.single-consultant-profile-alt--get-in-touch'
  );

  if (getInTouchButton) {

    getInTouchButton.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      document.querySelector('.get-in-touch--wrapper').scrollIntoView({
        behavior: 'smooth',
      });
    });
  }

  const scrollBtn = document.querySelectorAll('[data-scroll]');

  if(scrollBtn) {
    scrollBtn.forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        let target = document.getElementById(e.currentTarget.getAttribute('data-scroll'));
        console.log(target);
        target.scrollIntoView();
      })
    });
  }

}
