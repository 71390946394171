export function initializeItemsList() {
  const itemsList = document.querySelectorAll('.link-items');
  itemsList.forEach((list) => {
    const items = list.querySelectorAll('.link-item');
    items.forEach((item) => {
      const itemTitle = item.querySelector('.link-item h3 a');
      item.addEventListener('mouseover', () => {
        itemTitle.classList.add('hover');
      });
      item.addEventListener('mouseout', () => {
        itemTitle.classList.remove('hover');
      });
    });
  });
}
