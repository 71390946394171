import { initializeAnimation } from './global-animation';

export function initializeExperienceEditor() {
  // Check for Sitecore global and check if in experience editor
  let isPageEditor = function () {
    return !!(
      typeof Sitecore === 'object' &&
      Sitecore.PageModes &&
      Sitecore.PageModes.PageEditor
    );
  };

  if (isPageEditor()) {
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        mutation.addedNodes.forEach((addedNode) => {
          // some nodes don't support querying
          if (!addedNode.querySelector) return;

          if (
            addedNode.dataset &&
            typeof addedNode.dataset.animation !== 'undefined'
          ) {
            initializeAnimation(addedNode);
          }

          // query node for all child elements with data-animation
          addedNode.querySelectorAll('[data-animation]').forEach((node) => {
            initializeAnimation(node);
          });
        });
      });
    });

    // set up the observer
    observer.observe(document.querySelector('#MainPanel'), {
      subtree: true,
      childList: true,
    });
  }
}
