import * as React from 'react';
import { render } from 'react-dom';
import { getDefaultFilters, knownInsightTagTypes } from './components/Filter';
import { LatestInsightsPodcasts } from './components/LatestInsightsPodcasts';
import { decorateInsightHover } from './latest-insights-common';
import { initializeSearchFilter } from './search-filter';

export function decorateLatestInsightsPodcasts(demo = false) {
  const latestInsightsPodcasts = document.querySelector(
    '.latest-insights-podcasts-container'
  );
  if (!latestInsightsPodcasts) return;

  initializeSearchFilter('.latest-insights-podcasts', demo);

  if (demo) {
    decorateInsightHover(latestInsightsPodcasts);
  }
}

export function initializeLatestInsightsPodcasts() {
  const root = document.getElementById('latest-insights-podcasts-root');
  if (typeof root?.dataset?.datasourceId === 'undefined') return;
  if (root)
    render(
      <LatestInsightsPodcasts
        datasourceId={root.dataset.datasourceId}
        contextPageId={root.dataset.pagecontext}
        defaultFilters={getDefaultFilters(knownInsightTagTypes)}
        language={root.dataset.language}
      />,
      root
    );
}
