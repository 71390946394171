export function initializeHeaderFeatureConsultantDirectory() {
  const headerFeatureConsultantDirectory = document.querySelector(
    '.header-feature-consultant-directory--wrapper'
  );
  const nav = document.querySelector('.navigation');

  if (!headerFeatureConsultantDirectory || !nav) {
    return;
  }
}
