import { setEngagementGoal, ENGAGEMENT_GOAL_TYPES } from './api/request';

export default function () {
  const els = document.querySelectorAll('.gated-content--wrapper');
  for (let index = 0; index < els.length; index += 1) {
    const el = els[index];
    if (!el) return;

    const formEl = el.querySelector('form');

    if (!formEl) return;

    const { marketoFormId: formId } = formEl.dataset;

    let form;

    document.addEventListener(`marketo-form-loaded-${formId}`, () => {
      form = MktoForms2.getForm(formId);
      if (!form) return;

      form.onSuccess(function (values, followUpUrl) {
        // API call to sitecore backend to set user engagement score for successfully filling out a form
        setEngagementGoal(ENGAGEMENT_GOAL_TYPES.FILLED_OUT_FORM);
        return false;
      });
    });

    const id = el.dataset.gatedContentId;

    const elTarget = document.querySelector(
      `[data-gated-content-target="${id}"]`
    );

    if (!elTarget) return;

    const shouldObserve = elTarget.dataset.gatedContentObserver;

    if (shouldObserve) {
      function observerCb(entries, observer) {
        entries.forEach((entry) => {
          if (entry.intersectionRatio !== 1) return;

          el.classList.remove('close');
          el.classList.add('open');
        });
      }

      let options = {
        rootMargin: '0px 0px -10px 0px',
        threshold: 1.0,
      };

      let observer = new IntersectionObserver(observerCb, options);
      observer.observe(elTarget);
    } else {
      elTarget.addEventListener('click', (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        el.classList.remove('close');
        el.classList.add('open');
      });
    }

    const closeBtn = document.querySelector('.gated-content--btn-close');

    closeBtn.addEventListener('click', () => {
      el.classList.add('close');
      el.classList.remove('open');
    });
  }
}
