import Glide from '@glidejs/glide';
import debounce from './debounce';

export function activateCarousel(carousel) {
  let glide;
  const sizeMq = window.matchMedia('(max-width: 60rem)');
  // If we're smaller than 960px, create the new glide settings.
  if (sizeMq.matches) {
    glide = new Glide(carousel, {
      type: 'carousel',
    });

    // If a carousel is not enabled, mount it and add the "enabled" class.
    if (!carousel.classList.contains('js-enabled')) {
      glide.mount();
      carousel.classList.add('js-enabled');
    }
  } else if (glide) {
    // If we're larger than 960px and the carousel exists, destroy it.
    if (carousel.classList.contains('js-enabled')) {
      carousel.classList.remove('js-enabled');
      glide.destroy();
    }
  }
}

export function initializeCarousels() {
  const carousels = document.querySelectorAll('.carousel');

  if (!carousels.length) {
    return;
  }

  let glide;

  /**
   * Activate the carousels.
   */
  const activateCarousels = () => {
    carousels.forEach((carousel) => activateCarousel(carousel, glide));
  };

  /**
   * Debounce function for the carousel initialize.
   */
  const carouselDebounce = debounce(() => {
    activateCarousels();
  }, 250);

  // We need to wait for the window to load before initializing
  // the carousels. Otherwise things will load too fast and
  // glide.js inline styles are not applied.
  window.addEventListener('load', () => {
    activateCarousels();
  });

  // When we're resizing, we call the debounce function for performance purposes.
  window.addEventListener('resize', carouselDebounce);
}
