import * as React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { InsightImage } from './InsightImage';
import { AuthorsList } from './AuthorsList';

export const Insight = ({
  assetTypeTag: { tagName: type },
  isFeatured,
  featured = false,
  url,
  imageUrl,
  imageAlt,
  title,
  displayDate,
  readTime,
  tags = [],
  authors = [],
  authorsLabel,
  redefinersPodcast,
  labels,
  podcastUrl,
  podcastTitle,
  featuredLabel,
}) => {
  const [highlight, setHighlight] = React.useState(false);

  let displaySpan;
  if (isFeatured) {
    displaySpan = <span>{featuredLabel}</span>;
  } else {
    displaySpan = <span>{moment(displayDate).format('MMMM D, YYYY')}</span>;
  }

  function handleHighlight(currentHighlight) {
    setHighlight(currentHighlight);
  }

  return (
    <div
      className={classNames({
        insight: true,
        'insight--featured': featured || isFeatured,
      })}
    >
      <InsightImage
        url={url}
        imageUrl={imageUrl}
        imageAlt={imageAlt}
        type={
          redefinersPodcast
            ? labels.find((o) => o.key === 'label.redefiners.podcast')?.value
            : type
        }
        highlight={highlight}
        onHighlight={handleHighlight}
        podcastUrl={podcastUrl}
        podcastTitle={podcastTitle}
      />
      <div className="insight__content">
        <div className="insight__meta">
          {authors && authors.length > 0 && (
            <div className="insight__authors speakers">
              <AuthorsList authors={authors} authorsLabel={authorsLabel} />
            </div>
          )}
          <div className="insight__date-read">
            <p
              className={`copy--sm ${typeof readTime === 'undefined' || readTime === '' ? 'no-bar' : ''}`}
            >
              {displaySpan}
              <span>{readTime}</span>
            </p>
          </div>
        </div>
        <div className="insight__title">
          <div class="heading3">
            <a
              className={classNames({ hover: highlight })}
              href={url}
              onMouseEnter={() => handleHighlight(true)}
              onMouseLeave={() => handleHighlight(false)}
              onFocus={() => handleHighlight(true)}
              onBlur={() => handleHighlight(false)}
            >
              {title}
            </a>
          </div>
        </div>
        <div className="insight__tags">
          {tags.map((tag, index) => (
            <a href={tag.tagUrl || 'javascript:void(0)'} key={index}>
              {tag.tagName}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export const InsightPodcast = ({
  assetTypeTag: { tagName: type },
  isFeatured,
  featured = false,
  url,
  imageUrl,
  imageAlt,
  title,
  displayDate,
  readTime,
  tags = [],
  authors = [],
  authorsLabel,
  redefinersPodcast,
  podcastUrl,
  podcastTitle,
  featuredLabel,
  labels,
}) => {
  const [highlight, setHighlight] = React.useState(false);

  let displaySpan;
  if (isFeatured) {
    displaySpan = <span>{featuredLabel}</span>;
  } else {
    displaySpan = <span>{moment(displayDate).format('MMMM D, YYYY')}</span>;
  }

  function handleHighlight(currentHighlight) {
    setHighlight(currentHighlight);
  }

  return (
    <div
      className={classNames({
        insight: true,
        'insight--featured': featured || isFeatured,
      })}
    >
      <InsightImage
        url={url}
        imageUrl={imageUrl}
        imageAlt={imageAlt}
        type={
          redefinersPodcast
            ? labels.find((o) => o.key === 'label.redefiners.podcast')?.value
            : type
        }
        highlight={highlight}
        onHighlight={handleHighlight}
        podcastUrl={podcastUrl}
        podcastTitle={podcastTitle}
      />
      <div className="insight__content">
        <div className="insight__meta">
          {authors && authors.length > 0 && (
            <div className="insight__authors speakers">
              <AuthorsList authors={authors} authorsLabel={authorsLabel} />
            </div>
          )}
          <div className="insight__date-read">
            <p className="copy--sm">
              {displaySpan}
              <span>{readTime}</span>
            </p>
          </div>
        </div>
        <div className="insight__title">
          <h3>
            <a
              className={classNames({ hover: highlight })}
              href={url}
              onMouseEnter={() => handleHighlight(true)}
              onMouseLeave={() => handleHighlight(false)}
              onFocus={() => handleHighlight(true)}
              onBlur={() => handleHighlight(false)}
            >
              {title}
            </a>
          </h3>
        </div>
        <div className="insight__tags">
          {tags.map((tag, index) => (
            <a href={tag.tagUrl || 'javascript:void(0)'} key={index}>
              {tag.tagName}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};
