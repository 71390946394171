/* eslint-disable func-names */
/* eslint-disable no-undef */

import { getImagePath } from './util';
import tippy from 'tippy.js';
import { setEngagementGoal, ENGAGEMENT_GOAL_TYPES } from './api/request';

export default function () {
  let map;
  let infowindow;
  let points = [];
  let googleMarkers = [];
  let toolTips = [];
  const mql = window.matchMedia('(min-width: 992px)');

  const el = document.querySelector('.map-container');
  if (!el) return;
  const target = el.querySelector('.map--overflow');
  const dataTarget = el.querySelector('#map_data');

  if (!dataTarget) return;
  let json = `${dataTarget.textContent.trim()}`;
  json = JSON.parse(json);
  points = json.points;

  const API_KEY = json.apiKey;
  const closeEl = el.querySelector('.map__close');

  closeEl.addEventListener('click', (evt) => {
    target.classList.remove('active');
    closeEl.style.display = 'none';
    target.querySelector('img#map_svg').style.display = '';
    target.querySelector('#map').style.display = 'none';
    setTimeout(() => {
      closeEl.style.display = '';
    }, 600);
  });

  function addTippy(element, msg) {
    const tip = tippy(element, {
      content: `<div class="map__marker-text">${msg}</div>`,
      allowHTML: true,
    });
    toolTips.push(tip);
  }

  function enableTooltips() {
    if (toolTips.length) {
      toolTips.forEach((tip) => {
        tip.enable();
      });
    }
  }

  function disableTooltips() {
    // disable too
    toolTips.forEach((tip) => {
      tip.disable();
    });
  }

  function handleWindowResize(e) {
    if (e.matches) {
      enableTooltips();
    } else {
      disableTooltips();
    }
  }

  function showTooltip(index) {
    const address = points[index].address;
    let authors = '';

    if (
      address.consultants &&
      Array.isArray(address.consultants) &&
      !!address.consultants.length
    ) {
      for (let index = 0; index < address.consultants.length; index++) {
        const consult = address.consultants[index];

        if (index < 3) {
          authors += `
              <div class='author'>
                <img src='${consult}' alt='Photo of a person' />
              </div>
            `;
        }
      }
    }

    if (address.authorsLabel) {
      address.authorsLabel = `
          <span>${address.authorsLabel}</span>
        `;
    }

    const consultants = `
        <div class='authors'>
          ${authors}
          ${address.authorsLabel}
        </div>
      `;

    const contentString = `
        <div class="map-tooltip" data-animation="true">
          <div class="city">
            ${address.city}
          </div>

          <div class="place">
            <a >
              ${address.place}<br>
              ${address.number}<br>
              ${address.zip}<br>
              ${address.country}
            </a>
          </div>

          <div class="phone">
            <a >
              ${address.phone}
            </a>
          </div>

          <div class="consultants">
            ${consultants}
          </div>
        </div>
      `;

    let div = document.createElement('div');
    div.innerHTML = contentString;
    div.onclick = function () {
      window.location.href = points[index].href;
    };

    if (infowindow) infowindow.close();
    infowindow = new google.maps.InfoWindow({
      content: div,
    });

    const gMarker = googleMarkers[index];

    infowindow.open({
      anchor: gMarker,
      map,
      shouldFocus: false,
    });
  }

  // eslint-disable-next-line no-shadow
  function smoothZoom(map, max, cnt, index) {
    if (cnt < max) {
      const z = google.maps.event.addListener(
        map,
        'zoom_changed',
        function (event) {
          google.maps.event.removeListener(z);
          smoothZoom(map, max, cnt + 1, index);
        }
      );

      setTimeout(function () {
        map.setZoom(cnt);
      }, 80);
    } else {
      showTooltip(index);
      // TODO apply universal content reveal for the info
      // how ?
      // - artur
    }
  }

  const markerTemplate = document.getElementById('marker_template');

  const markerOffsetX = markerTemplate.clientWidth / 2;
  const markerOffsetY = markerTemplate.clientHeight / 2 - 30;

  function onMarkerClick(evt) {
    const { srcElement } = evt;
    const { index } = srcElement.dataset;

    const coords = new google.maps.LatLng(
      points[index].mapCoords.lat,
      points[index].mapCoords.lng
    );

    map.setCenter(coords); // set map center to marker position
    map.setZoom(4);

    target.classList.add('active');

    setTimeout(() => {
      target.querySelector('#map').style.display = '';
      smoothZoom(map, 16, map.getZoom(), index);
      target.querySelector('img#map_svg').style.display = 'none';
      setEngagementGoal(ENGAGEMENT_GOAL_TYPES.INTERACT_MAP);
    }, 2100);
  }

  // eslint-disable-next-line no-shadow
  function renderMarker(point, createMarker, index = 0) {
    let realCreateMarker = createMarker || false;
    let marker = el.querySelector('.map--marker');

    if (!marker || realCreateMarker) {
      marker = markerTemplate.cloneNode();
      marker.id = '';
      marker.dataset.index = index;
      marker.addEventListener('click', onMarkerClick);
      target.append(marker);

      const gMarker = new google.maps.Marker({
        position: new google.maps.LatLng(
          point.mapCoords.lat,
          point.mapCoords.lng
        ),
        icon: getImagePath('images/map-marker.png'),
        map: map,
      });
      gMarker.addListener('click', () => {
        showTooltip(index);
      });

      googleMarkers.push(gMarker);
    }

    marker.style.left = `${point.imagePosition[0] - markerOffsetX}px`;
    marker.style.top = `${point.imagePosition[1] - markerOffsetY}px`;
    addTippy(marker, point.address.city);
  }

  // eslint-disable-next-line no-shadow
  function renderMarkers() {
    for (let i = 0; i < points.length; i += 1) {
      renderMarker(points[i], true, i);
    }
  }

  function init() {
    var mapOptions = {
      zoom: 2,
      center: new google.maps.LatLng(40.67, -73.94), // New York
      styles: [
        {
          featureType: 'all',
          elementType: 'labels.text.fill',
          stylers: [
            { saturation: 36 },
            { color: '#000000' },
            { lightness: 40 },
          ],
        },
        {
          featureType: 'all',
          elementType: 'labels.text.stroke',
          stylers: [
            { visibility: 'on' },
            { color: '#000000' },
            { lightness: 16 },
          ],
        },
        {
          featureType: 'all',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.fill',
          stylers: [{ color: '#000000' }, { lightness: 20 }],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#000000' }, { lightness: 17 }, { weight: 1.2 }],
        },
        {
          featureType: 'administrative',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'administrative.country',
          elementType: 'all',
          stylers: [{ visibility: 'simplified' }],
        },
        {
          featureType: 'administrative.country',
          elementType: 'geometry',
          stylers: [{ visibility: 'simplified' }],
        },
        {
          featureType: 'administrative.country',
          elementType: 'labels.text',
          stylers: [{ visibility: 'simplified' }],
        },
        {
          featureType: 'administrative.province',
          elementType: 'all',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'administrative.locality',
          elementType: 'all',
          stylers: [
            { visibility: 'simplified' },
            { saturation: '-100' },
            { lightness: '30' },
          ],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'all',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'all',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'landscape',
          elementType: 'all',
          stylers: [
            { visibility: 'simplified' },
            { gamma: '0.00' },
            { lightness: '74' },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 20 }],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'all',
          stylers: [{ lightness: '3' }],
        },
        {
          featureType: 'poi',
          elementType: 'all',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 21 }],
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [{ visibility: 'simplified' }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [{ color: '#000000' }, { lightness: 17 }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#000000' }, { lightness: 29 }, { weight: 0.2 }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 18 }],
        },
        {
          featureType: 'road.local',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 16 }],
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 19 }],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{ color: '#000000' }, { lightness: 17 }],
        },
      ],
    };

    var mapElement = document.getElementById('map');

    map = new google.maps.Map(mapElement, mapOptions);
    renderMarkers();
  }

  function onScriptLoad() {
    init();
  }

  // Start the google maps loader
  if (document.querySelector('#google-maps-script')) {
    init();
    return;
  }

  const script = document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}`;
  script.setAttribute('id', 'google-maps-script');
  script.addEventListener('load', onScriptLoad);
  mql.addEventListener('change', handleWindowResize);
  script.async = true;

  document.head.appendChild(script);
}
