import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const SECTION_MENU_WHITE = '.section-menu--white';

export default function colorTrigger(elementName, lightClass, position = 0) {
  var sections = gsap.utils.toArray(SECTION_MENU_WHITE);

  const element = document.querySelector(elementName);
  if (!element) return;

  const onEnter = () => {
    const sticky = Number(element.dataset.sticky || 0) + 1;
    element.dataset.sticky = sticky;
    element.classList.add(lightClass);
  };
  const onEnterBack = onEnter;

  const onLeave = () => {
    const sticky = Number(element.dataset.sticky || 0) - 1;
    const newSticky = sticky < 0 ? 0 : sticky;
    element.dataset.sticky = newSticky;
    if (newSticky === 0) element.classList.remove(lightClass);
  };
  const onLeaveBack = onLeave;

  sections.forEach((section) => {
    ScrollTrigger.create({
      onEnter,
      onEnterBack,
      onLeave,
      onLeaveBack,
      trigger: section,
      start: `top top+=${position}`,
      end: `bottom top+=${position}`,
      markers: false,
    });
  });
}
