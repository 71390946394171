import { setEngagementGoal, ENGAGEMENT_GOAL_TYPES } from './api/request';
import playerjs from 'player.js';

export function initializePodcastPlayer() {
  const podcasts = document.querySelectorAll('iframe.podcast');
  podcasts.forEach((podcast) => {
    let goalTriggered = false;
    const player = new playerjs.Player(podcast);

    player.on('ready', () => {
      player.on('play', () => {
        if (!goalTriggered) {
          // API call to Sitecore backend to set user engagement score for successfully playing a unique podcast
          setEngagementGoal(ENGAGEMENT_GOAL_TYPES.INTERVIEW_PODCAST);
          goalTriggered = true;
        }
      });
    });
  });
}
