import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export function initializeAnimation(section, startPercentage = '80%') {
  // we're not doing the animation any longer so let's just return false
  return false;

  if (!section) return;

  /**
   * Callback function that sets the animation attribute to true.
   */
  const animateElement = (element) => {
    // eslint-disable-next-line no-param-reassign
    element.dataset.animation = true;
  };

  /**
   * Toggle the animation attribute when an element is 80%
   * from the top of the viewport.
   */
  ScrollTrigger.matchMedia({
    '(prefers-reduced-motion: no-preference)': function () {
      gsap.to(section, {
        scrollTrigger: {
          trigger: section,
          start: 'top bottom',
          scrub: true,
          end: () => ('+=' + section ? section.innerHeight : '0'),
          markers: false,
          onEnter: () => animateElement(section),
        },
      });
    },
  });
}

export function initializeAnimations() {
  const sections = gsap.utils.toArray('[data-animation]:not(.case-study--txt)');

  if (!sections) {
    return;
  }

  /**
   * Toggle the animation attribute when an element is 60%
   * from the top of the viewport.
   */
  sections.forEach((section) => {
    initializeAnimation(section);
  });
}
