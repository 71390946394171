export function getImagePath(path) {
  if (import.meta.env?.STORYBOOK) return path;
  return `/dist/${path}`;
}

export const placeholder1xImagePath = getImagePath(
  '/images/placeholder-1x.png'
);
export const placeholder2xImagePath = getImagePath(
  '/images/placeholder-2x.png'
);

export const smoothAnchorScroll = () => {
  const links = Array.from(document.querySelectorAll('a[href^="#"]'));
  const validLinks = links.filter(
    (link) => link.attributes.getNamedItem('href').value.length > 1
  );
  validLinks.forEach((anchor) => {
    anchor.addEventListener('click', (e) => {
      e.preventDefault();
      document.querySelector(e.target.getAttribute('href')).scrollIntoView({
        behavior: 'smooth',
      });
    });
  });
};
