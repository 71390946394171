import GLightbox from 'glightbox';

export function initializeBrightcoveVideoEmbed() {
  const brightcoveLinks = Array.from(
    document.querySelectorAll('.glightbox--brightcove')
  );
  brightcoveLinks.forEach((link) => {
    const accountId = link.dataset.accountid;
    const videoId = link.dataset.videoid;
    const scriptUrl = link.dataset.scripturl;
    if (accountId && videoId && scriptUrl) {
      link.addEventListener('click', () => {
        const myGallery = GLightbox({
          elements: [
            {
              content: `
              <video-js
                data-account='${accountId}'
                data-player='default'
                data-embed='default'
                controls=''
                data-video-id='${videoId}'
                data-playlist-id=''
                playsinline
                autoplay
                data-application-id=''
                class='vjs-fluid'
              ></video-js>
            `,
            },
          ],
          autoplayVideos: true,
        });
        myGallery.open();
        setTimeout(() => {
          const s = document.createElement('script');
          s.setAttribute('type', 'text/javascript');
          s.setAttribute('src', scriptUrl);
          document.getElementsByTagName('head')[0].appendChild(s);
        }, 0);

        // create new close button markup
        const closeButton = document.querySelector('.gclose');
        closeButton.innerHTML = `<span class="close-inner">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.646447" y1="12.6673" x2="12.6673" y2="0.646445" stroke="white"/>
            <line x1="0.353553" y1="0.66726" x2="12.3744" y2="12.6881" stroke="white"/>
          </svg>
        </span>`;

        document.querySelector('.gslide-media').appendChild(closeButton);
        document
          .querySelector('.glightbox-container')
          .classList.add('glightbox--brightcove');
      });
    }
  });
}
