import * as React from 'react';
import { render } from 'react-dom';
import { LatestInsightsConsultant } from './components/LatestInsightsConsultant';
import { decorateInsightHover } from './latest-insights-common';
import { getDefaultFilters, knownConsultantTagTypes } from './components/Filter';

function toggleFilterSlide(parent) {
  const filterButton = parent.querySelector('#filter__button');
  const filterContainer = parent.querySelector('.filter__container');
  const filterContent = parent.querySelector('.filter__content');

  filterButton.addEventListener('click', (e) => {
    if (filterContainer.classList.contains('filter__container--show')) {
      filterContent.classList.remove('filter__content--show');

      setTimeout(() => {
        filterButton.classList.remove('filter__button--active');
        filterContainer.classList.remove('filter__container--show');
      }, 1000);
    } else {
      filterButton.classList.add('filter__button--active');
      filterContainer.classList.add('filter__container--show');

      setTimeout(() => {
        filterContent.classList.add('filter__content--show');
      }, 1000);
    }
  });
}

function selectFilterItem(parent) {
  const filterItem = parent.querySelectorAll('.filter__item');

  filterItem.forEach((item) => {
    item.addEventListener('click', (e) => {
      e.preventDefault();
      item.classList.toggle('filter__item--selected');
    });
  });
}

export function decorateLatestInsightsConsultant(demo = false) {
  const latestInsightsConsultant = document.querySelector(
    '.latest-insights-consultant-container'
  );

  if (!latestInsightsConsultant) {
    return;
  }

  if (demo) {
    decorateInsightHover(latestInsightsConsultant);
    selectFilterItem(latestInsightsConsultant);
    toggleFilterSlide(latestInsightsConsultant);
  }
}

export function initializeLatestInsightsConsultant() {
  const root = document.getElementById('latest-insights-consultant-root');
  if (typeof root?.dataset?.author === 'undefined') return;
  render(
    <LatestInsightsConsultant 
      authorPath={root.dataset.author}
      contextPageId={root.dataset.pagecontext}
      defaultFilters={getDefaultFilters(knownConsultantTagTypes)}
      language={root.dataset.language}
    />,
    root
  );
}
