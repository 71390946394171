import * as React from 'react';
import { LatestInsights } from './LatestInsights';
import { getLatestInsightsInsightData } from '../api/request';
import { decorateLatestInsightsNewsroom } from '../latest-insights-newsroom';
import { latestInsightsInsightsData } from '../api/mockdata';
import { Insight } from './Insights';

export const LatestInsightsNewsroom = ({ datasourceId, contextPageId, defaultFilters, language="en" }) => (
  <LatestInsights
    variant="newsroom"
    language={language}
    datasourceId={datasourceId}
    contextPageId={contextPageId}
    defaultFilters={defaultFilters}
    onGetData={getLatestInsightsInsightData}
    //onGetData={async () => latestInsightsInsightsData}
    onRenderInsight={(data) => <Insight {...data} />}
    onDecorate={() => decorateLatestInsightsNewsroom()}
    onBuildFilters={(data) => [
      {
        control: 'dropdown',
        type: 'topic',
        name: data.insightTopicTags.description,
        filterType: 'insightTopicTags',
        filterItems: data.insightTopicTags.facetValues,
      },
      {
        control: 'dropdown',
        type: 'calculatedIndustryTags',
        name: data.calculatedIndustryTags.description,
        filterType: 'calculatedIndustryTags',
        filterItems: data.calculatedIndustryTags.facetValues,
      },
      {
        control: 'dropdown',
        type: 'function',
        name: data.areaOfExpertiseTags.description,
        filterType: 'areaOfExpertiseTags',
        filterItems: data.areaOfExpertiseTags.facetValues,
      },
      {
        control: 'dropdown',
        type: 'type',
        name: data.assetTypeTags.description,
        filterType: 'assetTypeTags',
        filterItems: data.assetTypeTags.facetValues,
      },
    ]}
    onBuildTags={(insight) => [
      ...(insight.insightTopicTags || []),
      ...(insight.businessIssueTags || []),
      ...(insight.calculatedIndustryTags || []),
      ...(insight.calculatedSubIndustryDescriptions || []),
      ...(insight.areaOfExpertiseTags || []),
      ...(insight.capabilityTags || []),
    ]}
  />
);
