export function headingTagsOverlap() {
  const tagsMobile = document.querySelector('.heading-tags-mobile');
  const tagsMobileHeight = tagsMobile.offsetHeight;
  const tagsDesktop = document.querySelector('.heading-tags-desktop');
  const tagsDesktopHeight = tagsDesktop.offsetHeight;

  if (tagsMobileHeight > 159) {
    tagsMobile.classList.add('has-overlay');
  }

  if (tagsDesktopHeight > 106) {
    tagsDesktop.classList.add('has-overlay');
  }
}
