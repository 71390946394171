/* eslint-disable func-names */
/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 * From the wizard: https://davidwalsh.name/javascript-debounce-function
 * @param {function} func the function to be debounced.
 * @param {number} wait the amount of time to debounce.
 * @param {boolean} immediate whether to trigger the function on the leading edge vs. trailing.
 */
export default function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this;
    var args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
