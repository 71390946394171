import * as React from 'react';
import { render } from 'react-dom';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { InsightsShowcase } from './components/InsightsShowcase';
import Glide from '@glidejs/glide';
import { activateCarousel } from './carousel-global';

gsap.registerPlugin(ScrollTrigger);

export function decorateInsights() {
  const insightsContainer = document.querySelector('.insights--wrapper');

  if (!insightsContainer) {
    return;
  }

  const insights = insightsContainer.querySelectorAll('.insight');
  insights.forEach((insight) => {
    const insightImage = insight.querySelector('.insight--image img');
    const insightTitle = insight.querySelector('.insight h2 a');
    if (!insightImage || !insightTitle) return;
    insight.addEventListener('mouseover', () => {
      insight.classList.add(classes.active);
      insightImage.classList.add('hover');
      insightTitle.classList.add('hover');
    });
    insight.addEventListener('mouseout', () => {
      insight.classList.remove(classes.active);
      insightImage.classList.remove('hover');
      insightTitle.classList.remove('hover');
    });
  });

  const classes = {
    active: 'active',
  };

  /**
   * Reveal the insights content.
   */
  const insightsReveal = () => {
    const insights = insightsContainer.querySelectorAll('.insight');
    insights.forEach((insight) => {
      // When we are 10% scrolled on an insight, initiate the reveal animation.
      // See https://greensock.com/docs/v3/Plugins/ScrollTrigger for details on this plugin.
      ScrollTrigger.create({
        trigger: insight,
        start: 'top bottom',
        end: 'bottom 30%',
        onEnter: () => insight.classList.add(classes.active),
        onLeave: () => insight.classList.remove(classes.active),
        onEnterBack: () => insight.classList.add(classes.active),
        onLeaveBack: () => insight.classList.remove(classes.active),
      });
    });
  };

  /**
   * Toggle the insights filters on mobile.
   */
  const insightsFiltersMobile = () => {
    // The mobile label is the first element in the container and our trigger.
    const filterButton = insightsContainer.querySelector('.mobile-label');
    // The trigger is our parent.
    const filterContainer = filterButton.parentElement;
    // We dynamically get the height of the container so we can reset back to it.
    const filterHeight = window.getComputedStyle(filterContainer).height;

    // Toggle the filter container height on click.
    filterButton.addEventListener('click', () => {
      if (!filterContainer.classList.contains(classes.active)) {
        filterContainer.classList.add(classes.active);
        gsap.to(filterContainer, { height: 'auto' });
      } else {
        filterContainer.classList.remove(classes.active);
        gsap.to(filterContainer, { height: filterHeight });
      }
    });
  };

  const initializeCarousel = () => {
    const carousel = document.querySelector('.insights-container .carousel');
    activateCarousel(carousel);
  };

  insightsReveal();
  insightsFiltersMobile();
  initializeCarousel();
}

export function initializeInsights() {
  const root = document.getElementById('insights-showcase-root');
  if (typeof root?.dataset?.datasourceId === 'undefined') return;
  const noOverlay = root?.dataset?.noOverlay
    ? root?.dataset?.noOverlay.toLocaleLowerCase() === 'true'
    : undefined;
  if (root)
    render(
      <InsightsShowcase
        datasourceId={root?.dataset?.datasourceId}
        contextPageId={root.dataset.pagecontext}
        noOverlay={noOverlay}
        language={root.dataset.language}
      />,
      root
    );
}
