export const consultantDirectoryData = {
  totalNumberOfResults: 540,
  pageSize: 15,
  profiles: [
    {
      fullName: 'Alessandra Abate',
      url: '/en/people/consultant-directory/alessandra-abate',
      imageUrl:
        'https://mss-p-053.stylelabs.cloud/api/public/content/c0c2b201164348c4a88fb0441bdc1b3e?v=b8608c20',
      imageAlt: '',
      titleTag: {
        tagId: '136b2c8ec1cf45b48cace4b0d9ce38cb',
        tagName: 'Managing Director - MD',
      },
      location: {
        country: {
          countryId: '5e871d44629d46deb267e5129a731958',
          countryName: 'Italy',
        },
        city: {
          cityId: '7e81dcc37ae34cf8962eed65664a870d',
          cityName: 'Milan',
          cityCount: null,
        },
      },
      capabilityTags: [
        {
          tagId: '78cc17b1678f49d4b33c5195b8aa7c76',
          tagName: 'Executive Search',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=78cc17b1678f49d4b33c5195b8aa7c76',
        },
        {
          tagId: 'a6126747b13f4af0b50e26a67a9f62d2',
          tagName: 'Assessment and Benchmarking',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=a6126747b13f4af0b50e26a67a9f62d2',
        },
        {
          tagId: 'a371fb9cadfe4f9c893d44e3a51a8ef6',
          tagName: 'Diversity, Equity, and Inclusion Advisory',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=a371fb9cadfe4f9c893d44e3a51a8ef6',
        },
        {
          tagId: '5efc117e7e7c4e6086e321e2aff9bfd3',
          tagName: 'C-Suite Succession',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=5efc117e7e7c4e6086e321e2aff9bfd3',
        },
        {
          tagId: '93a3df00f38d46df8338932617a24558',
          tagName: 'Development and Transition',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=93a3df00f38d46df8338932617a24558',
        },
      ],
      industryTags: [
        {
          tagId: '56ee3de883794470a5493a3385b0cd10',
          tagName: 'Healthcare',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?industryTags=56ee3de883794470a5493a3385b0cd10',
        },
        {
          tagId: '573e6774881c43749315ae7f304175ae',
          tagName: 'Technology',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?industryTags=573e6774881c43749315ae7f304175ae',
        },
      ],
      areaOfExpertiseTags: [
        {
          tagId: '2be2f08c95694399a8a627209bef3e6c',
          tagName: 'Human Resources',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?areaOfExpertiseTags=2be2f08c95694399a8a627209bef3e6c',
        },
      ],
      businessIssueTags: [
        {
          tagId: '5e9903f0da4d44ffb5adde3a8b3fb157',
          tagName: 'Succession Planning',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?businessIssueTags=5e9903f0da4d44ffb5adde3a8b3fb157',
        },
        {
          tagId: 'a6713612cd654871830c94fdf2fb907f',
          tagName: 'Transformation  Innovation',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?businessIssueTags=a6713612cd654871830c94fdf2fb907f',
        },
      ],
      insightTopicTags: [],
      calculatedIndustryTags: [
        {
          tagId: '56ee3de883794470a5493a3385b0cd10',
          tagName: 'Healthcare',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?calculatedIndustryTags=56ee3de883794470a5493a3385b0cd10',
        },
        {
          tagId: '573e6774881c43749315ae7f304175ae',
          tagName: 'Technology',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?calculatedIndustryTags=573e6774881c43749315ae7f304175ae',
        },
      ],
      calculatedSubIndustryDescriptions: [],
    },
    {
      fullName: 'Anthony Abbatiello',
      url: '/en/people/consultant-directory/anthony-abbatiello',
      imageUrl:
        'https://mss-p-053.stylelabs.cloud/api/public/content/620bf4be21564f799b37b6cec08c7d8a?v=129ec071',
      imageAlt: '',
      titleTag: {
        tagId: '136b2c8ec1cf45b48cace4b0d9ce38cb',
        tagName: 'Managing Director - MD',
      },
      location: {
        country: {
          countryId: '8ad216671f3f49e3a83f3d4476ba256a',
          countryName: 'United States',
        },
        city: {
          cityId: 'f58884fc56dd4d01a713c7a2404755f2',
          cityName: 'New York',
          cityCount: null,
        },
      },
      capabilityTags: [
        {
          tagId: 'a6126747b13f4af0b50e26a67a9f62d2',
          tagName: 'Assessment and Benchmarking',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=a6126747b13f4af0b50e26a67a9f62d2',
        },
        {
          tagId: 'a4e07688daef4167a8f242ff2af323cc',
          tagName: 'Board Effectiveness',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=a4e07688daef4167a8f242ff2af323cc',
        },
        {
          tagId: '2529546d07b94f97aaa136c987c5f05a',
          tagName: 'Culture Analytics',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=2529546d07b94f97aaa136c987c5f05a',
        },
        {
          tagId: '93a3df00f38d46df8338932617a24558',
          tagName: 'Development and Transition',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=93a3df00f38d46df8338932617a24558',
        },
        {
          tagId: '43122093b45b47a7809abbe7d3f7a5d2',
          tagName: 'CEO Succession',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=43122093b45b47a7809abbe7d3f7a5d2',
        },
      ],
      industryTags: [],
      areaOfExpertiseTags: [
        {
          tagId: 'efd50d93e55242789316c9cec87f543f',
          tagName: 'Technology, Data, and Digital',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?areaOfExpertiseTags=efd50d93e55242789316c9cec87f543f',
        },
        {
          tagId: 'e3d8ff5af5e840d4bf1d45f60905428c',
          tagName: 'Board and CEO Advisory',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?areaOfExpertiseTags=e3d8ff5af5e840d4bf1d45f60905428c',
        },
      ],
      businessIssueTags: [
        {
          tagId: '5e9903f0da4d44ffb5adde3a8b3fb157',
          tagName: 'Succession Planning',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?businessIssueTags=5e9903f0da4d44ffb5adde3a8b3fb157',
        },
      ],
      insightTopicTags: [],
      calculatedIndustryTags: [],
      calculatedSubIndustryDescriptions: [],
    },
    {
      fullName: 'Ilana Abramowicz',
      url: '/en/people/consultant-directory/ilana-abramowicz',
      imageUrl:
        'https://mss-p-053.stylelabs.cloud/api/public/content/6aaa062e6eaa4230a801f758508d6f53?v=73d86fc4',
      imageAlt: '',
      titleTag: {},
      location: {
        country: {
          countryId: 'b86e5bf48dc84749b35b8c85ad44e282',
          countryName: 'England',
        },
        city: {
          cityId: 'df4272721d7a42fe91a8af659b27cce2',
          cityName: 'London',
          cityCount: null,
        },
      },
      capabilityTags: [
        {
          tagId: 'a6126747b13f4af0b50e26a67a9f62d2',
          tagName: 'Assessment and Benchmarking',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=a6126747b13f4af0b50e26a67a9f62d2',
        },
        {
          tagId: '78cc17b1678f49d4b33c5195b8aa7c76',
          tagName: 'Executive Search',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=78cc17b1678f49d4b33c5195b8aa7c76',
        },
        {
          tagId: '93a3df00f38d46df8338932617a24558',
          tagName: 'Development and Transition',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=93a3df00f38d46df8338932617a24558',
        },
        {
          tagId: '5efc117e7e7c4e6086e321e2aff9bfd3',
          tagName: 'C-Suite Succession',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=5efc117e7e7c4e6086e321e2aff9bfd3',
        },
      ],
      industryTags: [],
      areaOfExpertiseTags: [],
      businessIssueTags: [
        {
          tagId: '5e9903f0da4d44ffb5adde3a8b3fb157',
          tagName: 'Succession Planning',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?businessIssueTags=5e9903f0da4d44ffb5adde3a8b3fb157',
        },
      ],
      insightTopicTags: [],
      calculatedIndustryTags: [],
      calculatedSubIndustryDescriptions: [],
    },
    {
      fullName: 'Catarina Abrantes',
      url: '/en/people/consultant-directory/catarina-abrantes',
      imageUrl:
        'https://mss-p-053.stylelabs.cloud/api/public/content/5f27526b57594fd8ab43ac8d8f05eddb?v=597f23c5',
      imageAlt: '',
      titleTag: {},
      location: {
        country: {
          countryId: 'b86e5bf48dc84749b35b8c85ad44e282',
          countryName: 'England',
        },
        city: {
          cityId: 'df4272721d7a42fe91a8af659b27cce2',
          cityName: 'London',
          cityCount: null,
        },
      },
      capabilityTags: [
        {
          tagId: '93a3df00f38d46df8338932617a24558',
          tagName: 'Development and Transition',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=93a3df00f38d46df8338932617a24558',
        },
        {
          tagId: '78cc17b1678f49d4b33c5195b8aa7c76',
          tagName: 'Executive Search',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=78cc17b1678f49d4b33c5195b8aa7c76',
        },
      ],
      industryTags: [
        {
          tagId: '397299e892be4513bdfa4b3fead404e5',
          tagName: 'Industrial',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?industryTags=397299e892be4513bdfa4b3fead404e5',
        },
        {
          tagId: 'acfc56031f5b429e97a2764911c6ae6a',
          tagName: 'Private Capital',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?industryTags=acfc56031f5b429e97a2764911c6ae6a',
        },
      ],
      areaOfExpertiseTags: [
        {
          tagId: 'efd50d93e55242789316c9cec87f543f',
          tagName: 'Technology, Data, and Digital',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?areaOfExpertiseTags=efd50d93e55242789316c9cec87f543f',
        },
      ],
      businessIssueTags: [
        {
          tagId: '845553503aee472988fe2ce2188b088c',
          tagName: 'Culture Risk',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?businessIssueTags=845553503aee472988fe2ce2188b088c',
        },
      ],
      insightTopicTags: [],
      calculatedIndustryTags: [
        {
          tagId: '397299e892be4513bdfa4b3fead404e5',
          tagName: 'Industrial',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?calculatedIndustryTags=397299e892be4513bdfa4b3fead404e5',
        },
        {
          tagId: 'acfc56031f5b429e97a2764911c6ae6a',
          tagName: 'Private Capital',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?calculatedIndustryTags=acfc56031f5b429e97a2764911c6ae6a',
        },
      ],
      calculatedSubIndustryDescriptions: [],
    },
    {
      fullName: 'Mark Adams',
      url: '/en/people/consultant-directory/mark-adams',
      imageUrl:
        'https://mss-p-053.stylelabs.cloud/api/public/content/06002ad7a3464a6e913a9f771cb6566f?v=f844b67d',
      imageAlt: '',
      titleTag: {},
      location: {
        country: {
          countryId: '8ad216671f3f49e3a83f3d4476ba256a',
          countryName: 'United States',
        },
        city: {
          cityId: 'ca4377418a294345bbd4047bb4f6a2d1',
          cityName: 'Boston',
          cityCount: null,
        },
      },
      capabilityTags: [
        {
          tagId: '78cc17b1678f49d4b33c5195b8aa7c76',
          tagName: 'Executive Search',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=78cc17b1678f49d4b33c5195b8aa7c76',
        },
        {
          tagId: 'a4e07688daef4167a8f242ff2af323cc',
          tagName: 'Board Effectiveness',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=a4e07688daef4167a8f242ff2af323cc',
        },
        {
          tagId: 'c2050c377cd54f29932d0afe8ade9ac7',
          tagName: 'Board Director and Chair Search',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=c2050c377cd54f29932d0afe8ade9ac7',
        },
        {
          tagId: '43122093b45b47a7809abbe7d3f7a5d2',
          tagName: 'CEO Succession',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=43122093b45b47a7809abbe7d3f7a5d2',
        },
      ],
      industryTags: [
        {
          tagId: 'acfc56031f5b429e97a2764911c6ae6a',
          tagName: 'Private Capital',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?industryTags=acfc56031f5b429e97a2764911c6ae6a',
        },
        {
          tagId: 'f5dffc5e9f62436ea00541933bb9aced',
          tagName: 'Financial Services',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?industryTags=f5dffc5e9f62436ea00541933bb9aced',
        },
      ],
      areaOfExpertiseTags: [
        {
          tagId: 'e3d8ff5af5e840d4bf1d45f60905428c',
          tagName: 'Board and CEO Advisory',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?areaOfExpertiseTags=e3d8ff5af5e840d4bf1d45f60905428c',
        },
      ],
      businessIssueTags: [
        {
          tagId: 'b21fd9c0022949909716380a0434ccf0',
          tagName: 'Board Composition and Succession',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?businessIssueTags=b21fd9c0022949909716380a0434ccf0',
        },
        {
          tagId: 'e1e06c08388d4ec09778f291612a2900',
          tagName: 'Shareholder Stakeholder Engagement',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?businessIssueTags=e1e06c08388d4ec09778f291612a2900',
        },
        {
          tagId: '772535903c88476fbbd3e73e698bfb0e',
          tagName: 'Activism, Vulnerability, and Defense',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?businessIssueTags=772535903c88476fbbd3e73e698bfb0e',
        },
      ],
      insightTopicTags: [],
      calculatedIndustryTags: [
        {
          tagId: 'acfc56031f5b429e97a2764911c6ae6a',
          tagName: 'Private Capital',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?calculatedIndustryTags=acfc56031f5b429e97a2764911c6ae6a',
        },
        {
          tagId: 'f5dffc5e9f62436ea00541933bb9aced',
          tagName: 'Financial Services',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?calculatedIndustryTags=f5dffc5e9f62436ea00541933bb9aced',
        },
      ],
      calculatedSubIndustryDescriptions: [],
    },
    {
      fullName: 'Ines Affolter',
      url: '/en/people/consultant-directory/ines-affolter',
      imageUrl:
        'https://mss-p-053.stylelabs.cloud/api/public/content/5e976ac88ebd40e182571bdbb64fcccb?v=491be7d7',
      imageAlt: '',
      titleTag: {
        tagId: '57b869676de9421cb47fd5963e69fb69',
        tagName: 'Associate',
      },
      location: {
        country: {
          countryId: '6a1485e66e474312abd2f50438e34db7',
          countryName: '',
        },
        city: {
          cityId: '5ea5ed894b24411485f825d1311e0bd7',
          cityName: 'Zurich',
          cityCount: null,
        },
      },
      capabilityTags: [
        {
          tagId: '78cc17b1678f49d4b33c5195b8aa7c76',
          tagName: 'Executive Search',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=78cc17b1678f49d4b33c5195b8aa7c76',
        },
      ],
      industryTags: [
        {
          tagId: '9c4e4dd66269480d8767d2b749d3c38b',
          tagName: 'Consumer',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?industryTags=9c4e4dd66269480d8767d2b749d3c38b',
        },
        {
          tagId: 'f5dffc5e9f62436ea00541933bb9aced',
          tagName: 'Financial Services',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?industryTags=f5dffc5e9f62436ea00541933bb9aced',
        },
      ],
      areaOfExpertiseTags: [
        {
          tagId: '2be2f08c95694399a8a627209bef3e6c',
          tagName: 'Human Resources',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?areaOfExpertiseTags=2be2f08c95694399a8a627209bef3e6c',
        },
      ],
      businessIssueTags: [],
      insightTopicTags: [],
      calculatedIndustryTags: [
        {
          tagId: '9c4e4dd66269480d8767d2b749d3c38b',
          tagName: 'Consumer',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?calculatedIndustryTags=9c4e4dd66269480d8767d2b749d3c38b',
        },
        {
          tagId: 'f5dffc5e9f62436ea00541933bb9aced',
          tagName: 'Financial Services',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?calculatedIndustryTags=f5dffc5e9f62436ea00541933bb9aced',
        },
      ],
      calculatedSubIndustryDescriptions: [],
    },
    {
      fullName: 'Carmen Aguiló',
      url: '/en/people/consultant-directory/carmen-aguilo',
      imageUrl:
        'https://mss-p-053.stylelabs.cloud/api/public/content/2cc9c198f57743f19c8f14dad4180db5?v=1eb0694e',
      imageAlt: '',
      titleTag: {},
      location: {
        country: {
          countryId: '8ad216671f3f49e3a83f3d4476ba256a',
          countryName: 'United States',
        },
        city: {
          cityId: '724a41522a4347cf8290257c59260b4b',
          cityName: 'Miami',
          cityCount: null,
        },
      },
      capabilityTags: [],
      industryTags: [
        {
          tagId: '9c4e4dd66269480d8767d2b749d3c38b',
          tagName: 'Consumer',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?industryTags=9c4e4dd66269480d8767d2b749d3c38b',
        },
      ],
      areaOfExpertiseTags: [
        {
          tagId: '1a75118a36794a74b328e7be663a0804',
          tagName: 'Corporate Affairs and Communication',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?areaOfExpertiseTags=1a75118a36794a74b328e7be663a0804',
        },
      ],
      businessIssueTags: [
        {
          tagId: '6f31c22a521c46d5b0be7f9a9956d5cb',
          tagName: 'Leadership',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?businessIssueTags=6f31c22a521c46d5b0be7f9a9956d5cb',
        },
      ],
      insightTopicTags: [],
      calculatedIndustryTags: [
        {
          tagId: '9c4e4dd66269480d8767d2b749d3c38b',
          tagName: 'Consumer',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?calculatedIndustryTags=9c4e4dd66269480d8767d2b749d3c38b',
        },
      ],
      calculatedSubIndustryDescriptions: [],
    },
    {
      fullName: 'Juha-Pekka Ahtikari',
      url: '/en/people/consultant-directory/juha-pekka-ahtikari',
      imageUrl:
        'https://mss-p-053.stylelabs.cloud/api/public/content/1098ee21b3954f2a9690f0eb0ca51e3d?v=62884046',
      imageAlt: '',
      titleTag: {
        tagId: '136b2c8ec1cf45b48cace4b0d9ce38cb',
        tagName: 'Managing Director - MD',
      },
      location: {
        country: {
          countryId: '28231f645e52465f8dfeb36b0a4c3094',
          countryName: 'Finland',
        },
        city: {
          cityId: '6f87ff22f48048cd833bccd96ef79f03',
          cityName: 'Helsinki',
          cityCount: null,
        },
      },
      capabilityTags: [
        {
          tagId: '78cc17b1678f49d4b33c5195b8aa7c76',
          tagName: 'Executive Search',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=78cc17b1678f49d4b33c5195b8aa7c76',
        },
        {
          tagId: 'a4e07688daef4167a8f242ff2af323cc',
          tagName: 'Board Effectiveness',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=a4e07688daef4167a8f242ff2af323cc',
        },
        {
          tagId: 'c2050c377cd54f29932d0afe8ade9ac7',
          tagName: 'Board Director and Chair Search',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=c2050c377cd54f29932d0afe8ade9ac7',
        },
      ],
      industryTags: [
        {
          tagId: '397299e892be4513bdfa4b3fead404e5',
          tagName: 'Industrial',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?industryTags=397299e892be4513bdfa4b3fead404e5',
        },
        {
          tagId: '9c4e4dd66269480d8767d2b749d3c38b',
          tagName: 'Consumer',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?industryTags=9c4e4dd66269480d8767d2b749d3c38b',
        },
      ],
      areaOfExpertiseTags: [
        {
          tagId: 'e3d8ff5af5e840d4bf1d45f60905428c',
          tagName: 'Board and CEO Advisory',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?areaOfExpertiseTags=e3d8ff5af5e840d4bf1d45f60905428c',
        },
      ],
      businessIssueTags: [
        {
          tagId: '1e6401e689cc4bc993ae629ee06a5a79',
          tagName: 'Customer Focused Growth',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?businessIssueTags=1e6401e689cc4bc993ae629ee06a5a79',
        },
        {
          tagId: '5e9903f0da4d44ffb5adde3a8b3fb157',
          tagName: 'Succession Planning',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?businessIssueTags=5e9903f0da4d44ffb5adde3a8b3fb157',
        },
      ],
      insightTopicTags: [],
      calculatedIndustryTags: [
        {
          tagId: '397299e892be4513bdfa4b3fead404e5',
          tagName: 'Industrial',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?calculatedIndustryTags=397299e892be4513bdfa4b3fead404e5',
        },
        {
          tagId: '9c4e4dd66269480d8767d2b749d3c38b',
          tagName: 'Consumer',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?calculatedIndustryTags=9c4e4dd66269480d8767d2b749d3c38b',
        },
      ],
      calculatedSubIndustryDescriptions: [],
    },
    {
      fullName: 'Boris Albers',
      url: '/en/people/consultant-directory/boris-albers',
      imageUrl:
        'https://mss-p-053.stylelabs.cloud/api/public/content/689dc559107b4cf1be7e9c0111ac8494?v=4158a1e7',
      imageAlt: '',
      titleTag: {},
      location: {
        country: {
          countryId: 'b41968dc605b46468a36e76b337979e8',
          countryName: 'Germany',
        },
        city: {
          cityId: 'a3de30a1755b4fc081ed1daa0eab6ef9',
          cityName: 'Hamburg',
          cityCount: null,
        },
      },
      capabilityTags: [
        {
          tagId: '78cc17b1678f49d4b33c5195b8aa7c76',
          tagName: 'Executive Search',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=78cc17b1678f49d4b33c5195b8aa7c76',
        },
      ],
      industryTags: [
        {
          tagId: '397299e892be4513bdfa4b3fead404e5',
          tagName: 'Industrial',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?industryTags=397299e892be4513bdfa4b3fead404e5',
        },
      ],
      areaOfExpertiseTags: [
        {
          tagId: '2be2f08c95694399a8a627209bef3e6c',
          tagName: 'Human Resources',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?areaOfExpertiseTags=2be2f08c95694399a8a627209bef3e6c',
        },
        {
          tagId: 'c6bcee489a864f88a517d1e9c3d7b2a6',
          tagName: 'Operations and Supply Chain',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?areaOfExpertiseTags=c6bcee489a864f88a517d1e9c3d7b2a6',
        },
      ],
      businessIssueTags: [
        {
          tagId: '845553503aee472988fe2ce2188b088c',
          tagName: 'Culture Risk',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?businessIssueTags=845553503aee472988fe2ce2188b088c',
        },
      ],
      insightTopicTags: [],
      calculatedIndustryTags: [
        {
          tagId: '397299e892be4513bdfa4b3fead404e5',
          tagName: 'Industrial',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?calculatedIndustryTags=397299e892be4513bdfa4b3fead404e5',
        },
      ],
      calculatedSubIndustryDescriptions: [],
    },
    {
      fullName: 'Frederik Albrechtsen',
      url: '/en/people/consultant-directory/frederik-albrechtsen',
      imageUrl:
        'https://mss-p-053.stylelabs.cloud/api/public/content/63bdc197110347048c5cb7d8ecd9933e?v=c37a1d07',
      imageAlt: '',
      titleTag: {},
      location: {
        country: {
          countryId: '6a1485e66e474312abd2f50438e34db7',
          countryName: '',
        },
        city: {
          cityId: '5ea5ed894b24411485f825d1311e0bd7',
          cityName: 'Zurich',
          cityCount: null,
        },
      },
      capabilityTags: [
        {
          tagId: '78cc17b1678f49d4b33c5195b8aa7c76',
          tagName: 'Executive Search',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=78cc17b1678f49d4b33c5195b8aa7c76',
        },
      ],
      industryTags: [
        {
          tagId: '56ee3de883794470a5493a3385b0cd10',
          tagName: 'Healthcare',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?industryTags=56ee3de883794470a5493a3385b0cd10',
        },
      ],
      areaOfExpertiseTags: [],
      businessIssueTags: [],
      insightTopicTags: [],
      calculatedIndustryTags: [
        {
          tagId: '56ee3de883794470a5493a3385b0cd10',
          tagName: 'Healthcare',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?calculatedIndustryTags=56ee3de883794470a5493a3385b0cd10',
        },
      ],
      calculatedSubIndustryDescriptions: [],
    },
    {
      fullName: 'Constantine Alexandrakis',
      url: '/en/people/consultant-directory/constantine-alexandrakis',
      imageUrl:
        'https://mss-p-053.stylelabs.cloud/api/public/content/8060cb75fb0341c88f147e06ed90b8a6?v=fe5011af',
      imageAlt: '',
      titleTag: {
        tagId: '136b2c8ec1cf45b48cace4b0d9ce38cb',
        tagName: 'Managing Director - MD',
      },
      location: {
        country: {
          countryId: '8ad216671f3f49e3a83f3d4476ba256a',
          countryName: 'United States',
        },
        city: {
          cityId: '48abcfb5b34041468d4f14211f95a963',
          cityName: 'Chicago',
          cityCount: null,
        },
      },
      capabilityTags: [
        {
          tagId: 'a4e07688daef4167a8f242ff2af323cc',
          tagName: 'Board Effectiveness',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=a4e07688daef4167a8f242ff2af323cc',
        },
        {
          tagId: 'c2050c377cd54f29932d0afe8ade9ac7',
          tagName: 'Board Director and Chair Search',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=c2050c377cd54f29932d0afe8ade9ac7',
        },
        {
          tagId: '43122093b45b47a7809abbe7d3f7a5d2',
          tagName: 'CEO Succession',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=43122093b45b47a7809abbe7d3f7a5d2',
        },
        {
          tagId: '78cc17b1678f49d4b33c5195b8aa7c76',
          tagName: 'Executive Search',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=78cc17b1678f49d4b33c5195b8aa7c76',
        },
      ],
      industryTags: [
        {
          tagId: 'acfc56031f5b429e97a2764911c6ae6a',
          tagName: 'Private Capital',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?industryTags=acfc56031f5b429e97a2764911c6ae6a',
        },
      ],
      areaOfExpertiseTags: [
        {
          tagId: 'e3d8ff5af5e840d4bf1d45f60905428c',
          tagName: 'Board and CEO Advisory',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?areaOfExpertiseTags=e3d8ff5af5e840d4bf1d45f60905428c',
        },
      ],
      businessIssueTags: [
        {
          tagId: 'b21fd9c0022949909716380a0434ccf0',
          tagName: 'Board Composition and Succession',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?businessIssueTags=b21fd9c0022949909716380a0434ccf0',
        },
      ],
      insightTopicTags: [],
      calculatedIndustryTags: [
        {
          tagId: 'acfc56031f5b429e97a2764911c6ae6a',
          tagName: 'Private Capital',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?calculatedIndustryTags=acfc56031f5b429e97a2764911c6ae6a',
        },
      ],
      calculatedSubIndustryDescriptions: [],
    },
    {
      fullName: 'Pedro Ancarola',
      url: '/en/people/consultant-directory/pedro-ancarola',
      imageUrl:
        'https://mss-p-053.stylelabs.cloud/api/public/content/ebb7bac856a24af08ebb6bf4f1ca2c36?v=b6585644',
      imageAlt: '',
      titleTag: {},
      location: {
        country: {
          countryId: '3781ed48f5d048d1a10c4b70c4a0c93b',
          countryName: 'Argentina',
        },
        city: {
          cityId: 'd2e7ed6591d54d49864194a1c9881ab9',
          cityName: 'Buenos Aires',
          cityCount: null,
        },
      },
      capabilityTags: [
        {
          tagId: '78cc17b1678f49d4b33c5195b8aa7c76',
          tagName: 'Executive Search',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=78cc17b1678f49d4b33c5195b8aa7c76',
        },
      ],
      industryTags: [
        {
          tagId: '9c4e4dd66269480d8767d2b749d3c38b',
          tagName: 'Consumer',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?industryTags=9c4e4dd66269480d8767d2b749d3c38b',
        },
        {
          tagId: 'f5dffc5e9f62436ea00541933bb9aced',
          tagName: 'Financial Services',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?industryTags=f5dffc5e9f62436ea00541933bb9aced',
        },
        {
          tagId: '397299e892be4513bdfa4b3fead404e5',
          tagName: 'Industrial',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?industryTags=397299e892be4513bdfa4b3fead404e5',
        },
      ],
      areaOfExpertiseTags: [],
      businessIssueTags: [],
      insightTopicTags: [],
      calculatedIndustryTags: [
        {
          tagId: '9c4e4dd66269480d8767d2b749d3c38b',
          tagName: 'Consumer',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?calculatedIndustryTags=9c4e4dd66269480d8767d2b749d3c38b',
        },
        {
          tagId: 'f5dffc5e9f62436ea00541933bb9aced',
          tagName: 'Financial Services',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?calculatedIndustryTags=f5dffc5e9f62436ea00541933bb9aced',
        },
        {
          tagId: '397299e892be4513bdfa4b3fead404e5',
          tagName: 'Industrial',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?calculatedIndustryTags=397299e892be4513bdfa4b3fead404e5',
        },
      ],
      calculatedSubIndustryDescriptions: [],
    },
    {
      fullName: 'David Andersen',
      url: '/en/people/consultant-directory/david-andersen',
      imageUrl:
        'https://mss-p-053.stylelabs.cloud/api/public/content/6e7593a53d4a4f22a72ad72c63365ab0?v=df23dc16',
      imageAlt: '',
      titleTag: {},
      location: {
        country: {
          countryId: '8ad216671f3f49e3a83f3d4476ba256a',
          countryName: 'United States',
        },
        city: {
          cityId: 'b3ec45ec02054652a351ba3eedd820a1',
          cityName: 'Atlanta',
          cityCount: null,
        },
      },
      capabilityTags: [
        {
          tagId: '78cc17b1678f49d4b33c5195b8aa7c76',
          tagName: 'Executive Search',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=78cc17b1678f49d4b33c5195b8aa7c76',
        },
      ],
      industryTags: [
        {
          tagId: '573e6774881c43749315ae7f304175ae',
          tagName: 'Technology',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?industryTags=573e6774881c43749315ae7f304175ae',
        },
      ],
      areaOfExpertiseTags: [],
      businessIssueTags: [],
      insightTopicTags: [],
      calculatedIndustryTags: [
        {
          tagId: '573e6774881c43749315ae7f304175ae',
          tagName: 'Technology',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?calculatedIndustryTags=573e6774881c43749315ae7f304175ae',
        },
      ],
      calculatedSubIndustryDescriptions: [],
    },
    {
      fullName: 'Nicholas Anderson',
      url: '/en/people/consultant-directory/nicholas-anderson',
      imageUrl:
        'https://mss-p-053.stylelabs.cloud/api/public/content/1ccf9fd95a9842a884b9b5bbc7500624?v=2e01b5c6',
      imageAlt: '',
      titleTag: {},
      location: {
        country: {
          countryId: 'd0a1217a164f46539d565d71f0e40310',
          countryName: 'Hong Kong',
        },
        city: {
          cityId: '60e1cde7aa964f3085ab2bb8e91a2626',
          cityName: 'Hong Kong',
          cityCount: null,
        },
      },
      capabilityTags: [
        {
          tagId: '93a3df00f38d46df8338932617a24558',
          tagName: 'Development and Transition',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=93a3df00f38d46df8338932617a24558',
        },
        {
          tagId: 'a6126747b13f4af0b50e26a67a9f62d2',
          tagName: 'Assessment and Benchmarking',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=a6126747b13f4af0b50e26a67a9f62d2',
        },
        {
          tagId: '5efc117e7e7c4e6086e321e2aff9bfd3',
          tagName: 'C-Suite Succession',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=5efc117e7e7c4e6086e321e2aff9bfd3',
        },
      ],
      industryTags: [],
      areaOfExpertiseTags: [],
      businessIssueTags: [],
      insightTopicTags: [],
      calculatedIndustryTags: [],
      calculatedSubIndustryDescriptions: [],
    },
    {
      fullName: 'Keith Anderson',
      url: '/en/people/consultant-directory/keith-anderson',
      imageUrl:
        'https://mss-p-053.stylelabs.cloud/api/public/content/fff84dee05be42bb8890c9e5b432e2f5?v=4fe182fc',
      imageAlt: '',
      titleTag: {
        tagId: '136b2c8ec1cf45b48cace4b0d9ce38cb',
        tagName: 'Managing Director - MD',
      },
      location: {
        country: {
          countryId: '8ad216671f3f49e3a83f3d4476ba256a',
          countryName: 'United States',
        },
        city: {
          cityId: '042ba6f370834ea595225ed45c2a001c',
          cityName: 'San Francisco',
          cityCount: null,
        },
      },
      capabilityTags: [
        {
          tagId: 'a4e07688daef4167a8f242ff2af323cc',
          tagName: 'Board Effectiveness',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=a4e07688daef4167a8f242ff2af323cc',
        },
        {
          tagId: '78cc17b1678f49d4b33c5195b8aa7c76',
          tagName: 'Executive Search',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?capabilityTags=78cc17b1678f49d4b33c5195b8aa7c76',
        },
      ],
      industryTags: [
        {
          tagId: '397299e892be4513bdfa4b3fead404e5',
          tagName: 'Industrial',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?industryTags=397299e892be4513bdfa4b3fead404e5',
        },
      ],
      areaOfExpertiseTags: [
        {
          tagId: 'e3d8ff5af5e840d4bf1d45f60905428c',
          tagName: 'Board and CEO Advisory',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?areaOfExpertiseTags=e3d8ff5af5e840d4bf1d45f60905428c',
        },
        {
          tagId: '926735d8f4d848c6ae6d60ce8f917e40',
          tagName: 'Finance',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?areaOfExpertiseTags=926735d8f4d848c6ae6d60ce8f917e40',
        },
      ],
      businessIssueTags: [
        {
          tagId: '5e9903f0da4d44ffb5adde3a8b3fb157',
          tagName: 'Succession Planning',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?businessIssueTags=5e9903f0da4d44ffb5adde3a8b3fb157',
        },
      ],
      insightTopicTags: [],
      calculatedIndustryTags: [
        {
          tagId: '397299e892be4513bdfa4b3fead404e5',
          tagName: 'Industrial',
          tagUrl:
            'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/en/insights?calculatedIndustryTags=397299e892be4513bdfa4b3fead404e5',
        },
      ],
      calculatedSubIndustryDescriptions: [],
    },
  ],
  titleTags: {
    description: 'TITLE',
    facetValues: [
      {
        tagId: '57b869676de9421cb47fd5963e69fb69',
        tagName: 'Associate',
        tagCount: 18,
      },
      {
        tagId: '4d80d7db25fb415d9a90ab5d8a85bc5c',
        tagName: 'Executive Director - ED',
        tagCount: 8,
      },
      {
        tagId: '136b2c8ec1cf45b48cace4b0d9ce38cb',
        tagName: 'Managing Director - MD',
        tagCount: 188,
      },
    ],
  },
  locations: {
    description: 'LOCATION',
    facetLocations: [
      {
        country: {
          countryId: '8ad216671f3f49e3a83f3d4476ba256a',
          countryName: 'United States',
        },
        city: {
          cityId: 'f58884fc56dd4d01a713c7a2404755f2',
          cityName: 'New York',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '8ad216671f3f49e3a83f3d4476ba256a',
          countryName: 'United States',
        },
        city: {
          cityId: '042ba6f370834ea595225ed45c2a001c',
          cityName: 'San Francisco',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '8ad216671f3f49e3a83f3d4476ba256a',
          countryName: 'United States',
        },
        city: {
          cityId: '48abcfb5b34041468d4f14211f95a963',
          cityName: 'Chicago',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '8ad216671f3f49e3a83f3d4476ba256a',
          countryName: 'United States',
        },
        city: {
          cityId: 'b3ec45ec02054652a351ba3eedd820a1',
          cityName: 'Atlanta',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '8ad216671f3f49e3a83f3d4476ba256a',
          countryName: 'United States',
        },
        city: {
          cityId: 'ee29bb7181c24871a6bb9c7e2e24f5e5',
          cityName: 'Washington DC',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '8ad216671f3f49e3a83f3d4476ba256a',
          countryName: 'United States',
        },
        city: {
          cityId: 'ca4377418a294345bbd4047bb4f6a2d1',
          cityName: 'Boston',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '8ad216671f3f49e3a83f3d4476ba256a',
          countryName: 'United States',
        },
        city: {
          cityId: '3d96ed19d9f6429fa7fe87aa7c39813c',
          cityName: 'Dallas',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '8ad216671f3f49e3a83f3d4476ba256a',
          countryName: 'United States',
        },
        city: {
          cityId: '14136203089747f7a53de9587b1daae3',
          cityName: 'Houston',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '8ad216671f3f49e3a83f3d4476ba256a',
          countryName: 'United States',
        },
        city: {
          cityId: '724a41522a4347cf8290257c59260b4b',
          cityName: 'Miami',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '8ad216671f3f49e3a83f3d4476ba256a',
          countryName: 'United States',
        },
        city: {
          cityId: '80f678e41946408ba9d13737bf1ba34b',
          cityName: 'Los Angeles',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '8ad216671f3f49e3a83f3d4476ba256a',
          countryName: 'United States',
        },
        city: {
          cityId: '6251b274c4974273a4f743c9f09e8319',
          cityName: 'Palo Alto',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '8ad216671f3f49e3a83f3d4476ba256a',
          countryName: 'United States',
        },
        city: {
          cityId: '24033100026a4cc0a6c2926e46370afe',
          cityName: 'Minneapolis - St Paul',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '8ad216671f3f49e3a83f3d4476ba256a',
          countryName: 'United States',
        },
        city: {
          cityId: '4af89227cdb944ceb34aa1a5567a3978',
          cityName: 'Stamford',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: 'b86e5bf48dc84749b35b8c85ad44e282',
          countryName: 'England',
        },
        city: {
          cityId: 'df4272721d7a42fe91a8af659b27cce2',
          cityName: 'London',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: 'b41968dc605b46468a36e76b337979e8',
          countryName: 'Germany',
        },
        city: {
          cityId: '1d5cce636fe44f38a4d263ba3f3cb06c',
          cityName: 'Frankfurt',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: 'b41968dc605b46468a36e76b337979e8',
          countryName: 'Germany',
        },
        city: {
          cityId: '8d412f926ef04ecabb44eaaf93f24f73',
          cityName: 'Munich',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: 'b41968dc605b46468a36e76b337979e8',
          countryName: 'Germany',
        },
        city: {
          cityId: 'a3de30a1755b4fc081ed1daa0eab6ef9',
          cityName: 'Hamburg',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: 'b574bb43f16d4af9bb9f39c58a162535',
          countryName: 'France',
        },
        city: {
          cityId: '0ac3bbb3f70d4334bd4b05dea195410b',
          cityName: 'Paris',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: 'dd011c49cbf84298abdf2c4ba7336440',
          countryName: 'Australia',
        },
        city: {
          cityId: 'cfed370c75554a1aafce6629991beb96',
          cityName: 'Sydney',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: 'dd011c49cbf84298abdf2c4ba7336440',
          countryName: 'Australia',
        },
        city: {
          cityId: '9be830f398d9455fa45af908a75e5563',
          cityName: 'Melbourne',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '1549b9d3bc8e44bdaa8f61aea8c4392b',
          countryName: 'Canada',
        },
        city: {
          cityId: 'c8d22782308b43fbb46593b08ec18511',
          cityName: 'Toronto',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '1549b9d3bc8e44bdaa8f61aea8c4392b',
          countryName: 'Canada',
        },
        city: {
          cityId: 'f40e0f001d1c48fa840d9e107e8293f2',
          cityName: 'Montreal',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '1549b9d3bc8e44bdaa8f61aea8c4392b',
          countryName: 'Canada',
        },
        city: {
          cityId: 'fffc22430fef4e99bfc1ed39834abe6f',
          cityName: 'Calgary',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: 'a8f259dec35f4312a66dea3b919e8187',
          countryName: 'China',
        },
        city: {
          cityId: 'a531d708e9b2499bb44aa87e9c27d44d',
          cityName: 'Shanghai',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: 'a8f259dec35f4312a66dea3b919e8187',
          countryName: 'China',
        },
        city: {
          cityId: '84f63034b7184cfcb2e7dbf4429031f1',
          cityName: 'Beijing',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '09c0688797284b1f923a7cb8c26c557c',
          countryName: 'Netherlands',
        },
        city: {
          cityId: '20ce0f6f23274c21888f8482d398281f',
          cityName: 'Amsterdam',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '6a1485e66e474312abd2f50438e34db7',
          countryName: '',
        },
        city: {
          cityId: '5ea5ed894b24411485f825d1311e0bd7',
          cityName: 'Zurich',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: 'ecfd5dc9217f420e8b0c735219b71ac1',
          countryName: 'Singapore',
        },
        city: {
          cityId: '93665b60859b47588029aab78c109b50',
          cityName: 'Singapore',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: 'a6775dc8fb6e4105814a246af632fa57',
          countryName: 'India',
        },
        city: {
          cityId: 'c1e273706d614e18813ee83ad483832d',
          cityName: 'Mumbai',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: 'a6775dc8fb6e4105814a246af632fa57',
          countryName: 'India',
        },
        city: {
          cityId: 'c0830aa6c841403083820e39bcaa3522',
          cityName: 'New Delhi',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '545066542bc1470d8b260bca88b61729',
          countryName: 'Sweden',
        },
        city: {
          cityId: '41f5e2992eb44678b1abdefee478f2e6',
          cityName: 'Stockholm',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '5e871d44629d46deb267e5129a731958',
          countryName: 'Italy',
        },
        city: {
          cityId: '7e81dcc37ae34cf8962eed65664a870d',
          cityName: 'Milan',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: 'd0a1217a164f46539d565d71f0e40310',
          countryName: 'Hong Kong',
        },
        city: {
          cityId: '60e1cde7aa964f3085ab2bb8e91a2626',
          cityName: 'Hong Kong',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '2afe26223946479183d02e25fd3ddec7',
          countryName: 'Belgium',
        },
        city: {
          cityId: 'ef53cd9bde51445bb7fa4a502edc9db5',
          cityName: 'Brussels',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: 'deb7d1efafff4eaf9c031ccb1fafdadf',
          countryName: 'Brazil',
        },
        city: {
          cityId: '5605ac1524d244ada96f825e0058f342',
          cityName: 'São Paulo',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '055c42ff491e43238b2bb50419297c9a',
          countryName: 'Spain',
        },
        city: {
          cityId: '532f0578636e4206b851861df4bfa7fe',
          cityName: 'Madrid',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '055c42ff491e43238b2bb50419297c9a',
          countryName: 'Spain',
        },
        city: {
          cityId: '0d04f4e415b444fab9d56307d391cf84',
          cityName: 'Barcelona',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '1d465244dd35496d83737f9dd7c342b6',
          countryName: 'Denmark',
        },
        city: {
          cityId: 'ff1a0ef7cdda4519a6fc07d0143f46ba',
          cityName: 'Copenhagen',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '07ab33391f114c9394552776015e05f1',
          countryName: 'Japan',
        },
        city: {
          cityId: '939a735106b348399c0064e8903c9ac9',
          cityName: 'Tokyo',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '46596f68da9d41f084bb9ea13a15434b',
          countryName: 'United Arab Emirates',
        },
        city: {
          cityId: '25bab13ef2684d69be9dafa21953ffb5',
          cityName: 'Dubai',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: 'e2f60013f75649089dd141a63c87c89d',
          countryName: 'Norway',
        },
        city: {
          cityId: '9021368818e4441f8fa3b0d0f07b81e2',
          cityName: 'Oslo',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '274a8f7dbc1b42d6920b27383c0ad93b',
          countryName: 'Mexico',
        },
        city: {
          cityId: '3b67773c693c43dc867dc0f6df264ab8',
          cityName: 'Mexico City',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: 'fc36e38b77ac472498c23b23cf2b07c3',
          countryName: 'Poland',
        },
        city: {
          cityId: '6b2e400530c74a1793ac01dc39d8e659',
          cityName: 'Warsaw',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '28231f645e52465f8dfeb36b0a4c3094',
          countryName: 'Finland',
        },
        city: {
          cityId: '6f87ff22f48048cd833bccd96ef79f03',
          cityName: 'Helsinki',
          cityCount: null,
        },
      },
      {
        country: {
          countryId: '3781ed48f5d048d1a10c4b70c4a0c93b',
          countryName: 'Argentina',
        },
        city: {
          cityId: 'd2e7ed6591d54d49864194a1c9881ab9',
          cityName: 'Buenos Aires',
          cityCount: null,
        },
      },
    ],
  },
  labels: [
    {
      key: 'label.clear.all',
      value: 'Clear All',
    },
    {
      key: 'label.filters',
      value: 'Filters',
    },
    {
      key: 'label.clear.all.filters',
      value: 'Clear All Filters',
    },
    {
      key: 'label.close',
      value: 'Close',
    },
  ],
  capabilityTags: {
    description: 'CAPABILITY',
    facetValues: [
      {
        tagId: 'a6126747b13f4af0b50e26a67a9f62d2',
        tagName: 'Assessment and Benchmarking',
        tagCount: 75,
      },
      {
        tagId: 'c2050c377cd54f29932d0afe8ade9ac7',
        tagName: 'Board Director and Chair Search',
        tagCount: 89,
      },
      {
        tagId: 'a4e07688daef4167a8f242ff2af323cc',
        tagName: 'Board Effectiveness',
        tagCount: 105,
      },
      {
        tagId: '5efc117e7e7c4e6086e321e2aff9bfd3',
        tagName: 'C-Suite Succession',
        tagCount: 90,
      },
      {
        tagId: '43122093b45b47a7809abbe7d3f7a5d2',
        tagName: 'CEO Succession',
        tagCount: 123,
      },
      {
        tagId: '2529546d07b94f97aaa136c987c5f05a',
        tagName: 'Culture Analytics',
        tagCount: 9,
      },
      {
        tagId: '93a3df00f38d46df8338932617a24558',
        tagName: 'Development and Transition',
        tagCount: 79,
      },
      {
        tagId: 'a371fb9cadfe4f9c893d44e3a51a8ef6',
        tagName: 'Diversity, Equity, and Inclusion Advisory',
        tagCount: 42,
      },
      {
        tagId: '78cc17b1678f49d4b33c5195b8aa7c76',
        tagName: 'Executive Search',
        tagCount: 396,
      },
      {
        tagId: 'e74419f70d37440e80ba7043e244a504',
        tagName: 'Team Effectiveness',
        tagCount: 7,
      },
    ],
  },
  industryTags: {
    description: 'INDUSTRY',
    facetValues: [
      {
        tagId: '9c4e4dd66269480d8767d2b749d3c38b',
        tagName: 'Consumer',
        tagCount: 87,
      },
      {
        tagId: 'dada5901e1ba4dccaa2f84513e5f06e8',
        tagName: 'Education',
        tagCount: 10,
      },
      {
        tagId: 'f5dffc5e9f62436ea00541933bb9aced',
        tagName: 'Financial Services',
        tagCount: 109,
      },
      {
        tagId: 'a52569728e69450c8f5a8bb0dea0a4c1',
        tagName: 'FinTech',
        tagCount: 1,
      },
      {
        tagId: 'd7dee3b6ba854ec0ba2cb30ff6581023',
        tagName: 'FinTech',
        tagCount: 1,
      },
      {
        tagId: '56ee3de883794470a5493a3385b0cd10',
        tagName: 'Healthcare',
        tagCount: 68,
      },
      {
        tagId: '397299e892be4513bdfa4b3fead404e5',
        tagName: 'Industrial',
        tagCount: 113,
      },
      {
        tagId: 'acfc56031f5b429e97a2764911c6ae6a',
        tagName: 'Private Capital',
        tagCount: 80,
      },
      {
        tagId: '69bd31b3105e4e1894a6839935781497',
        tagName: 'Professional Services',
        tagCount: 15,
      },
      {
        tagId: '1011044816e441bcbc5bf091f516a373',
        tagName: 'Social Impact',
        tagCount: 27,
      },
      {
        tagId: '573e6774881c43749315ae7f304175ae',
        tagName: 'Technology',
        tagCount: 112,
      },
      {
        tagId: 'a92ce0d33c2940ba841e1831ee34a32e',
        tagName: 'Venture Capital and Growth',
        tagCount: 3,
      },
    ],
  },
  areaOfExpertiseTags: {
    description: 'FUNCTION',
    facetValues: [
      {
        tagId: 'e3d8ff5af5e840d4bf1d45f60905428c',
        tagName: 'Board and CEO Advisory',
        tagCount: 135,
      },
      {
        tagId: '1a75118a36794a74b328e7be663a0804',
        tagName: 'Corporate Affairs and Communication',
        tagCount: 22,
      },
      {
        tagId: '926735d8f4d848c6ae6d60ce8f917e40',
        tagName: 'Finance',
        tagCount: 88,
      },
      {
        tagId: '2be2f08c95694399a8a627209bef3e6c',
        tagName: 'Human Resources',
        tagCount: 35,
      },
      {
        tagId: '9fcc00d817e04ed693f2d2e6d664e242',
        tagName: 'Innovation, Research, and Development',
        tagCount: 1,
      },
      {
        tagId: 'e605f5d3213e4d248cf6601c79929444',
        tagName: 'Legal, Risk, and Compliance',
        tagCount: 20,
      },
      {
        tagId: 'a6e0ed9a34b942529a3e4a6963611220',
        tagName: 'Marketing, Sales, and Strategy',
        tagCount: 35,
      },
      {
        tagId: 'c6bcee489a864f88a517d1e9c3d7b2a6',
        tagName: 'Operations and Supply Chain',
        tagCount: 26,
      },
      {
        tagId: '3326cde14d184751bcf502629cf2c16e',
        tagName: 'Sustainability',
        tagCount: 13,
      },
      {
        tagId: 'efd50d93e55242789316c9cec87f543f',
        tagName: 'Technology, Data, and Digital',
        tagCount: 102,
      },
    ],
  },
  businessIssueTags: {
    description: 'BUSINESS',
    facetValues: [
      {
        tagId: '772535903c88476fbbd3e73e698bfb0e',
        tagName: 'Activism, Vulnerability, and Defense',
        tagCount: 50,
      },
      {
        tagId: 'b21fd9c0022949909716380a0434ccf0',
        tagName: 'Board Composition and Succession',
        tagCount: 72,
      },
      {
        tagId: '845553503aee472988fe2ce2188b088c',
        tagName: 'Culture Risk',
        tagCount: 7,
      },
      {
        tagId: '1e6401e689cc4bc993ae629ee06a5a79',
        tagName: 'Customer Focused Growth',
        tagCount: 61,
      },
      {
        tagId: '31b3746cd9a3478a9180a35d8c9f5d17',
        tagName: 'Diversity',
        tagCount: 4,
      },
      {
        tagId: '5e0340caa4e54136a331a40262a394e4',
        tagName: 'Environmental, Social, and Governance',
        tagCount: 20,
      },
      {
        tagId: '90d59b6b0b4c49fdbb2de73b3f90982b',
        tagName: 'Family Business Governance',
        tagCount: 15,
      },
      {
        tagId: '6f31c22a521c46d5b0be7f9a9956d5cb',
        tagName: 'Leadership',
        tagCount: 27,
      },
      {
        tagId: '1280ef732158472a8990b391eff0531b',
        tagName: 'Mergers, Acquisitions, and Integrations',
        tagCount: 5,
      },
      {
        tagId: '813485c63b1e483d8a75220073426fd8',
        tagName: 'Portfolio Company Leadership',
        tagCount: 33,
      },
      {
        tagId: 'e1e06c08388d4ec09778f291612a2900',
        tagName: 'Shareholder Stakeholder Engagement',
        tagCount: 58,
      },
      {
        tagId: '5e9903f0da4d44ffb5adde3a8b3fb157',
        tagName: 'Succession Planning',
        tagCount: 94,
      },
      {
        tagId: 'a6713612cd654871830c94fdf2fb907f',
        tagName: 'Transformation  Innovation',
        tagCount: 51,
      },
    ],
  },
  insightTopicTags: {
    description: 'TOPIC',
    facetValues: [
      {
        tagId: 'a433be52cf2d47869421d04411bb7161',
        tagName: 'Technology and Innovation',
        tagCount: 2,
      },
    ],
  },
  calculatedIndustryTags: {
    description: 'INDUSTRY',
    facetValues: [
      {
        tagId: '9c4e4dd66269480d8767d2b749d3c38b',
        tagName: 'Consumer',
        tagCount: 87,
      },
      {
        tagId: 'dada5901e1ba4dccaa2f84513e5f06e8',
        tagName: 'Education',
        tagCount: 10,
      },
      {
        tagId: 'f5dffc5e9f62436ea00541933bb9aced',
        tagName: 'Financial Services',
        tagCount: 109,
        children: [
          {
            tagId: 'a52569728e69450c8f5a8bb0dea0a4c1',
            tagName: 'FinTech',
            tagCount: 1,
          },
        ],
      },
      {
        tagId: '56ee3de883794470a5493a3385b0cd10',
        tagName: 'Healthcare',
        tagCount: 68,
      },
      {
        tagId: '397299e892be4513bdfa4b3fead404e5',
        tagName: 'Industrial',
        tagCount: 113,
      },
      {
        tagId: 'acfc56031f5b429e97a2764911c6ae6a',
        tagName: 'Private Capital',
        tagCount: 80,
      },
      {
        tagId: '69bd31b3105e4e1894a6839935781497',
        tagName: 'Professional Services',
        tagCount: 15,
      },
      {
        tagId: '1011044816e441bcbc5bf091f516a373',
        tagName: 'Social Impact',
        tagCount: 27,
      },
      {
        tagId: '573e6774881c43749315ae7f304175ae',
        tagName: 'Technology',
        tagCount: 112,
        children: [
          {
            tagId: 'd7dee3b6ba854ec0ba2cb30ff6581023',
            tagName: 'FinTech',
            tagCount: 1,
          },
        ],
      },
      {
        tagId: 'a92ce0d33c2940ba841e1831ee34a32e',
        tagName: 'Venture Capital and Growth',
        tagCount: 3,
      },
    ],
  },
  calculatedSubIndustryDescriptions: {
    description: 'SUB INDUSTRY',
    facetValues: [
      {
        tagId: 'FinTech',
        tagName: 'FinTech',
        tagCount: 2,
      },
    ],
  },
};
