import * as React from 'react';
import { initializeAnimation } from '../global-animation';
import { Pager } from './Pager';
import {
  Filter,
  getPreselectedTags,
  getPreselectedTagsFromFacets,
  removeFilter,
  selectTags,
  toggleMultipleFilter,
  toggleSingleFilter,
} from './Filter';

export const LatestInsights = ({
  variant,
  datasourceId,
  language = "en",
  contextPageId,
  onGetData,
  onBuildFilters,
  onBuildTags,
  onDecorate,
  defaultFilters = {},
  onRenderInsight,
}) => {
  const location = window.location;
  const defaultSelectedTags = [];

  let url = new URL(location.href);
  let params = new URLSearchParams(url.search);

  const defaultPage = params.has('page') ? Number(params.get('page')) : 1;
  const firstPage = 1;
  const [page, setPage] = React.useState(defaultPage);
  const [loaded, setLoaded] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [selectedTags, setSelectedTags] = React.useState(defaultSelectedTags);
  const [data, setData] = React.useState(null);
  const [filters, setFilters] = React.useState(defaultFilters);
  const containerRef = React.useRef(null);
  const headerContainerRef = React.useRef(null);
  const resultsContainerRef = React.useRef(null);
  const [firstLoad, setFirstLoad] = React.useState(true);

  async function loadData() {
    //check for URL Params 
    try {
      const hasBeenLoaded = loaded;
      setLoaded(false);
      const response = await onGetData(page, filters, contextPageId, datasourceId, language);
      setData(response);
      setLoaded(true);
      setError(null);

      if(firstLoad){
        const preSelectedTags = getPreselectedTagsFromFacets(
          response,
          filters,
          selectedTags
        );
        
        if (preSelectedTags.length) setSelectedTags(preSelectedTags);        
        setFirstLoad(false);
      }
      
      if (!hasBeenLoaded && response && !firstLoad) {
        const initialSelectedTags = getPreselectedTags(
          response,
          filters,
          selectedTags
        );
        if (initialSelectedTags.length) setSelectedTags(initialSelectedTags);
      }

      if (hasBeenLoaded && resultsContainerRef){
        resultsContainerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (e) {
      setError(e);
    }    
  }

  const handleFilterChange = (filters) => {    
    setFilters(filters);
    setPage(firstPage);
    
    let url = new URL(location.href);
    let params = new URLSearchParams(url.search);
    
    Object.entries(filters).forEach(([key, value]) => {
      params.set(`${key}`, `${value}`);
    })
    
    window.history.pushState(null, 'filter', location.pathname + '?' + decodeURIComponent(params.toString()));
  };

  const handleDropdownFilterChange = (type, tag) => {
    setFilters(toggleMultipleFilter(filters, type, tag.tagId));
    setSelectedTags(selectTags(selectedTags, tag));
    setPage(firstPage);
    setUrl(type,tag,firstPage);
  };

  function handleRemoveDropdownFilter(tag) {
    setFilters(removeFilter(filters, tag));
    setSelectedTags(selectTags(selectedTags, tag));
    setPage(firstPage);
    removeUrlParam(tag);
  }

  function setUrl(type,tag,currentPage){
    let tagIds = [];

    let url = new URL(location.href);
    let params = new URLSearchParams(url.search);

    if(params.has(type) && params.get(type).length > 0){
      tagIds = params.get(type).split(',');
    }

    if(!tagIds.includes(tag.tagId)){
      tagIds.push(tag.tagId);
    } else {
      tagIds.splice(tagIds.indexOf(tag.tagId), 1);
    }

    params.set(type, tagIds.toString());
    params.set('page', currentPage);

    window.history.pushState(null, 'filter', location.pathname + '?' + decodeURIComponent(params.toString()));
  }

  function removeUrlParam(tag){
    let url = new URL(location.href);
    let params = new URLSearchParams(url.search);

    params.forEach((key, value) => {
      let tagIds = [];
      tagIds = params.get(value).split(',');      

      if(tagIds.includes(tag.tagId)){
        tagIds.splice(tagIds.indexOf(tag.tagId), 1);
      }

      if(tagIds.length > 0){
        params.set(value, tagIds.toString());
      } else {
        params.delete(value);
      }
    })

    params.set('page', firstPage);

    window.history.pushState(null, 'filter', location.pathname + '?' + decodeURIComponent(params.toString()));
  }

  const handleClearFilters = () => {
    try{
      for (const key in defaultFilters){
        delete defaultFilters[key];
      }
      defaultSelectedTags.length = 0;
      setFilters(defaultFilters);
      setSelectedTags(defaultSelectedTags);
      setPage(firstPage);

      console.log(defaultFilters, defaultSelectedTags, defaultPage);
      
      window.history.pushState(null, 'clear filter', location.pathname);
    } catch(e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    loadData();
  }, [filters, page]);

  const isValidData = !!data;

  React.useLayoutEffect(() => {
    if (!loaded || !isValidData) return;
    setTimeout(() => {
      initializeAnimation(headerContainerRef.current);
      initializeAnimation(resultsContainerRef.current);
      onDecorate();
    }, 250);
  }, [loaded, filters]);

  const filtersLabel =
    data && data.labels && Array.isArray(data.labels)
      ? data.labels.find((o) => o.key === 'label.filters')?.value || 'Filters'
      : 'Filters';
  const clearAllLabel =
    data && data.labels && Array.isArray(data.labels)
      ? data.labels.find((o) => o.key === 'label.clear.all')?.value ||
        'clear all'
      : 'clear all';
  const clearAllFiltersLabel =
    data && data.labels && Array.isArray(data.labels)
      ? data.labels.find((o) => o.key === 'label.clear.all.filters')?.value ||
        'clear all filters'
      : 'clear all filters';
  const closeLabel =
    data && data.labels && Array.isArray(data.labels)
      ? data.labels.find((o) => o.key === 'label.close')?.value || 'close'
      : 'close';

  return (
    <>
      {isValidData && (
        <>
          <div
            className={`latest-insights-${variant}-container`}
            ref={containerRef}
          >
            <Filter
              variant={variant}
              dataFilters={filters}
              filters={onBuildFilters ? onBuildFilters(data) : []}
              selectedFilters={filters}
              selectedTags={selectedTags}
              containerRef={headerContainerRef}
              onClearFilters={handleClearFilters}
              onFilterChange={handleFilterChange}
              onToggleDropdownFilter={handleDropdownFilterChange}
              onRemoveDropdownFilter={handleRemoveDropdownFilter}
              filtersLabel={filtersLabel}
              clearAllLabel={clearAllLabel}
              clearAllFiltersLabel={clearAllFiltersLabel}
              closeLabel={closeLabel}
            />
            <div className="layout-container">
              <div className={`latest-insights-${variant}`}>
                <div
                  className={`latest-insights-${variant}__insights`}
                  data-animation="false"
                  ref={resultsContainerRef}
                >
                  {(data.insights || []).map((insight, index) => (
                    <React.Fragment key={index}>
                      {onRenderInsight({
                        ...insight,
                        tags: onBuildTags(insight),
                        labels: data.labels,
                      })}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <Pager
            page={page}
            totalResults={data.totalNumberOfResults}
            pageSize={data.pageSize}
            onChange={(pageNumber) => setPage(pageNumber)}
            updateUrl={true}
          />
        </>
      )}
    </>
  );
};
