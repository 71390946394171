import Cookies from 'js-cookie';
import { initializeAnimations } from './global-animation';

const cookieName = 'rra-seen-splash'; // the cookie name
const dataAttr = 'ignoreCookie'; // data attribute is name to ignore the cookie

export function initializeSplash() {
  const reduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)');
  const splash = document.querySelector('.splash');
  if (reduceMotion.matches || !splash) {
    initializeAnimations();
    return;
  }
  const video = splash.querySelector('video');

  // Gets the cookie is value
  let value = Cookies.get(cookieName);

  // Check if theres a data attr to ignore the cookie, fail safe measure
  const shouldIgnoreCookie = splash.dataset[dataAttr] === 'true';

  // If there's not the cookie, or, the fail safe is 'true' (string), show the splash
  if (!value || shouldIgnoreCookie) {
    splash.style.display = 'block';
    // this works around an iOS bug with 'ended', which doesn't always
    // fire when a video ends; but pause does
    video.addEventListener('pause', () => initializeAnimations());
  } else {
    initializeAnimations();
  }

  // always update the cookie
  // value as string to avoid problems with variables type which does not exist in cookie land
  Cookies.set(cookieName, '1', { secure: true, expires: 999999, path: '' });
}

export function resetSplash() {
  Cookies.remove(cookieName);
}
