import Glide from '@glidejs/glide';

export function initializeCaseStudies() {
  const caseStudiesTitle = document.querySelector('.case-studies--title');
  const caseStudiesCarousel = document.querySelector('.case-studies--carousel');
  if (!caseStudiesCarousel) return;
  const slidesList = caseStudiesCarousel.querySelectorAll('.glide__slides li');
  if (slidesList.length === 0) return;

  let lastIndex = 1;

  /**
   * Instantiate the events carousel.
   */
  const glide = new Glide(caseStudiesCarousel, {
    type: 'carousel',
    perView: 1,
    gap: 72,
    // autoplay: 6000,
  });

  glide.on('build.after', (e) => {
    console.log('built');
    const firstSlide = caseStudiesCarousel.querySelector('.glide__slide--active [data-animation="false"]');
    firstSlide.dataset.animation = true;
  })

  // glide.on('run.after', (e) => {
  //   caseStudiesTitle.style.opacity = '0';
  // });

  glide.on('move.after', (e) => {
    const slides = Array.from(
      caseStudiesCarousel.querySelectorAll('.glide__slides li')
    );
    if (!slides.length) return;
    const current = slides.splice(glide.index + 1, 1)[0];
    // reset animations on all non-current slides
    slides.forEach((slide) => {
      if(slide !== current){
        Array.from(slide.querySelectorAll('[data-animation=true]')).forEach(
          (el) => {
            // eslint-disable-next-line no-param-reassign
            el.dataset.animation = false;
          }
        );
      }
    });
    // trigger animation on current slide
    Array.from(current.querySelectorAll('[data-animation=false]')).forEach(
      (el) => {
        // eslint-disable-next-line no-param-reassign
        el.dataset.animation = true;
      }
    );
  });

  glide.on('run.before', (e) => {
    lastIndex = glide.index + 1;
  });
  
  glide.on('run.after', (e) => {
    let nthChild = glide.index + 1;

    const elSiblingSelector = `.case-study--images-container.desktop .case-study--image.case-study--image-desktop:nth-child(${lastIndex})`;
    const elSibling = caseStudiesCarousel.querySelector(elSiblingSelector);
    elSibling.dataset.active = 'false';

    const el = caseStudiesCarousel.querySelector(
      `.case-study--images-container.desktop .case-study--image.case-study--image-desktop:nth-child(${nthChild})`
    );

    el.dataset.active = 'true';
  });

  glide.mount();
}
