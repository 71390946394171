import gsap from 'gsap';

export function initializeSearchFilter(
  parentSelector = '.search-filter',
  demo = false
) {
  console.log('parentSelector', parentSelector);
  let parentSelectorWrapper = parentSelector;
  if (parentSelector.indexOf('.latest-insights') > -1) {
    parentSelectorWrapper = `${parentSelector}-container`;
  }

  const component = document.querySelector(parentSelectorWrapper);
  if (!component) return;

  let current = -1; // filter__nav-item
  let filterItemIndex = -1; // search-filter__item
  let menuState = 'CLOSED'; // 'OPENED' 'CLOSED'
  // animate desktop filter dropdowns
  const filterItems = document.querySelectorAll('.filter__dropdown-nav-item');
  const filterDropdowns = document.querySelectorAll(
    `${parentSelector}__filters-dropdown`
  );
  const filterDropdownContents = document.querySelectorAll(
    `${parentSelector}__content`
  );
  let currentFilterItems = filterDropdowns[0]?.querySelectorAll(
    `${parentSelector}__item`
  );

  const addStyles = (items, item) => {
    const currentFilterNavItemDiv = filterItems[current].querySelector('div');
    if (item) {
      currentFilterNavItemDiv.setAttribute('aria-activedescendant', item.id);
      items.classList.add('hover-selected');
      item.classList.add('item-selected');
    } else {
      if (!filterDropdowns[current]) return;
      const visuallyHidden =
        filterDropdowns[current].querySelector('.visualy-hidden');
      if (!visuallyHidden) return;
      currentFilterNavItemDiv.setAttribute(
        'aria-activedescendant',
        visuallyHidden.id
      );
    }
  };

  const removeStyles = (element, item) => {
    if (!filterItems[current]) return;
    const currentFilterNavItemDiv = filterItems[current].querySelector('div');
    currentFilterNavItemDiv.setAttribute('aria-activedescendant', 'e0');
    if (element) {
      element.classList.remove('hover-selected');
    }
    if (item) {
      item.blur();
      item.classList.remove('item-selected');
    }
  };

  const indexIsValid = (index) => {
    if (index >= 0 && index < filterItems.length) return true;
    return false;
  };

  const closeElement = (index) => {
    if (indexIsValid(index)) {
      filterItems[index].classList.remove('hover');
      filterDropdowns[index].classList.remove('hover');
      filterDropdownContents[index].classList.remove('show');
      removeStyles(
        filterDropdowns[index].querySelector(`${parentSelector}__items`),
        currentFilterItems[filterItemIndex] || currentFilterItems[0]
      );
      current = -1;
      filterItemIndex = -1;
      menuState = 'CLOSED';
    }
  };

  const closeElements = (index) => {
    filterItems.forEach((item) => {
      item.classList.remove('hover');
    });
    filterDropdowns.forEach((item) => {
      item.classList.remove('hover');
    });
    filterDropdownContents.forEach((item) => {
      item.classList.remove('show');
    });

    let openMobileNav = document.querySelectorAll('.sticky-bar__mobile-nav__header > .open');
    openMobileNav.forEach(el => {
      el.classList.remove('open');
    });

    current = -1;
    filterItemIndex = -1;
    menuState = 'CLOSED';
  };

  const openElement = (index) => {
    if (!indexIsValid(index)) return;
    closeElement(current);
    menuState = 'OPENED';
    current = index;
    filterItems[index].classList.add('hover');
    filterDropdowns[index].classList.add('hover'); // find the right element #
    filterDropdownContents[index].classList.add('show');
    currentFilterItems = filterDropdowns[index].querySelectorAll(
      `${parentSelector}__item`
    );
  };

  const isMenuOpened = () => {
    return menuState === 'OPENED';
  };

  const nextIndex = (currentIndex, groupLength) => {
    if (currentIndex + 1 < groupLength) return currentIndex + 1;
    return -1;
  };

  const previousIndex = (currentIndex, groupLength) => {
    if (currentIndex - 1 < -1) return groupLength - 1;
    return currentIndex - 1;
  };

  const homeIndex = (groupLength) => {
    return 0;
  };

  const endIndex = (groupLength) => {
    return groupLength - 1;
  };

  const handleKeyboardShortcuts = () => {
    const filterItemAnchors = document.querySelectorAll(
      `${parentSelector}__filters .filter__dropdown-nav-item div`
    );

    filterItemAnchors.forEach((anchor, index) => {
      anchor.addEventListener('blur', () => {
        closeElement(index);
      });

      anchor.addEventListener('focus', () => {
        openElement(index);
      });

      anchor.addEventListener('keydown', (event) => {
        const keyName = event.key;

        if (
          keyName === 'ArrowRight' ||
          keyName === 'ArrowLeft' ||
          keyName === 'ArrowUp' ||
          keyName === 'ArrowDown' ||
          keyName === 'Home' ||
          keyName === 'End'
        ) {
          if (isMenuOpened()) {
            // find group of search filter items
            currentFilterItems = filterDropdowns[current].querySelectorAll(
              `${parentSelector}__item`
            );

            if (
              filterItemIndex > -1 &&
              filterItemIndex < currentFilterItems.length
            ) {
              removeStyles(
                filterDropdowns[current].querySelector(
                  `${parentSelector}__items`
                ),
                currentFilterItems[filterItemIndex]
              );
            }

            if (keyName === 'ArrowRight' || keyName === 'ArrowDown') {
              event.preventDefault();
              filterItemIndex = nextIndex(
                filterItemIndex,
                currentFilterItems.length
              );
            } else if (keyName === 'ArrowLeft' || keyName === 'ArrowUp') {
              event.preventDefault();
              filterItemIndex = previousIndex(
                filterItemIndex,
                currentFilterItems.length
              );
            } else if (keyName === 'Home') {
              filterItemIndex = homeIndex();
            } else if (keyName === 'End') {
              filterItemIndex = endIndex(currentFilterItems.length);
            }

            addStyles(
              filterDropdowns[current].querySelector(
                `${parentSelector}__items`
              ),
              currentFilterItems[filterItemIndex]
            );
          }
        }

        if (keyName === 'Enter') {
          if (isMenuOpened() && filterItemIndex >= 0) {
            currentFilterItems[filterItemIndex].click();
          }
        }
      });
    });
  };

  const init = () => {
    // animate mobile filter flyout menu
    const mobileNavOpen = document.querySelector(
      `${parentSelector}__mobile-nav-link`
    );

    //console.log('mobileNavOpen',mobileNavOpen);

    const mobileNavClose = document.querySelector(
      `${parentSelector}__mobile-nav-close`
    );

    //console.log('mobileNavClose',mobileNavClose);

    const mobileNav = document.querySelector(`${parentSelector}__mobile-nav`);

    //console.log('mobileNav',mobileNav);

    const mobileNavItemsParent = document.querySelector(
      `${parentSelector}__mobile-nav__items`
    );

    const mobileNavItems = mobileNavItemsParent ? mobileNavItemsParent.children : null;

    let tl = gsap.timeline({ paused: true });

    tl.to(mobileNav, {
      duration: 0.35,
      opacity: 1,
      width: '100%',
      ease: 'expo.inOut',
    });

    tl.to(mobileNavClose, {
      duration: 0.35,
      opacity: 1,
      visibility: 'visible',
      ease: 'expo.inOut',
    });

    if(mobileNavItems){
      tl.from(mobileNavItems, {
        duration: 0.35,
        opacity: 0,
        y: '2rem',
        stagger: 0.2,
        ease: 'expo.inOut',
      });
    }

    if (!mobileNavOpen.dataset.decorated) {
      mobileNavOpen.addEventListener('click', (e) => {
        e.preventDefault();
        tl.play();
      });
      mobileNavOpen.dataset.decorated = true;
    }

    if (!mobileNavClose.dataset.decorated) {
      mobileNavClose.addEventListener('click', (e) => {
        e.preventDefault();
        tl.reverse();
      });
      mobileNavClose.dataset.decorated = true;
    }

    if (demo) {
      // toggle mobile filter items
      const mobileFilterItems = document.querySelectorAll(
        `${parentSelector}__mobile-nav__item`
      );

      mobileFilterItems.forEach((item) => {
        if (item.dataset.decorated) return;
        item.addEventListener('click', (e) => {
          item.classList.toggle('active');
        });
        item.dataset.decorated = true;
      });
    }

    filterItems.forEach((item, index) => {
      item.addEventListener('mouseover', () => {
        const idx = Array.from(item.parentElement.children).indexOf(item);
        if (idx > -1) openElement(idx);
        closeElements();
        openElement(index);
      });
      item.addEventListener('click', () => {
        if (item.classList.contains('hover')) {
          closeElements();
        } else {
          const idx = Array.from(item.parentElement.children).indexOf(item);
          if (idx > -1) openElement(idx);
        }
      });
    });

    const eventsFilter = document.querySelector(`${parentSelector}__filters`);

    console.log(eventsFilter);

    eventsFilter.addEventListener('mouseleave', () => {
      closeElements();
    });
    // toggle filter items
    filterDropdowns.forEach((filterDropdown) => {
      filterDropdown
        .querySelectorAll(`${parentSelector}__item`)
        .forEach((filterItem, index) => {
          if (demo) {
            filterItem.addEventListener('click', (e) => {
              filterItem.classList.toggle(`${parentSelector}__item--selected`);
            });
          }

          filterItem.addEventListener('mouseenter', (e) => {
            e.stopPropagation();
            filterItemIndex = index;
          });
        });
    });
  };

  init();
  handleKeyboardShortcuts();
}
