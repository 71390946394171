import * as React from 'react';
import { LatestInsights } from './LatestInsights';
import { getLatestInsightsInsightData } from '../api/request';
import { latestInsightsInsightsData } from '../api/mockdata';
import { decorateLatestInsightsInsights } from '../latest-insights-insights';
import { getImagePath } from '../util';
import { Insight } from './Insights';

export const LatestInsightsInsights = ({ datasourceId, contextPageId, defaultFilters, language="en" }) => {
  return (
    <LatestInsights
      variant="insights"
      datasourceId={datasourceId}
      language={language}
      contextPageId={contextPageId}
      defaultFilters={defaultFilters}
      onGetData={getLatestInsightsInsightData}
      // onGetData={async () => latestInsightsInsightsData}
      onRenderInsight={(data) => <Insight {...data} />}
      onDecorate={() => decorateLatestInsightsInsights()}
      onBuildFilters={(data) => [
        {
          control: 'dropdown',
          type: 'topic',
          name: data.insightTopicTags.description,
          filterType: 'insightTopicTags',
          filterItems: data.insightTopicTags.facetValues,
        },
        {
          control: 'dropdown',
          type: 'calculatedIndustryTags',
          name: data.calculatedIndustryTags.description,
          filterType: 'calculatedIndustryTags',
          filterItems: data.calculatedIndustryTags.facetValues,
        },
        {
          control: 'dropdown',
          type: 'function',
          name: data.areaOfExpertiseTags.description,
          filterType: 'areaOfExpertiseTags',
          filterItems: data.areaOfExpertiseTags.facetValues,
        },
        {
          control: 'dropdown',
          type: 'type',
          name: data.assetTypeTags.description,
          filterType: 'assetTypeTags',
          filterItems: data.assetTypeTags.facetValues,
        },
      ]}
      onBuildTags={(insight) => [
        ...(insight.insightTopicTags || []),
        ...(insight.businessIssueTags || []),
        ...(insight.calculatedIndustryTags || []),
        ...(insight.calculatedSubIndustryDescriptions || []),
        ...(insight.areaOfExpertiseTags || []),
        ...(insight.capabilityTags || []),
      ]}
    />
  );
};
