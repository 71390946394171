import * as React from 'react';
import { render } from 'react-dom';
import { getDefaultFilters, knownInsightTagTypes } from './components/Filter';
import { LatestInsightsEvents } from './components/LatestInsightsEvents';
import { decorateInsightHover } from './latest-insights-common';
import { initializeSearchFilter } from './search-filter';

export function decorateLatestInsightsEvents(demo = false) {
  const latestInsightsEvents = document.querySelector(
    '.latest-insights-events-container'
  );
  if (!latestInsightsEvents) return;

  initializeSearchFilter('.latest-insights-events', demo);

  if (demo) {
    decorateInsightHover(latestInsightsEvents);
  }
}

export function initializeLatestInsightsEvents() {
  const root = document.getElementById('latest-insights-events-root');
  if (typeof root?.dataset?.datasourceId === 'undefined') return;
  if (root)
    render(
      <LatestInsightsEvents
        datasourceId={root.dataset.datasourceId}
        contextPageId={root.dataset.pagecontext}
        defaultFilters={getDefaultFilters(knownInsightTagTypes)}
        language={root.dataset.language}
      />,
      root
    );
}
