export function initializeConcierge() {
  const concierge = document.querySelector('.concierge--wrapper');

  if (!concierge) {
    return;
  }
  const conciergeTrigger = document.querySelector('.concierge--trigger');

  /**
   * Toggle the concierge modal.
   */
  const toggleConciergeModal = () => {
    const conciergeClose = concierge.querySelector(
      '.concierge--close > button'
    );
    conciergeTrigger.addEventListener('click', () => {
      if (!concierge.classList.contains('is-open')) {
        concierge.classList.add('is-open');
        let scrollY = window.scrollY;
        document.body.classList.add('no-scroll');
        document.body.style.top = `-${scrollY}px`;
      }
    });

    // @TODO – abstract the close functionality into a separate function
    // and trigger it with either a close button click OR escape keypress.
    conciergeClose.addEventListener('click', () => {
      if (concierge.classList.contains('is-open')) {
        concierge.classList.remove('is-open');
        const scrollY = document.body.style.top;
        document.body.classList.remove('no-scroll');
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      }
    });
  };

  /**
   * Toggle the concierge input between text + voice.
   * @TODO – add actual voice capture functionality.
   * Maybe leveraging https://developer.mozilla.org/en-US/docs/Web/API/Web_Speech_API/Using_the_Web_Speech_API.
   */
  const toggleConciergeInput = () => {
    const inputToggle = concierge.querySelector('.concierge--input-toggle');
    const buttons = inputToggle.querySelectorAll('button');

    buttons.forEach((button) => {
      button.addEventListener('click', (e) => {
        const activeButton = inputToggle.querySelector('.active');

        // When we click on a button, change the active state
        // as long as we're not clicking on the current active button.
        if (e.target.classList.contains('active')) return;
        e.target.classList.add('active');
        activeButton.classList.remove('active');
      });
    });
  };

  toggleConciergeInput();
  toggleConciergeModal();
}
