const tabParent = document.querySelectorAll('.tabs');

function init(){
	tabParent.forEach((parent) => {
		const tabContainer = parent.querySelector('.tabs-list');
		const tabs = tabContainer.querySelectorAll('.tabs-list__link');
		const tabContent = parent.querySelectorAll('.tabs-content');

		tabs.forEach((el) => {
			el.addEventListener('click', (e) => {
				e.preventDefault();
				document.querySelector('.tabs-list__link.active').classList.remove('active');
				el.classList.add('active');
				let targetID = el.getAttribute('data-content');
				tabContent.forEach((target) => {
					if(target.getAttribute('data-tab') === targetID){
						target.classList.add('active');
						target.dataset.animation = true;
					} else {
						target.classList.remove('active');
						target.dataset.animation = false;
					}
				})
			})
		})

		tabContainer.dataset.animation = true;
	});
}

export function initializeTabs(){
	init();
}