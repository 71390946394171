import * as React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { decorateInsights } from '../insights';
import { initializeAnimation } from '../global-animation';
import * as api from '../api/request';
import { isFilterSelected, toggleMultipleFilter } from './Filter';
import { placeholder1xImagePath } from '../util';
import { insightsShowcaseData } from '../api/mockdata';

const ResultItem = ({ insight }) => {
  if (insight.assetTypeTag && insight.assetTypeTag.tagName === 'Event') {
    return <EventResultItem {...insight} />;
  }

  return <ArticleResultItem {...insight} />;
};

const ArticleResultItem = ({
  featured,
  url,
  imageUrl,
  imageAlt,
  title,
  body,
  businessIssueTags,
  calculatedIndustryTags,
  calculatedSubIndustryDescriptions,
  areaOfExpertiseTags,
  capabilityTags,
  noOverlay = false,
}) => {
  return (
    <li
      className={classNames({
        insight: true,
        'insight--featured': !!featured,
        glide__slide: true,
      })}
    >
      <div
        className={classNames({
          'insight--image': true,
          'no-overlay': !!noOverlay,
        })}
      >
        <a href={url}>
          <img src={imageUrl || placeholder1xImagePath} alt={imageAlt} />
        </a>
      </div>
      <div className="insight--txt">
        <div className="insight--tags">
          {[
            ...businessIssueTags,
            ...calculatedIndustryTags,
            ...calculatedSubIndustryDescriptions,
            ...areaOfExpertiseTags,
            ...capabilityTags,
          ].map((tag, index) => (
            <a href={tag.tagUrl || '#'} key={index}>
              {tag.tagName}
            </a>
          ))}
        </div>
        <h3 className="heading2">
          <a href={url}>{title}</a>
        </h3>
        <p>{body}</p>
        <a href={url} className="link--button">
          Continue Reading
        </a>
      </div>
    </li>
  );
};

const EventResultItem = ({
  body,
  displayDate,
  featured,
  imageUrl,
  imageAlt,
  noOverlay = false,
  title,
  url,
}) => {
  return (
    <li
      className={classNames({
        insight: true,
        'insight--featured': !!featured,
        glide__slide: true,
      })}
    >
      <div
        className={classNames({
          'insight--image': true,
          'no-overlay': !!noOverlay,
        })}
      >
        <a href={url}>
          <img src={imageUrl || placeholder1xImagePath} alt={imageAlt} />
        </a>
      </div>
      <div className="insight--txt insight--txt-event">
        <div class="date">
          <div class="day">{moment(displayDate).format('DD')}</div>
          <div class="month-year">
            {moment(displayDate).format('MMMM')}
            <br />
            {moment(displayDate).format('YYYY')}
          </div>
        </div>
        <h2>
          <a href={url}>{title}</a>
        </h2>
        <p>{body}</p>
        <a href={url} className="link--button">
          Continue Reading
        </a>
      </div>
    </li>
  );
};

export const InsightsShowcase = ({ datasourceId, contextPageId, noOverlay = false, language = "en", }) => {
  const defaultFilters = {};
  const [loaded, setLoaded] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [filters, setFilters] = React.useState(defaultFilters);
  const headerContainerRef = React.useRef(null);
  const slidesContainerRef = React.useRef(null);

  async function loadData() {
    try {
      setLoaded(false);
      const response = await api.getInsightsShowcaseData(filters, datasourceId, contextPageId, language);
      // const response = insightsShowcaseData;
      // response.insights.forEach((insight, index) => {
      //   insight.featured = index === 0 || index === 5;
      // });
      setData(response);
      setLoaded(true);
      setError(null);
    } catch (e) {
      setError(e);
    }
  }

  function handleMultipleFilterChange(type, tag) {
    setFilters(toggleMultipleFilter(filters, type, tag));
  }

  React.useEffect(() => {
    loadData();
  }, [filters]);

  const isValidData = !!data;

  React.useEffect(() => {
    if (!loaded || !isValidData) return;
    setTimeout(() => {
      initializeAnimation(headerContainerRef.current);
      initializeAnimation(slidesContainerRef.current);
      decorateInsights();
    }, 250);
  }, [loaded, data]);

  const title =
    data && data.labels && Array.isArray(data.labels)
      ? data.labels.find((o) => o.key === 'latestinsightsshowcase.title')
        ?.value || ''
      : '';
  const viewAllHref =
    data && data.labels && Array.isArray(data.labels)
      ? data.labels.find(
        (o) => o.key === 'latestinsightsshowcase.viewalllink.href'
      )?.value || ''
      : '';
  const viewAllLabel =
    data && data.labels && Array.isArray(data.labels)
      ? data.labels.find(
        (o) => o.key === 'latestinsightsshowcase.viewalllink.description'
      )?.value || ''
      : '';

  const insights = (data ? data.insights : undefined) || [];

  const leftColumnCount = insights
    .filter(
      (_, index) =>
        index === 0 || index === 3 || (insights.length === 6 && index === 5)
    )
    .map((v, index) => (index === 0 ? 2 : 1))
    .reduce((acc, v) => acc + v, 0);
  const rightColumnCount = insights
    .filter(
      (_, index) =>
        index === 1 ||
        index === 2 ||
        (insights.length === 5 ? index === 4 : index === 5)
    )
    .map((v, index) => (insights.length === 6 && index === 2 ? 2 : 1))
    .reduce((acc, v) => acc + v, 0);
  const rowCount = Math.max(rightColumnCount, leftColumnCount);

  return (
    <>
      {isValidData && (
        <div className="insights-container">
          <div className="layout-container">
            <div className="insights--wrapper">
              <div className="insights--wrapper-inner">
                <div
                  className="insights--header"
                  data-animation="false"
                  ref={headerContainerRef}
                >
                  {!!title && <h2 className="heading1">{title}</h2>}
                  <div className="insights--header-button-wrapper">
                    <a
                      href={viewAllHref}
                      className="link--button all-insights-mobile"
                    >
                      {viewAllLabel}
                    </a>
                  </div>
                  <div className="insights--filters-wrap">
                    <div className="insights--filters">
                      <button className="mobile-label">All Categories</button>
                      {(data.calculatedIndustryTags.facetValues || []).map(
                        (tag, index) => (
                          <a
                            className={classNames({
                              hover: isFilterSelected(filters, tag.tagId),
                            })}
                            href={tag.tagUrl || '#'}
                            key={index}
                            onClick={(e) => {
                              e.preventDefault();
                              handleMultipleFilterChange(
                                'calculatedIndustryTags',
                                tag.tagId
                              );
                            }}
                          >
                            {tag.tagName}
                          </a>
                        )
                      )}
                      {(data.areaOfExpertiseTags.facetValues || []).map(
                        (tag, index) => (
                          <a
                            className={classNames({
                              hover: isFilterSelected(filters, tag.tagId),
                            })}
                            href={tag.tagUrl || '#'}
                            key={index}
                            onClick={(e) => {
                              e.preventDefault();
                              handleMultipleFilterChange(
                                'areaOfExpertiseTags',
                                tag
                              );
                            }}
                          >
                            {tag.tagName}
                          </a>
                        )
                      )}
                      {(data.capabilityTags.facetValues || []).map(
                        (tag, index) => (
                          <a
                            className={classNames({
                              hover: isFilterSelected(filters, tag.tagId),
                            })}
                            href={tag.tagUrl || '#'}
                            key={index}
                            onClick={(e) => {
                              e.preventDefault();
                              handleMultipleFilterChange('capabilityTags', tag);
                            }}
                          >
                            {tag.tagName}
                          </a>
                        )
                      )}
                    </div>
                    <a
                      href={viewAllHref}
                      className="link--button all-insights-desktop"
                    >
                      {viewAllLabel}
                    </a>
                  </div>
                </div>
                <div className="insights--carousel glide carousel">
                  <div
                    className="insights--content glide__track"
                    data-glide-el="track"
                  >
                    <ul
                      className={classNames({
                        glide__slides: true,
                        [`rows-${rowCount}`]: true,
                      })}
                      data-animation="false"
                      ref={slidesContainerRef}
                    >
                      {insights.map((insight, index) => (
                        <ResultItem
                          key={index}
                          noOverlay={noOverlay}
                          insight={insight}
                        />
                      ))}
                    </ul>
                  </div>
                  <div className="all-insights--bottom">
                    <a href={viewAllHref} className="link--button all-insights">
                      {viewAllLabel}
                    </a>
                  </div>
                  <div className="glide__bullets" data-glide-el="controls[nav]">
                    {insights.map((_, index) => (
                      <button
                        className="glide__bullet"
                        data-glide-dir={`=${index}`}
                        key={index}
                      ></button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
