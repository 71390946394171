/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
import { initializeAnimation } from './global-animation';
import { getImagePath } from './util';

function decorateInputs(form) {
  const inputs = form.querySelectorAll('input,textarea');
  if (!inputs.length) return;

  const el = form.parentElement;
  let shouldMakeMultiset = el.classList.contains('multi-set-form');

  function onKeydown(evt) {
    if (evt.key === 'Enter') {
      nextStep(mktForm, form, formId, evt);
    }
  }

  function onKeyup(evt) {
    const { srcElement } = evt;
    const { value, parentNode } = srcElement;
    if (value) parentNode.classList.add('has-value');
    else parentNode.classList.remove('has-value');
  }

  inputs.forEach((el) => {
    el.addEventListener('keyup', onKeyup);
    el.addEventListener('keydown', onKeydown);
  });

  const selects = form.querySelectorAll('select');

  selects.forEach((el) => {
    const { parentNode } = el;
    parentNode.classList.add('has-value');
  });

  const checkboxes = form.querySelectorAll('input[type="checkbox"]');

  checkboxes.forEach((el) => {
    el.closest('.mktoFieldWrap').classList.add('mktoCheckbox');
  });

  var formEl = form, //.getFormElem()[0],
    targetWrapper = formEl.closest('[data-fields]'),
    targetData,
    placeholders = formEl.querySelectorAll('.mktoPlaceholder');

  if (targetWrapper) {
    targetData = targetWrapper
      .getAttribute('data-fields')
      .split(',')
      .filter((element) => element);

    if (targetData.length > 0) {
      var moveArray = [];

      var formRows = formEl.querySelectorAll('.mktoFormRow'),
        buttonRow = formEl.querySelector('.mktoButtonRow');

      // tag each field row/wrapper with the name of its first input to make it easily seekable
      for (var i = 0, imax = formRows.length, wrappedField; i < imax; i++) {
        if (
          (wrappedField = formRows[i].querySelector(
            'INPUT[name],SELECT[name],TEXTAREA[name],.mktoPlaceholder',
          ))
        ) {
          var targetName = wrappedField.classList.contains('mktoPlaceholder')
              ? 'placeholder'
              : wrappedField.name,
            checkClass;

          formRows[i].setAttribute('data-wrapper-for', targetName);

          if (targetName == 'placeholder') {
            wrappedField.classList.forEach((cls) => {
              checkClass = cls.replace('mktoPlaceholder', '');
              if (checkClass.length > 0) targetName = checkClass;
            });
          }

          if (targetData.includes(targetName)) {
            moveArray.push(formRows[i]);
          }
        }
      }
      if (!shouldMakeMultiset) {
        // setTimeout(function() {
        moveArray.forEach((el) => {
          formEl.insertBefore(el, buttonRow);
        });
        // }, 200);
      }
    }
  }
}

function decorateRows(container, current = 0) {
  const rows = container.querySelectorAll('.mktoFormRow');
  rows.forEach((row) => {
    row.classList.remove('not-a-marketo-field');
    row.classList.remove('marketo-field');
    row.classList.remove('marketo-submenu-field');
    row.classList.remove('marketo-logic-field');
  });

  rows.forEach((row) => {
    if (!row.querySelector('.mktoFieldDescriptor')) {
      row.classList.add('not-a-marketo-field');
    } else if (
      row.querySelector(
        '.mktoFieldDescriptor[type=hidden][name=mktoUTMContent]',
      ) ||
      row.querySelector(
        '.mktoFieldDescriptor[type=hidden][name=mktoUTMContent]',
      )
    ) {
      row.classList.add('not-a-marketo-field');
    } else if (row.querySelector('.mktoLogicalField')) {
      row.classList.add('marketo-field');
      row.classList.add('marketo-logic-field');
    } else if (row.querySelector('.mktoFieldDescriptor[type=hidden]')) {
      row.classList.add('marketo-submenu-field');
      row.classList.add('not-a-marketo-field');
    } else {
      row.classList.add('marketo-field');
    }
  });

  const fields = container.querySelectorAll(
    '.mktoFormRow:not(.not-a-marketo-field)',
  );

  fields.forEach((field) => {
    field.classList.add('hidden');
  });

  fields[current].classList.remove('hidden');

  return fields;
}

export function handleLogicalField(inputBef, inputFields, step) {
  if (
    inputBef.attributes.getNamedItem('type')?.value === 'checkbox' &&
    inputBef.parentElement.classList.contains('mktoLogicalField') &&
    inputBef.checked
  ) {
    const nextFields = inputFields.slice(step);
    nextFields.some((field) => {
      const isSubmenuField = field.classList.contains('marketo-submenu-field');
      if (isSubmenuField) {
        field.classList.remove('not-a-marketo-field');
        field.classList.add('hidden');
      }
      return !isSubmenuField;
    });
  }
}

export async function nextStep(mktForm, form, formId, evt) {
  evt.stopPropagation();
  evt.preventDefault();

  const multiStepFormContainer = form.parentElement;

  const stepEl = multiStepFormContainer.querySelector('.marketo-stepper-step');
  // eslint-disable-next-line radix
  const step = parseInt(stepEl.innerText);

  const counterEl = multiStepFormContainer.querySelector(
    '.marketo-stepper-counter',
  );

  // eslint-disable-next-line radix
  const total = parseInt(counterEl.innerText);

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(total)) return;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(step)) return;
  // eslint-disable-next-line no-undef

  if (step < total) {
    decorateRows(form, step - 1);
    decorateInputs(form);
    let fields = multiStepFormContainer.querySelectorAll(
      '.mktoFormRow:not(.not-a-marketo-field)',
    );

    const fieldBef = fields[step - 1];
    const inputBef = fieldBef.querySelector(
      'input, select, textarea, checkbox',
    );

    inputBef.focus();
    inputBef.blur();
    inputBef.focus();
    inputBef.blur();

    handleLogicalField(inputBef, fields, step);

    fields = multiStepFormContainer.querySelectorAll(
      '.mktoFormRow:not(.not-a-marketo-field)',
    );
    counterEl.innerHTML = fields.length;

    const hasErrorEl = fieldBef.querySelector('.mktoError');

    if (hasErrorEl && hasErrorEl.style.display !== 'none') return;

    fieldBef.classList.add('hidden');
    fields[step].classList.remove('hidden');
    fields[step].querySelector('input, select, textarea').focus();
    stepEl.innerText = step + 1;
  } else {
    mktForm.submit();
  }
}

export function createStepper(
  mktForm,
  form,
  formId,
  target = document,
  counter = 1,
) {
  const el = target.querySelector('form');
  const stepperDiv = document.createElement('div');
  stepperDiv.setAttribute('class', 'marketo-stepper-container');

  const stepDiv = document.createElement('div');
  stepDiv.setAttribute('class', 'marketo-stepper-step');
  stepDiv.innerHTML = '1';

  const counterDiv = document.createElement('div');
  counterDiv.setAttribute('class', 'marketo-stepper-counter');
  counterDiv.innerHTML = counter;

  if (counter === 1)
    stepperDiv.classList.add('marketo-stepper-container--hidden');

  stepperDiv.appendChild(stepDiv);
  stepperDiv.appendChild(counterDiv);
  el.appendChild(stepperDiv);
}

export function createControls(mktForm, form, formId, target = document) {
  const el = target.querySelector('form');
  const controlsDiv = document.createElement('div');
  controlsDiv.setAttribute('class', 'marketo-controls-container');

  const btn = document.createElement('button');
  btn.setAttribute('class', 'marketo-controls-button');

  const img = document.createElement('img');
  img.setAttribute('src', getImagePath('/images/arrow-white.svg'));

  btn.addEventListener('click', (e) => nextStep(mktForm, form, formId, e));

  btn.appendChild(img);
  controlsDiv.appendChild(btn);
  el.appendChild(controlsDiv);
}

export function initializeMarketMultiSetForm(form, htmlForm) {
  const el = htmlForm.parentElement;
  let shouldMakeMultiset = el.classList.contains('multi-set-form');
  if (!shouldMakeMultiset) return;

  try {
    var userConfig = {
      buttons: {
        prev: {
          label: 'Previous',
          disabled: false,
        },
        next: {
          label: 'Next',
        },
      },
      requiredFields: [],
    };

    const requireFields = el.querySelectorAll(
      '.multi-set-form .mktoField.mktoRequired',
    );
    requireFields.forEach((o, i) => {
      userConfig.requiredFields.push({
        name: o.name,
        message: `This field is required`,
      });
    });

    var formEl = form.getFormElem()[0],
      arrayify = getSelection.call.bind([].slice);

    var fieldRowStor = '.mktoForm > .mktoFormRow',
      buttonRowStor = '.mktoForm > .mktoButtonRow',
      buttonStor = '.mktoButtonRow .mktoButton',
      fsaatPrefix = 'fsaat-',
      localFragmentAttr = 'data-form-local-fragment';

    var CSSOM_RULEPOS_FIRST = 0;

    var fieldRowsCopy = formEl.querySelectorAll(fieldRowStor),
      submitButtonRow = formEl.querySelector(buttonRowStor),
      submitButton = submitButtonRow.querySelector(buttonStor);

    //lets remove the hidden fields
    var fieldRows = [];
    var excludedFieldRows = [];
    fieldRowsCopy.forEach((o, i) => {
      if (
        o.children &&
        o.children.length > 0 &&
        o.children[0].type == 'hidden'
      ) {
        excludedFieldRows.push(o);
      } else {
        fieldRows.push(o);
      }
    });

    userConfig.requiredFields
      .map(function (fieldDesc) {
        fieldDesc.label = formEl.querySelector(
          "[for='" + fieldDesc.name + "']",
        );
        fieldDesc.refEl = formEl.querySelector(
          "[name='" + fieldDesc.name + "']",
        );
        return fieldDesc;
      })
      .forEach(function (fieldDesc) {
        fieldDesc.label.parentNode.classList.add('mktoRequiredField');
      });

    var dynableSheet = arrayify(document.styleSheets).filter(function (sheet) {
      return sheet.ownerNode.nodeName == 'STYLE';
    })[0];

    arrayify(fieldRows).forEach(function (row, rowIdx) {
      var rowPos = {
        isFirst: rowIdx == 0,
        isLast: rowIdx == fieldRows.length - 1,
      };

      // id each wrapper row in DOM order
      row.id = fsaatPrefix + rowIdx;

      arrayify(excludedFieldRows).forEach(function (excludedRow) {
        excludedRow.id = fsaatPrefix + rowIdx;
      })

      var navButtonRow = rowPos.isLast
          ? submitButtonRow
          : submitButtonRow.cloneNode(true),
        newRowAxis = row.nextSibling,
        nextEnabled = !rowPos.isLast,
        prevEnabled = !rowPos.isFirst && !userConfig.buttons.prev.disabled,
        newButtonAxis,
        newButtonTmpl,
        navButtons = {};

      if (nextEnabled) {
        navButtons.next = navButtonRow.querySelector(buttonStor);
      }

      if (prevEnabled) {
        newButtonTmpl = newButtonAxis = navButtons.next || submitButton;
        navButtons.prev = newButtonTmpl.cloneNode();
      }

      Object.keys(navButtons).forEach(function (dir) {
        navButtons[dir].type = 'button';
        navButtons[dir].setAttribute('data-dir', dir);
        navButtons[dir].innerHTML = userConfig.buttons[dir].label;
      });

      if (nextEnabled) {
        row.parentNode.insertBefore(navButtonRow, newRowAxis);
      }

      if (prevEnabled) {
        newButtonAxis.parentNode.insertBefore(navButtons.prev, newButtonAxis);
      }

      navButtonRow.addEventListener('click', function (e) {
        if (e.target.tagName == 'BUTTON' && e.target.type == 'button') {
          if (
            e.target.getAttribute('data-dir') == 'next' &&
            !isCustomValid(true, row)
          ) {
            return;
          }
          fsaatSet(row, e.target.getAttribute('data-dir'));
        }
      });

      dynableSheet.insertRule(
        [
          '.mktoForm[' +
            localFragmentAttr +
            "='#" +
            row.id +
            "']" +
            ' ' +
            '.mktoFormRow#' +
            row.id +
            ',',
          '.mktoForm[' +
            localFragmentAttr +
            "='#" +
            row.id +
            "']" +
            ' ' +
            '.mktoFormRow#' +
            row.id +
            ' + ' +
            '.mktoButtonRow',
          '{ display: block; }',
        ].join(' '),
        CSSOM_RULEPOS_FIRST,
      );
    });

    function fsaatSet(current, dir) {
      var FSAAT_DIR_PREV = 'prev',
        FSAAT_DIR_NEXT = 'next';

      var dir = dir || FSAAT_DIR_NEXT,
        currentIndex,
        newHash;

      if (current instanceof HTMLElement) {
        currentIndex = +current.id.split(fsaatPrefix)[1];
      } else if (!isNaN(current)) {
        currentIndex = current;
      } else {
        currentIndex = -1;
      }

      newHash =
        '#' +
        fsaatPrefix +
        (dir == FSAAT_DIR_NEXT ? ++currentIndex : --currentIndex);

      formEl.setAttribute(localFragmentAttr, newHash);
    }

    function isCustomValid(native, currentStep) {
      form.submittable(false);

      var currentStep = currentStep || formEl,
        currentValues = form.getValues();

      var currentUnfilled = userConfig.requiredFields.filter(
        function (fieldDesc) {
          return (
            currentStep.contains(fieldDesc.refEl) &&
            (!currentValues[fieldDesc.name] ||
              (fieldDesc.refEl.type == 'checkbox' &&
                currentValues[fieldDesc.name] == 'no'))
          );
        },
      );

      if (currentUnfilled.length) {
        form.showErrorMessage(
          currentUnfilled[0].message,
          MktoForms2.$(currentUnfilled[0].refEl),
        );
        return false;
      } else {
        form.submittable(true);
        return true;
      }
    }

    form.onValidate(isCustomValid);
    fsaatSet();
  } catch (error) {
    console.error('failed to start multiset for marketo', error);
  }
}

export function initializeMarketoMultiStepForm(mktForm, form, formId) {
  const el = form.parentElement;
  let shouldMakeMultistep = el.classList.contains('multi-step-form');
  if (!shouldMakeMultistep) return;

  try {
    const buttonContainer = el.querySelector('.mktoButtonRow');
    if (!buttonContainer) return;

    const rows = el.querySelectorAll('.mktoFormRow');
    rows.forEach((row) => {
      if (!row.querySelector('div.mktoFieldDescriptor')) {
        row.classList.add('not-a-marketo-field');
      } else {
        row.classList.add('marketo-field');
      }
    });

    const fields = el.querySelectorAll(
      '.mktoFormRow:not(.not-a-marketo-field)',
    );

    fields.forEach((field, i) => {
      if (i && i > 0) field.classList.add('hidden');
    });

    buttonContainer.classList.add('hidden');

    createStepper(mktForm, form, formId, el, fields.length);
    createControls(mktForm, form, formId, el);
  } catch (error) {
    console.error('failed to start multistep for marketo', error);
  }
}

export async function loadMarketoScripts(url) {
  const check = document.querySelector('link#mktoForms2BaseStyle');
  if (check) return true;
  const head = document.getElementsByTagName('head')[0];

  const link = document.createElement('link');
  link.id = 'mktoForms2BaseStyle';
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = `${url}/js/forms2/css/forms2.css`;
  head.appendChild(link);

  const link2 = document.createElement('link');
  link2.id = 'mktoForms2BaseStyle';
  link2.rel = 'stylesheet';
  link2.type = 'text/css';
  link2.href = `${url}/js/forms2/css/forms2-theme-simple.css`;
  head.appendChild(link2);

  const script = document.createElement('script');
  script.src = `${url}/js/forms2/js/forms2.min.js`;
  head.appendChild(script);

  // setInterval is the most secure way to check if a script has been loaded,
  // onload and addeventlisteners do not work all the times and across multiple navigators
  // - artur
  return new Promise((resolve) => {
    let interval = setInterval(() => {
      try {
        if (MktoForms2) {
          clearInterval(interval);
          const event = new CustomEvent('marketo-script-loaded');
          document.dispatchEvent(event);
          resolve(true);
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }, 100);
  });
}

export function onFormLoad(mktForm, form, formId) {
  initializeMarketoMultiStepForm(mktForm, form, formId);
  initializeMarketMultiSetForm(mktForm, form);
  decorateInputs(form);

  const event = new CustomEvent(`marketo-form-loaded-${formId}`);
  document.dispatchEvent(event);
}

export function loadForm(url, accountId, el, formId) {
  return new Promise((resolve) => {
    MktoForms2.loadForm(url, accountId, formId, (mktForm) => {
      onFormLoad(mktForm, el, formId);
      resolve(mktForm);
    });
  });
}

export async function initializeMarketoBase() {
  const marketoForms = document.querySelectorAll('[data-marketo-url]');
  if (!marketoForms || !marketoForms.length) return;

  for (let index = 0; index < marketoForms.length; index += 1) {
    const el = marketoForms[index];

    const {
      marketoUrl: url,
      marketoAccountId: accountId,
      marketoFormId: formId,
      forceLoad,
    } = el.dataset;
    const loaded = !!document.querySelector(
      `[data-marketo-form-id="${formId}"][data-loaded="true"]`,
    );
    if (loaded && (!forceLoad || forceLoad !== 'true')) {
      console.error(
        'You tried to load 2 marketoForms with the same ID, we can not do it without lose control of then, form id:',
        formId,
      );
      // eslint-disable-next-line no-continue
      continue;
    }
    el.id = `mktoForm_${formId}`;
    el.dataset.loaded = 'true';
    el.dataset.animation = false;
    initializeAnimation(el);

    // eslint-disable-next-line no-await-in-loop
    await loadMarketoScripts(url);
    await loadForm(url, accountId, el, formId);
    el.id = ``;
  }
}
