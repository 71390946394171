export function decorateInsightHover(parent) {
  const insights = parent.querySelectorAll('.insight');
  insights.forEach((insight) => {
    const insightImage = insight.querySelector('.insight__image');
    const insightTitle = insight.querySelector('.insight__title a');
    if (!insightImage || !insightTitle) return;
    insightImage.addEventListener('mouseover', () => {
      insightTitle.classList.add('hover');
    });
    insightImage.addEventListener('mouseout', () => {
      insightTitle.classList.remove('hover');
    });
    insightTitle.addEventListener('mouseover', () => {
      insightImage.classList.add('insight__image--hover');
    });
    insightTitle.addEventListener('mouseout', () => {
      insightImage.classList.remove('insight__image--hover');
    });
  });
}
