export default function () {
  const el = document.querySelector('.our-values--wrapper');
  if (!el) return;

  function setActiveValue(evt) {
    const { srcElement } = evt;
    const parent = srcElement.parentNode;

    const { classList } = parent;
    if (classList.contains('active')) return;

    const innerTexts = parent.querySelector('.our-values--texts');
    const innerSubtitle = innerTexts.querySelector('.subtitle');
    innerSubtitle.style.transitionDelay = '0s';
    innerSubtitle.style.transitionDuration = '0s, 0.56s';
    const innerText = innerTexts.querySelector('.text');
    innerText.style.transitionDuration = '0s, 0.56s';
    innerText.style.transitionDelay = '0.2s';

    if (innerText && innerSubtitle) {
      const outerTexts = document.querySelector(
        '.our-values--container > .our-values--texts'
      );
      const outerSubtitle = outerTexts.querySelector('.subtitle');
      const outerText = outerTexts.querySelector('.text');

      // hide previous text quickly
      outerSubtitle.style.transitionDelay = '0s';
      outerSubtitle.style.transitionDuration = '0s, 0.56s';
      outerText.style.transitionDelay = '0s';
      outerText.style.transitionDuration = '0s, 0.56s';
      outerTexts.dataset.animation = false;

      // configure next text to display
      outerSubtitle.innerText = innerSubtitle.innerText;
      outerText.innerText = innerText.innerText;
      outerText.style.transitionDelay = '0.2s';

      // show text
      setTimeout(() => {
        outerTexts.dataset.animation = true;
      }, 200);
    }

    let removableEls = document.querySelectorAll('.our-values--item-title');

    removableEls.forEach((element) => {
      element.classList.remove('active');
      element.querySelector('.our-values--texts').dataset.animation = false;
    });

    parent.classList.add('active');
    setTimeout(() => {
      innerTexts.dataset.animation = true;
    }, 200);
  }

  const clicklableEls = document.querySelectorAll('.our-values--item-title');

  clicklableEls.forEach((element) => {
    element.addEventListener('click', (e) => setActiveValue(e));
  });
}
