import colorTrigger from './color-trigger';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import tippy from 'tippy.js';

gsap.registerPlugin(ScrollTrigger);

// Adapted from https://stackoverflow.com/a/68523696
function applyOutlookFixToEmailButtons() {
  const emailButtons = Array.from(
    document.querySelectorAll('.st-custom-button[data-network=email]')
  );
  emailButtons.forEach((button) =>
    button.addEventListener('click', () => {
      // eslint-disable-next-line quotes
      const subject = "I'd like to share a link with you";
      // eslint-disable-next-line no-underscore-dangle
      const body = window.__sharethis__.href;
      document.location = 'mailto:?subject=' + subject + '&body=' + body;
    })
  );
}

export function initializeSocialShare() {
  const socialShare = document.querySelector('.social-share');

  if (!socialShare) return;
  const saveElement = document.querySelector('.social-share--save');

  if (saveElement) {
    colorTrigger('.social-share--save', 'social-share--white', 200);
    colorTrigger('.social-share--share', 'social-share--white', 295);
  } else {
    colorTrigger('.social-share--share', 'social-share--white', 200);
  }

  const socialShareButton = document.querySelector('.social-share--share');
  const socialShareContent = document.querySelector(
    '.social-share--share-content'
  );

  setTimeout(() => {
    tippy(socialShareButton, {
      allowHTML: true,
      interactive: true,
      placement: 'left',
      content(reference) {
        return socialShareContent;
      },
    });
  }, 1000);

  // eslint-disable-next-line no-underscore-dangle
  if (window.__sharethis__ && window.__sharethis__.loaded)
    applyOutlookFixToEmailButtons();
  else {
    window.onShareThisLoaded = applyOutlookFixToEmailButtons;
  }
}
