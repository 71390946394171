import * as React from 'react';
import { render } from 'react-dom';
import { getDefaultFilters, knownInsightTagTypes } from './components/Filter';
import { LatestInsightsNewsroom } from './components/LatestInsightsNewsroom';
import { decorateInsightHover } from './latest-insights-common';
import { initializeSearchFilter } from './search-filter';

export function decorateLatestInsightsNewsroom(demo = false) {
  const latestInsightsNewsroom = document.querySelector(
    '.latest-insights-newsroom-container'
  );
  if (!latestInsightsNewsroom) return;

  initializeSearchFilter('.latest-insights-newsroom', demo);

  if (demo) {
    decorateInsightHover(latestInsightsNewsroom);
  }
}

export function initializeLatestInsightsNewsroom() {
  const root = document.getElementById('latest-insights-newsroom-root');
  if (typeof root?.dataset?.datasourceId === 'undefined') return;
  if (root)
    render(
      <LatestInsightsNewsroom
        datasourceId={root.dataset.datasourceId}
        contextPageId={root.dataset.pagecontext}
        defaultFilters={getDefaultFilters(knownInsightTagTypes)}
      />,
      root
    );
}
