import * as React from 'react';
import { LatestInsights } from './LatestInsights';
import { getLatestInsightsEventsData } from '../api/request';
import { latestInsightsEventsData } from '../api/mockdata';
import { decorateLatestInsightsEvents } from '../latest-insights-events';
import { Insight } from './Insights';
import { capitalize } from 'lodash';
import moment from 'moment';

export const LatestInsightsEvents = ({ datasourceId, contextPageId, defaultFilters, language="en" }) => (
  <LatestInsights
    variant="events"
    datasourceId={datasourceId}
    language={language}
    contextPageId={contextPageId}
    defaultFilters={defaultFilters}
    onGetData={getLatestInsightsEventsData}
    //onGetData={async () => latestInsightsEventsData}
    onRenderInsight={(data) => <Insight {...data} />}
    onDecorate={() => decorateLatestInsightsEvents()}
    onBuildFilters={(data) => {
      return [
        {
          control: 'text-link',
          text: 'Upcoming events',
          onShowActive: (dataFilters) => {
            return dataFilters.upcomingEvents;
          },
          onCreateFilters: (dataFilters) => {
            return {
              ...dataFilters,
              startDisplayDate: moment().format('YYYY-MM-DD'),
              endDisplayDate: null,
            };
          },
        },
        {
          control: 'text-link',
          text: 'Past events',
          onShowActive: (dataFilters) => {
            return dataFilters.replays;
          },
          onCreateFilters: (dataFilters) => {
            return {
              ...dataFilters,
              endDisplayDate: moment().format('YYYY-MM-DD'),
              startDisplayDate: null,
            };
          },
        },
        {
          control: 'separator',
        },
        {
          control: 'date-range',
          name: 'Date',
          onShowActive: (dataFilters) => {
            return (
              !!dataFilters.startDisplayDate && !!dataFilters.endDisplayDate
            );
          },
          onCreateFilters: (dataFilters, startDisplayDate, endDisplayDate) => ({
            ...dataFilters,
            startDisplayDate,
            endDisplayDate,
          }),
        },
        {
          control: 'dropdown',
          type: 'location',
          name: data ? capitalize(data.cities.description) : '',
          filterType: 'cities',
          filterItems: data
            ? data.cities.facetCities.map((city) => ({
                tagId: city.cityId,
                tagName: city.cityName,
                tagCount: city.cityCount,
              }))
            : [],
        },
      ];
    }}
    onBuildTags={(insight) => [
      ...(insight.insightTopicTags || []),
      ...(insight.businessIssueTags || []),
      ...(insight.calculatedIndustryTags || []),
      ...(insight.calculatedSubIndustryDescriptions || []),
      ...(insight.areaOfExpertiseTags || []),
      ...(insight.capabilityTags || []),
    ]}
  />
);
