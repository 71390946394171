import { setEngagementGoal, ENGAGEMENT_GOAL_TYPES } from './api/request';

export function initializeGetInTouch() {
  const els = document.querySelectorAll('.get-in-touch--container');
  for (let index = 0; index < els.length; index += 1) {
    const el = els[index];
    if (!el) return;

    const submitBtn = el.querySelector('button.submit-btn.btn.btn--black');
    const formEl = el.querySelector('form');
    const { marketoFormId: formId } = formEl.dataset;

    let form;

    document.addEventListener(`marketo-form-loaded-${formId}`, () => {
      form = MktoForms2.getForm(formId);
      if (!form) return;

      form.onSuccess(function (values, followUpUrl) {
        // API call to sitecore backend to set user engagement score for successfully filling out a form
        setEngagementGoal(ENGAGEMENT_GOAL_TYPES.EMAIL_CONSULTANT);
        el.querySelector('.get-in-touch--confirmation').style.display = 'block';
        el.querySelector('.get-in-touch--form').style.display = 'none';
        el.querySelector('.submit-btn').style.display = 'none';
        return false;
      });

      // eslint-disable-next-line func-names
      form.onSubmit(function (evt) {
        form.submittable(true);
        return true;
      });
    });

    function submitForm(evt) {
      form.submit();
    }

    submitBtn.addEventListener('click', submitForm);
  }
}
