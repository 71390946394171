export default function () {
  let mouseover = false;
  const el = document.querySelector('.breadcrumbs-wrapper');
  if (!el) return;

  let mql = window.matchMedia('(min-width: 1024px)');

  const items = document.querySelectorAll(
    '.breadcrumbs--item:not(:first-child)'
  );

  function onMouseOver(e) {
    if (mouseover) return;

    if (e.matches) {
      mouseover = true;

      items.forEach((item) => {
        item.style.width = `${item.scrollWidth + 5}px`;
      });
    } else {
      items.forEach((item) => {
        if (item.getAttribute('style')) {
          if (item.style.removeProperty) {
            item.style.removeProperty('width');
          } else {
            item.style.removeAttribute('width');
          }
        }
      });
    }
  }

  function onMouseLeave(e) {
    if (!mouseover) return;

    if (e.matches) {
      mouseover = false;

      items.forEach((item) => {
        item.style.width = `0px`;
      });
    } else {
      items.forEach((item) => {
        if (item.getAttribute('style')) {
          if (item.style.removeProperty) {
            item.style.removeProperty('width');
          } else {
            item.style.removeAttribute('width');
          }
        }
      });
    }
  }

  function handleWindowResize(e) {
    if (!e.matches) {
      if (items) {
        items.forEach((item) => {
          if (item.getAttribute('style')) {
            if (item.style.removeProperty) {
              item.style.removeProperty('width');
            } else {
              item.style.removeAttribute('width');
            }
          }
        });
      }
    }
  }

  mql.addEventListener('change', handleWindowResize);

  el.addEventListener('mouseover', onMouseOver);
  el.addEventListener('mouseleave', onMouseLeave);
}
