export function initializeLatestreadings() {
  const relatedReading = document.querySelector('.related-reading-container');

  if (!relatedReading) {
    return;
  }

  const readings = relatedReading.querySelectorAll('.reading');
  readings.forEach((reading) => {
    const readingImage = reading.querySelector('.reading__image');
    const readingTitle = reading.querySelector('.reading__title a');
    readingImage.addEventListener('mouseover', () => {
      readingTitle.classList.add('hover');
    });
    readingImage.addEventListener('mouseout', () => {
      readingTitle.classList.remove('hover');
    });
    readingTitle.addEventListener('mouseover', () => {
      readingImage.classList.add('reading__image--hover');
    });
    readingTitle.addEventListener('mouseout', () => {
      readingImage.classList.remove('reading__image--hover');
    });
  });
}
