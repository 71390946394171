import classNames from 'classnames';
import { isArray } from 'core-js/core/array';
import { _sortPropTweensByPriority } from 'gsap/gsap-core';
import * as React from 'react';
import { getImagePath, placeholder1xImagePath } from '../util';
import { CaretIcon, CrossIcon } from './Icons';

export const HiddenFilterItem = ({ type = '', index = 0 }) => {
  return (
    <div
      id={type && Number.isInteger(index) ? `${type}${index}` : undefined}
      aria-label="no tag active"
      role="option"
      className="visually-hidden"
    ></div>
  );
};

export const FilterItem = ({
  index,
  tagName,
  tagCount,
  tagId,
  variant = 'insights',
  type = '',
  onToggleFilter,
  selected,
}) => {
  return (
    <div
      id={type && Number.isInteger(index) ? `${type}${index}` : undefined}
      aria-label={`${type ? `${type} ` : ''}${tagName}`}
      role="option"
      className={classNames({
        [`latest-insights-${variant}__item`]: true,
        [`latest-insights-${variant}__item--selected`]: selected,
      })}
      onClick={(e) => {
        e.stopPropagation();
        if (onToggleFilter) onToggleFilter({ tagName, tagId });
      }}
      onKeyDown={(e) => {}}
      onKeyPress={(e) => {
        if (e.key === 'Enter' && onToggleFilter)
          onToggleFilter({ tagName, tagId });
      }}
    >
      <CrossIcon />
      <p>{tagName}</p>
      <span>{tagCount}</span>
    </div>
  );
};

export const FilterNavItem = ({ className, children, ...props }) => {
  return (
    <div
      role="menuitem"
      className={classNames({
        'filter__nav-item': true,
        [className]: !!className,
      })}
      {...props}
    >
      {children}
    </div>
  );
};

export const FilterFocusNavItem = ({ className, onClick, children }) => {
  const [focus, setFocus] = React.useState(false);
  const [hover, setHover] = React.useState(false);
  return (
    <FilterNavItem
      className={classNames({
        [className]: !!className,
        hover: !!hover || !!focus,
      })}
    >
      <div
        tabIndex="0"
        onClick={onClick}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        onMouseEnter={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        {children}
      </div>
    </FilterNavItem>
  );
};

export const FilterSeparator = () => {
  return (
    <FilterNavItem className="filter__nav-item-separator">|</FilterNavItem>
  );
};

export const FilterDateRange = ({ name }) => {
  return (
    <FilterFocusNavItem
      className="filter__dropdown-nav-item"
      // onClick={onClick}
    >
      {name}
    </FilterFocusNavItem>
  );
};

export const FilterDateRangeDropdown = ({ variant = 'insights' }) => {
  return (
    <div className={`latest-insights-${variant}__filters-dropdown`}>
      <div className="layout-container">
        <div className={`latest-insights-${variant}__filters-dropdown-inner`}>
          <div className={`latest-insights-${variant}__content`}>
            <div className={`latest-insights-${variant}__items`}>
              <input type="date" />
              <input type="date" />
            </div>
            {/* <div className={`latest-insights-${variant}__items`}>
              {/* <HiddenFilterItem type={type} />
              {filterItems.map((filterItemProps, index) => (
                <FilterItem
                  variant={variant}
                  selected={
                    Array.isArray(selectedFilters)
                      ? selectedFilters.indexOf(filterItemProps.tagId) > -1
                      : selectedFilters === filterItemProps.tagId
                  }
                  index={index + 1}
                  type={type}
                  onToggleFilter={(tag) => onToggleFilter(filterType, tag)}
                  key={index}
                  {...filterItemProps}
                />
              ))}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export const FilterTextLinkNavItem = ({ text, onClick, active }) => {
  return (
    <FilterFocusNavItem
      className={classNames({
        'filter__text-link-nav-item': true,
        hover: !!active,
      })}
      onClick={onClick}
    >
      {text}
    </FilterFocusNavItem>
  );
};

export const FilterImageLinkNavItem = ({ url, imageUrl, alt, onClick }) => {
  return (
    <FilterFocusNavItem className="filter__image-link-nav-item">
      <a href={url}>
        <img src={imageUrl} alt={alt}></img>
      </a>
    </FilterFocusNavItem>
  );
};

export const FilterDropdownNavItem = ({ type, name }) => {
  return (
    <FilterNavItem className="filter__dropdown-nav-item">
      <div
        tabIndex="0"
        role="listbox"
        aria-label={type}
        aria-activedescendant={`${type}0`}
      >
        {name}
      </div>
      <CaretIcon thin />
    </FilterNavItem>
  );
};

export const FilterDropdown = ({
  variant = 'insights',
  filterType,
  type,
  filterItems = [],
  onToggleFilter,
  selectedFilters,
}) => {
  return (
    <div className={`latest-insights-${variant}__filters-dropdown`}>
      <div className="layout-container">
        <div className={`latest-insights-${variant}__filters-dropdown-inner`}>
          <div className={`latest-insights-${variant}__content`}>
            <div className={`latest-insights-${variant}__items`}>
              <HiddenFilterItem type={type} />
              {filterItems.map((filterItemProps, index) => (
                <FilterItem
                  variant={variant}
                  selected={
                    Array.isArray(selectedFilters)
                      ? selectedFilters.indexOf(filterItemProps.tagId) > -1
                      : selectedFilters === filterItemProps.tagId
                  }
                  index={index + 1}
                  type={type}
                  onToggleFilter={(tag) => onToggleFilter(filterType, tag)}
                  key={index}
                  {...filterItemProps}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DesktopFilter = ({
  variant = 'insights',
  dataFilters = {},
  filters = [],
  selectedTags = [],
  selectedFilters,
  containerRef,
  showTags = true,
  onClearFilters,
  onFilterChange,
  onToggleFilter,
  onRemoveFilter,
  filtersLabel,
  clearAllFiltersLabel,
}) => {
  return (
    <div
      className={`latest-insights-${variant}__filters-container`}
      data-animation="false"
      ref={containerRef}
    >
      <div className={`latest-insights-${variant}__filters`}>
        <div className="layout-container">
          <div
            role="menu"
            className={`latest-insights-${variant}__filters-nav`}
          >
            {filters.map((filter, index) => {
              return (
                <>
                  {filter.control === 'dropdown' && (
                    <FilterDropdownNavItem
                      type={filter.type}
                      name={filter.name}
                      key={index}
                    />
                  )}
                  {filter.control === 'separator' && <FilterSeparator />}
                  {filter.control === 'text-link' && (
                    <FilterTextLinkNavItem
                      text={filter.text}
                      active={
                        !filter.onShowActive || filter.onShowActive(dataFilters)
                      }
                      onClick={() => {
                        if (!filter.onCreateFilters) return;
                        onFilterChange(filter.onCreateFilters(dataFilters));
                      }}
                    />
                  )}
                  {filter.control === 'image-link' && (
                    <FilterImageLinkNavItem
                      imageUrl={filter.imageUrl}
                      url={filter.url}
                    />
                  )}
                  {filter.control === 'date-range' && (
                    <FilterDateRange
                      name={filter.name}
                      onClick={() =>
                        filter.onCreateFilters &&
                        onFilterChange(filter.onCreateFilters(dataFilters))
                      }
                    />
                  )}
                </>
              );
            })}
          </div>
        </div>
        <div className={`latest-insights-${variant}__filters-dropdowns`}>
          {filters.map((filter, index) => (
            <>
              {filter.control === 'dropdown' && (
                <FilterDropdown
                  variant={variant}
                  type={filter.type}
                  selectedFilters={selectedFilters[filter.filterType]}
                  filterType={filter.filterType}
                  filterItems={filter.filterItems}
                  key={index}
                  onToggleFilter={onToggleFilter}
                />
              )}
              {filter.control === 'date-range' && (
                <FilterDateRangeDropdown
                  variant={variant}
                  type={filter.type}
                  selectedFilters={selectedFilters[filter.filterType]}
                  filterType={filter.filterType}
                  filterItems={filter.filterItems}
                  key={index}
                  onToggleFilter={onToggleFilter}
                />
              )}
            </>
          ))}
        </div>
        <div className="layout-container">
          <a
            href="#"
            className={`latest-insights-${variant}__mobile-nav-link link--button-white`}
          >
            <img src={getImagePath('images/filter-menu-white.svg')} alt="" />
            {filtersLabel}
          </a>
        </div>
      </div>
      <div className="layout-container">
        <div className={`latest-insights-${variant}__tags`}>
          {showTags && (
            <>
              {selectedTags.map((selectedTag, index) => (
                <div className={`latest-insights-${variant}__tag`} key={index}>
                  <a
                    href="#"
                    aria-label={selectedTag.tagName}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (onRemoveFilter) onRemoveFilter(selectedTag);
                    }}
                  >
                    <img src={getImagePath('/images/close.svg')} alt="" />
                  </a>

                  {selectedTag.tagName}
                </div>
              ))}

              <a
                href="#"
                className={`latest-insights-${variant}__clear-tags link--button`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (onClearFilters) onClearFilters();
                }}
              >
                {clearAllFiltersLabel}
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export const FilterMobileDateRange = ({
  variant = 'insights',
  name,
  open = false,
  onOpen,
}) => {
  return (
    <div
      className={classNames({
        [`latest-insights-${variant}__mobile-nav__item`]: true,
        active: open,
      })}
      onClick={() => onOpen && onOpen(!open)}
    >
      <div className={`latest-insights-${variant}__mobile-nav__item-title`}>
        {name}
        <CaretIcon thin />
      </div>
      <div className={`latest-insights-${variant}__mobile-nav__item-filters`}>
        <input type="date" />
        <input type="date" />
      </div>
    </div>
  );
};

export const FilterMobileNavItem = ({
  name,
  variant = 'insights',
  filterType,
  selectedFilters,
  filterItems = [],
  open,
  onToggleFilter,
  onOpen,
}) => {
  return (
    <div
      className={classNames({
        [`latest-insights-${variant}__mobile-nav__item`]: true,
        active: open,
      })}
      onClick={() => onOpen && onOpen(!open)}
    >
      <div className={`latest-insights-${variant}__mobile-nav__item-title`}>
        {name}
        <CaretIcon thin />
      </div>
      <div className={`latest-insights-${variant}__mobile-nav__item-filters`}>
        <div className={`latest-insights-${variant}__items`}>
          {filterItems.map((filterItemProps, index) => (
            <FilterItem
              {...filterItemProps}
              selected={
                Array.isArray(selectedFilters)
                  ? selectedFilters.indexOf(filterItemProps.tagId) > -1
                  : selectedFilters === filterItemProps.tagId
              }
              key={index}
              onToggleFilter={(tag) => {
                if (!onOpen) return;
                onOpen(false);
                if (!onToggleFilter) return;
                onToggleFilter(filterType, tag);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export const MobileFilter = ({
  variant = 'insights',
  filters,
  selectedTags,
  selectedFilters,
  onClearFilters,
  onFilterChange,
  onToggleFilter,
  closeLabel,
  clearAllLabel,
}) => {
  const defaultOpen = null;
  const closeRef = React.useRef(null);
  const [open, setOpen] = React.useState(defaultOpen);
  return (
    <div className={`latest-insights-${variant}__mobile-nav`}>
      <div className={`latest-insights-${variant}__mobile-nav__inner`}>
        <a
          href="#"
          className={`latest-insights-${variant}__mobile-nav-close`}
          ref={closeRef}
          onClick={() => setOpen(defaultOpen)}
        >
          {closeLabel}
          <img src={getImagePath('/images/close.svg')} role="presentation" />
        </a>
        <div className={`latest-insights-${variant}__mobile-nav__items`}>
          {/* {filters.map((filter, index) => (
            <FilterMobileNavItem
              variant={variant}
              name={filter.name}
              filterType={filter.filterType}
              filterItems={filter.filterItems}
              selectedFilters={selectedFilters[filter.filterType]}
              key={index}
              open={open === index}
              onOpen={() => setOpen(open !== index ? index : defaultOpen)}
              onToggleFilter={(filterType, tag) => {
                setOpen(defaultOpen);
                closeRef.current.click();
                if (onToggleFilter)
                  setTimeout(() => onToggleFilter(filterType, tag), 1750);
              }}
            />
          ))} */}

          {filters.map((filter, index) => {
            return (
              <>
                {filter.control === 'dropdown' && (
                  <FilterMobileNavItem
                    variant={variant}
                    name={filter.name}
                    filterType={filter.filterType}
                    filterItems={filter.filterItems}
                    selectedFilters={selectedFilters[filter.filterType]}
                    key={index}
                    open={open === index}
                    onOpen={() => setOpen(open !== index ? index : defaultOpen)}
                    onToggleFilter={(filterType, tag) => {
                      setOpen(defaultOpen);
                      closeRef.current.click();
                      if (onToggleFilter)
                        setTimeout(() => onToggleFilter(filterType, tag), 1750);
                    }}
                  />
                )}
                {filter.control === 'separator' && <FilterSeparator />}
                {filter.control === 'text-link' && (
                  <FilterTextLinkNavItem
                    text={filter.text}
                    onClick={() =>
                      filter.onCreateFilters &&
                      onFilterChange(filter.onCreateFilters(dataFilters))
                    }
                  />
                )}
                {filter.control === 'image-link' && (
                  <FilterImageLinkNavItem
                    imageUrl={filter.imageUrl}
                    onClick={() =>
                      filter.onCreateFilters &&
                      onFilterChange(filter.onCreateFilters(dataFilters))
                    }
                  />
                )}
                {filter.control === 'date-range' && (
                  <FilterMobileDateRange
                    name={filter.name}
                    onOpen={() => setOpen(open !== index ? index : defaultOpen)}
                    open={open === index}
                    onClick={() =>
                      filter.onCreateFilters &&
                      onFilterChange(filter.onCreateFilters(dataFilters))
                    }
                  />
                )}
              </>
            );
          })}
        </div>
        {!!selectedTags.length && (
          <div className={`latest-insights-${variant}__mobile-nav__bottom`}>
            {/* <a
            href="#"
            className={`latest-insights-${variant}__mobile-nav__apply-filters btn btn--white`}
          >
            Apply
          </a> */}
            <a
              href="#"
              className={`latest-insights-${variant}__mobile-nav__clear-filters link--button-white`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpen(defaultOpen);
                closeRef.current.click();
                if (onClearFilters) setTimeout(() => onClearFilters(), 1750);
              }}
            >
              {clearAllLabel}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export const Filter = ({
  variant = 'insights',
  dataFilters = {},
  filters = [],
  selectedTags = [],
  selectedFilters,
  containerRef,
  showTags = true,
  onClearFilters,
  onFilterChange,
  onToggleDropdownFilter,
  onRemoveDropdownFilter,
  filtersLabel,
  clearAllLabel,
  clearAllFiltersLabel,
  closeLabel,
}) => {
  return (
    <>
      <MobileFilter
        variant={variant}
        dataFilters={dataFilters}
        filters={filters}
        selectedFilters={selectedFilters}
        selectedTags={selectedTags}
        onFilterChange={onFilterChange}
        onToggleFilter={onToggleDropdownFilter}
        onClearFilters={onClearFilters}
        closeLabel={closeLabel}
        clearAllLabel={clearAllLabel}
      />
      <DesktopFilter
        variant={variant}
        dataFilters={dataFilters}
        filters={filters}
        selectedFilters={selectedFilters}
        selectedTags={selectedTags}
        containerRef={containerRef}
        showTags={showTags}
        onFilterChange={onFilterChange}
        onClearFilters={onClearFilters}
        onToggleFilter={onToggleDropdownFilter}
        onRemoveFilter={onRemoveDropdownFilter}
        filtersLabel={filtersLabel}
        clearAllFiltersLabel={clearAllFiltersLabel}
      />
    </>
  );
};

export function toggleMultipleFilter(
  filters,
  type,
  tag,
  tagType = knownInsightTagIdentifiers.id
) {
  const filterTypeTags = [...(filters[type] || [])];
  let filterIndex;

  switch (tagType) {
    case knownInsightTagIdentifiers.name:
      filterIndex = filterTypeTags.indexOf(
        tag[knownInsightTagIdentifiers.name]
      );

      if (filterIndex > -1) {
        const remaining = [...filters[type]];

        remaining.splice(filterIndex, 1);
        return {
          ...filters,
          [type]: remaining,
        };
      } else {
        return {
          ...filters,
          [type]: _.uniq([
            ...filterTypeTags,
            tag[knownInsightTagIdentifiers.name],
          ]),
        };
      }
    case knownInsightTagIdentifiers.id:
    default:
      filterIndex = filterTypeTags.indexOf(tag[knownInsightTagIdentifiers.id]);

      if (filterIndex > -1) {
        const remaining = [...filters[type]];

        remaining.splice(filterIndex, 1);
        return {
          ...filters,
          [type]: remaining,
        };
      } else {
        return {
          ...filters,
          [type]: _.uniq([
            ...filterTypeTags,
            tag[knownInsightTagIdentifiers.id],
          ]),
        };
      }
  }
}

export function toggleSingleFilter(
  filters,
  type,
  tag,
  tagType = knownInsightTagIdentifiers.id
) {
  switch (tagType) {
    case knownInsightTagIdentifiers.name:
      return {
        ...filters,
        [type]:
          filters[type] !== tag[knownInsightTagIdentifiers.name]
            ? tag[knownInsightTagIdentifiers.name]
            : undefined,
      };
      break;
    case knownInsightTagIdentifiers.id:
    default:
      return {
        ...filters,
        [type]:
          filters[type] !== tag[knownInsightTagIdentifiers.id]
            ? tag[knownInsightTagIdentifiers.id]
            : undefined,
      };
  }
}

export function removeFilter(filters, tag) {
  let newFilters = {};
  Object.keys(filters).forEach((key) => {
    if (Array.isArray(filters[key])) {
      const filter = [...filters[key]];
      const filteredFilters = filter.filter((t) => {
        return (
          t[knownInsightTagIdentifiers.name] ===
            tag[knownInsightTagIdentifiers.name] ||
          t[knownInsightTagIdentifiers.id] ===
            tag[knownInsightTagIdentifiers.id]
        );
      });

      if (filteredFilters.length) newFilters[key] = filteredFilters;
    } else {
      newFilters[key] =
        filters[key] === tag[knownInsightTagIdentifiers.id] ||
        t[knownInsightTagIdentifiers.name]
          ? undefined
          : filters[key];
    }
  });
  return newFilters;
}

export function selectTags(selectedTags, tag, tagType) {
  switch (tagType) {
    case knownInsightTagIdentifiers.name:
      if (Array.isArray(selectedTags)) {
        const tagsToRemove = selectedTags.filter((t) => {
          return (
            t[knownInsightTagIdentifiers.name] ===
            tag[knownInsightTagIdentifiers.name]
          );
        });

        if (tagsToRemove.length) {
          const currentSelectedTags = selectedTags.filter((t) => {
            return (
              t[knownInsightTagIdentifiers.name] !==
              tag[knownInsightTagIdentifiers.name]
            );
          });

          return [...currentSelectedTags];
        } else {
          return [...selectedTags, tag];
        }
      }

    case knownInsightTagIdentifiers.id:
      const index = selectedTags.findIndex((t) => t.tagId === tag.tagId);
      let currentSelectedTags = [...selectedTags];
      if (index > -1) {
        currentSelectedTags.splice(index, 1);
        return [...currentSelectedTags];
      } else {
        return [...currentSelectedTags, tag];
      }
    default:
  }
  const index = selectedTags.findIndex((t) => t.tagId === tag.tagId);
  let currentSelectedTags = [...selectedTags];
  if (index > -1) {
    currentSelectedTags.splice(index, 1);
    return [...currentSelectedTags];
  } else {
    return [...currentSelectedTags, tag];
  }
}

export const knownInsightTagIdentifiers = {
  name: 'tagName',
  id: 'tagId',
};

export const knownInsightTagTypes = [
  { type: 'assetTypeTags', single: false },
  { type: 'insightTopicTags', single: false },
  { type: 'businessIssueTags', single: false },
  { type: 'industryTags', single: false },
  { type: 'areaOfExpertiseTags', single: false },
  { type: 'capabilityTags', single: false },
];

export function getDefaultFilters(knownTagTypes, qs = window.location.search) {
  const filters = {};
  const params = new URLSearchParams(qs);
  knownTagTypes.map((tagType) => {
    if (!params.has(tagType.type)) return;
    filters[tagType.type] = tagType.single
      ? params.get(tagType.type)
      : [params.get(tagType.type)];
  });

  return filters;
}

export function getPreselectedTags(data, filters = {}, selectedTags = []) {
  const initialSelectedTags = [...selectedTags];
  Object.keys(filters).map((key) => {
    const tags = data[key];
    if (!tags) return;
    if (Array.isArray(filters[key])) {
      if (!filters[key].length) return;
      filters[key].forEach((filterValue) => {
        const tag = tags.find((tag) => tag.tagId === filterValue);
        if (!tag) return;
        initialSelectedTags.push(tag);
      });
    } else {
      filterValue = filters[key];
      if (!filterValue) return;
      const tag = tags.find((tag) => tag.tagId === filterValue);
      if (!tag) return;
      initialSelectedTags.push(tag);
    }
  });
  return initialSelectedTags;
}
