import 'core-js';
import 'regenerator-runtime/runtime';
import { initializeCarousels } from './carousel-global';
import { initializeInsights } from './insights';
import { initializeEvents } from './events';
import { initializeConcierge } from './concierge';
import { initializeCaseStudies } from './case-studies';
import { initializeLatestInsightsConsultant } from './latest-insights-consultant';
import { initializeLatestInsightsEvents } from './latest-insights-events';
import { initializeLatestInsightsInsights } from './latest-insights-insights';
import { initializeLatestInsightsNewsroom } from './latest-insights-newsroom';
import { initializeLatestInsightsPodcasts } from './latest-insights-podcasts';
import { initializeSingleConsultantProfile } from './single-consultant-profile';
import { initializeSingleConsultantProfileAlt } from './single-consultant-profile-alt';
import { initializeAccordion } from './rich-text-accordion';
import { initializeInputs } from './inputs';
import { initializeGetInTouch } from './get-in-touch';
import { initializePagination } from './pagination';
import { initializeHeaderFeatureConsultantDirectory } from './header-feature-consultant-directory';
import initializeArticlesHeading from './article-heading';
import initializeArticlesPress from './article-press';
import initializeHeaderCarousel from './header-carousel';
import initializeFeatureCarousel from './feature-carousel';
import initializePodcastCarousel from './podcast-carousel';
import { initializeShareFlyout } from './share-flyout';
import initializeEventsHeading from './event-heading';
import initializeBreadcrumbs from './breadcrumbs';
import initializeNavigationHeader from './navigation_header';
import initializeNav from './nav';
import { initializeEventRegistration } from './event-registration';
import { initializeCrossLinks } from './cross-links';
import spotlightText from './spotlight-text';
import * as splash from './splash';
import initializeLightbox from './infographic';
import headerSubnav from './header-subnav';
import tabination from './tabination';
import ourValues from './our-values';
import landingSubnav from './landing-subnav';
import { initializeConsultantDirectory } from './consultant-directory';
import map from './map';
import gatedContent from './gated-content';
import headerPodcast from './header-podcast';
import { initializeRelatedArticles } from './related-articles';
import { initializeItemsList } from './items-list';
import { initializeContactHr } from './contact-hr';
import initializeContactUs from './contact-us';
import initializeEmailOnly from './email-only';
import initializeContactMedia from './contact-media';
import { initializeMarketoBase } from './marketo-base';
import { initializeLatestreadings } from './related-reading';
import { initializeBrightcoveVideoEmbed } from './brightcove-video-embed';
import { initializeSearchFilter } from './search-filter';
import { initializeAuthorsList } from './authors-list';
import { initializeStickyNav } from './sticky-nav';
import { initializeTabs } from './tabs';
import { initializeScrollable } from './scrollable';
import { initializeAnchors } from './anchors';
import { smoothAnchorScroll } from './util';
import { initializeGatedContentFlat } from './gated-content-flat';
import { initializeRelatedConsultants } from './related-consultants';
import videoHeaderSubnav from './video-header-subnav';
import { initializeSocialShare } from './social-share';
import { initializePodcastText } from './podcast-text';
import { initializeSimilarConsultants } from './similar-consultants';
import { initializePodcastPlayer } from './podcast-player';
import { initializeExperienceEditor } from './experience-editor';
import initializeInTheMedia from './inthemedia';
import { initializeAccordions } from './accordion';

export function initialize() {
  splash.initializeSplash();
  map();
  tabination();
  initializeInsights();
  initializeEvents();
  gatedContent();
  initializeGatedContentFlat();
  initializeConcierge();
  initializeCaseStudies();
  initializeLatestInsightsConsultant();
  initializeLatestInsightsEvents();
  initializeLatestInsightsInsights();
  initializeLatestInsightsNewsroom();
  initializeLatestInsightsPodcasts();
  initializeRelatedConsultants();
  initializeSingleConsultantProfile();
  initializeSingleConsultantProfileAlt();
  initializeAccordion();
  initializeInputs();
  initializeGetInTouch();
  initializePagination();
  initializeShareFlyout();
  initializeArticlesHeading();
  initializeHeaderCarousel();
  initializeEventsHeading();
  initializeBreadcrumbs();
  initializeHeaderFeatureConsultantDirectory();
  initializeFeatureCarousel();
  initializeNavigationHeader();
  initializeNav();
  initializeEventRegistration();
  spotlightText();
  initializeLightbox();
  headerSubnav();
  initializeCrossLinks();
  initializeRelatedArticles();
  initializeItemsList();
  ourValues();
  landingSubnav();
  initializeArticlesPress();
  initializeContactHr();
  initializeConsultantDirectory();
  initializeSimilarConsultants();
  initializeLatestreadings();
  initializeBrightcoveVideoEmbed();
  initializeSearchFilter(undefined, true);
  initializeSearchFilter('.sticky-bar', true);
  initializeStickyNav();
  initializeTabs();
  initializeScrollable();
  initializeAnchors();
  initializeContactUs();
  initializeEmailOnly();
  initializeContactMedia();
  initializeAuthorsList();
  initializeCarousels();
  smoothAnchorScroll();
  headerPodcast();
  videoHeaderSubnav();
  initializeSocialShare();
  initializePodcastCarousel();
  initializePodcastText();
  initializePodcastPlayer();
  initializeExperienceEditor();
  initializeInTheMedia();
  initializeAccordions();

  // This has to run after all
  // - artur
  initializeMarketoBase();
}

export function resetSplash() {
  splash.resetSplash();
}
