import {
  similarConsultants
} from './mockdata';

const apiUrl = import.meta.env?.STORYBOOK ?
  'https://mc-8d6f7bc1-40fc-4234-bf29-2580-cd.azurewebsites.net/' :
  `${window.location.origin}/`;

export async function getLatestInsightsConsultantsData(page = 1, contextPageId, filters, language="en") {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    pagination: {
      currentPageNumber: page - 1,
    },
    filters,
    contextPageId,
    language
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const response = await fetch(
    `${apiUrl}/en/author/api/insights`,
    requestOptions
  );
  const data = await response.json();
  return data;
}

export async function getLatestInsightsInsightData(
  page = 1,
  filters,
  contextPageId,
  datasourceId,
  language = "en"
) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    pagination: {
      currentPageNumber: page - 1,
    },
    filters,
    datasourceId,
    contextPageId,
    language,
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };
  const response = await fetch(
    `${apiUrl}/en/insight/api/insights`,
    requestOptions
  );
  const data = await response.json();
  return data;
}

export async function getLatestInsightsEventsData(
  page = 1,
  filters,
  contextPageId,
  datasourceId,
  language = "en"
) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    pagination: {
      currentPageNumber: page - 1,
    },
    filters,
    datasourceId,
    contextPageId,
    language
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };
  const response = await fetch(
    `${apiUrl}/en/insight/api/events`,
    requestOptions
  );
  const data = await response.json();
  return data;
}

export async function getLatestInsightsPodcastData(
  page = 1,
  filters,
  contextPageId,
  datasourceId,
  language ="en"
) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    pagination: {
      currentPageNumber: page - 1,
    },
    filters,
    datasourceId,
    contextPageId,
    language,
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };
  const response = await fetch(
    `${apiUrl}/en/insight/api/podcasts`,
    requestOptions
  );
  const data = await response.json();
  return data;
}

export async function getInsightsShowcaseData(filters = {}, datasourceId, contextPageId, language = "en") {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    filters,
    datasourceId,
    contextPageId,
    language
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const response = await fetch(
    `${apiUrl}/en/insight/api/latestinsightsshowcase`,
    requestOptions
  );
  const data = await response.json();
  if (data.insights && data.insights.length) {
    data.insights.forEach((insight, index) => {
      insight.featured = index === 0 || index === 5;
    });
  }
  return data;
}

export async function getConsultantDirectoryData(
  page = 1,
  filters = {},
  datasourceId,
  language = "en"
) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    pagination: {
      currentPageNumber: page - 1,
    },
    filters,
    datasourceId,
    language
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const response = await fetch(
    `${apiUrl}/en/profile/api/profiles`,
    requestOptions
  );
  const data = await response.json();
  return data;
}

export async function getSimilarConsultants(
  page = 1,
  datasourceId = '',
  profileId = '',
  language = "en"
) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    pagination: {
      currentPageNumber: page - 1,
    },
    profileId,
    datasourceId,
    language
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const response = await fetch(
    `${apiUrl}/en/profile/api/similarprofiles`,
    requestOptions
  );
  const data = await response.json();
  return data;
}

export async function setEngagementGoal(goalId) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    goalId: goalId,
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const response = await fetch(`${apiUrl}goal/api/trigger`, requestOptions);
  const data = await response.json();
  return data;
}

export const ENGAGEMENT_GOAL_TYPES = {
  COMPLETED_LEAD_GEN: '{C326E71F-CA28-42F0-8133-3815EF1C6ECB}',
  DOWNLOAD_WHITEPAPER_REPORT: '{0184305D-FE20-436B-9C28-ED677BEB52A2}',
  EMAIL_CONSULTANT: '{F1D2644D-CFD0-47CA-82D5-97EFA4FC8852}',
  PREFERENCES_CENTER_ADD_PREFERENCES: '{3440A8D3-BB40-462B-9ABA-23C9BB181475}',
  PREFERENCES_CENTER_REMOVE_PREFERENCES: '{CEA4548F-D00A-4BDE-81FB-CD0E2EC63075}',
  INTERVIEW_PODCAST: '{931D9CD8-90E3-45CB-8C49-72E933992BEC}',
  REQUEST_INVITATION: '{D20F257F-FB7E-4D76-A16C-5AB15DF76979}',
  INTERACT_MAP: '{A931ECBB-87BC-42EC-83F3-28E5320678D8}',
  FILLED_OUT_FORM: '{BC50C97A-0FFA-4979-B887-377BBE2A9FDE}',
};
