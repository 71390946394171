import * as React from 'react';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';
import { debounce } from 'lodash';
import gsap from 'gsap';
import { decorateConsultantDirectory } from '../consultant-directory';
import { initializeAnimation } from '../global-animation';
import * as api from '../api/request';
import { Pager } from './Pager';
import {
  knownInsightTagIdentifiers,
  removeFilter,
  FilterMobileNavItem,
  selectTags,
  isFilterSelected,
  toggleMultipleFilter,
  toggleSingleFilter,
} from './ConsultantDirectoryFilter';
import { getImagePath, placeholder1xImagePath } from '../util';
import { consultantDirectoryData } from '../api/mockdata';
import { filter } from 'core-js/core/array';
import {
  getPreselectedTagsFromFacets,
} from './Filter';
import DOMPurify from 'isomorphic-dompurify';

function useDynamicRefs() {
  const dynamicRefs = new Map();

  function getRef(key = '') {
    if (key === '') return;
    return dynamicRefs.get(key);
  }

  function setRef(key = '') {
    if (key === '') return;
    const ref = React.createRef();
    dynamicRefs.set(key, ref);
    return ref;
  }
  return [getRef, setRef];
}

const MobileNavItem = ({
  hidden,
  name,
  filterItems = [],
  onToggleFilter,
  innerRef,
  open,
  onOpen,
}) => {
  const handleIsFilterSelected = (tag, tagType) => {
    switch (tagType) {
      case knownInsightTagIdentifiers.name:
        if (Array.isArray(selected)) {
          const foundTags = selected.filter((t) => {
            return (
              t[knownInsightTagIdentifiers.name] ===
              tag[knownInsightTagIdentifiers.name]
            );
          });
          return !!foundTags.length;
        }
        break;
      case knownInsightTagIdentifiers.id:
      default:
        if (Array.isArray(selected)) {
          const foundTags = selected.filter((t) => {
            return (
              t[knownInsightTagIdentifiers.id] ===
              tag[knownInsightTagIdentifiers.id]
            );
          });
          return !!foundTags.length;
        }
        break;
    }
  };
  return (
    <div
      className={classNames({
        'consultant-directory__mobile-nav__item': true,
        'consultant-directory__mobile-nav__item--hidden': hidden,
        active: open,
      })}
      ref={innerRef}
    >
      <div
        className="consultant-directory__mobile-nav__item-title"
        onClick={(e) => onOpen && onOpen()}
      >
        {name}
        <svg
          width="5"
          height="5"
          viewBox="0 0 5 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.5875 0.958008L2.5 2.90341L4.4125 0.958008L5 1.55692L2.5 4.10548L0 1.55692L0.5875 0.958008Z"
            fill="black"
          ></path>
        </svg>
      </div>
      <div className="consultant-directory__mobile-nav__item-filters">
        <div className="consultant-directory__items">
          {filterItems.map((tag, index) => (
            <div className="consultant-directory__item" key={index}>
              <div
                className="mobile-nav__button"
                onClick={(e) => {
                  e.stopPropagation();
                  if (onToggleFilter)
                    onToggleFilter(tag, knownInsightTagIdentifiers.id);
                }}
              >
                <svg
                  width="12"
                  height="11"
                  viewBox="0 0 12 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    y1="-0.5"
                    x2="13.1442"
                    y2="-0.5"
                    transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 1 9.29395)"
                    stroke="#4A0080"
                  ></line>
                  <line
                    x1="10.5046"
                    y1="9.64848"
                    x2="1.21024"
                    y2="0.354118"
                    stroke="#4A0080"
                  ></line>
                </svg>
                <p>{tag.tagName}</p>
                <span>{tag.tagCount}</span>
              </div>
              {'children' in tag && (
                <div className="mobile-nav__children-items">
                  {tag.children.map((childTag, childIndex) => (
                    <div
                      className={classNames({
                        'mobile-nav__children-item': true,
                      })}
                      key={childIndex}
                    >
                      <div
                        className="mobile-nav__button"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (onToggleFilter)
                            onToggleFilter(
                              childTag,
                              knownInsightTagIdentifiers.name
                            );
                        }}
                      >
                        <svg
                          width="12"
                          height="11"
                          viewBox="0 0 12 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <line
                            y1="-0.5"
                            x2="13.1442"
                            y2="-0.5"
                            transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 1 9.29395)"
                            stroke="#4A0080"
                          ></line>
                          <line
                            x1="10.5046"
                            y1="9.64848"
                            x2="1.21024"
                            y2="0.354118"
                            stroke="#4A0080"
                          ></line>
                        </svg>
                        <p>{childTag.tagName}</p>
                        <span>{childTag.tagCount}</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const MobileFilter = ({
  filterItems = [],
  onToggleFilter,
  onClearFilters,
  hasTags,
  closeLabel,
  clearAllLabel,
  filtersLabel,
}) => {
  const defaultOpen = null;
  const defaultActiveFilter = null;
  const [activeFilter, setActiveFilter] = React.useState(null);
  const [open, setOpen] = React.useState(defaultOpen);
  const [getMobileNavItemsRef, setMobileNavItemsRef] = useDynamicRefs();
  const mobileNavOpenRef = React.useRef(null);
  const mobileNavCloseRef = React.useRef(null);
  const mobileNavRef = React.useRef(null);

  const handleMobileNavItemOpen = (filter) => () => {
    setActiveFilter(activeFilter !== filter ? filter : defaultActiveFilter);
  };

  const handleClearFilters = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (mobileNavCloseRef) mobileNavCloseRef.current.click();
    if (onClearFilters) setTimeout(() => onClearFilters(), 1750);
  };

  return (
    <>
      <div className="consultant-directory__mobile-nav" ref={mobileNavRef}>
        <div className="consultant-directory__mobile-nav__inner">
          <a
            href="#"
            className="consultant-directory__mobile-nav-close"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(false);
            }}
            ref={mobileNavCloseRef}
          >
            {closeLabel}
            <img src={getImagePath('/images/close.svg')} role="presentation" />
          </a>
          <div className="consultant-directory__mobile-nav__items">
            {filterItems.map((filterItem, index) => (
              <MobileNavItem
                hidden={filterItem.hidden}
                name={filterItem.name}
                filterType={filterItem.filterType}
                filterItems={filterItem.filterItems}
                onToggleFilter={(tag, tagType) => {
                  setOpen(defaultOpen);
                  setActiveFilter(defaultActiveFilter);
                  mobileNavCloseRef.current.click();
                  setTimeout(
                    () => onToggleFilter(filterItem, tag, tagType),
                    1750
                  );
                }}
                key={index}
                open={activeFilter === filterItem.name}
                onOpen={handleMobileNavItemOpen(filterItem.name)}
                innerRef={setMobileNavItemsRef(index)}
              />
            ))}
          </div>
          {hasTags && (
            <div className="consultant-directory__mobile-nav__bottom">
              {/* <a
                      href="#"
                      className="consultant-directory__mobile-nav__apply-filters btn btn--white"
                    >
                      Apply
                    </a> */}
              <a
                href="#"
                className="consultant-directory__mobile-nav__clear-filters link--button-white"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleClearFilters();
                }}
              >
                {clearAllLabel}
              </a>
            </div>
          )}
        </div>
      </div>
      <div className="consultant-directory__filters">
        <div className="layout-container">
          <a
            href="#"
            className="consultant-directory__mobile-nav-link link--button-white"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(true);
            }}
            ref={mobileNavOpenRef}
          >
            <img src={getImagePath('images/filter-menu-white.svg')} alt="" />
            {filtersLabel}
          </a>
        </div>
      </div>
    </>
  );
};

const FilterItem = ({
  hidden = false,
  name,
  open = false,
  onToggle,
  filterItems = [],
  selected,
  onToggleFilter,
  onClearFilters,
}) => {
  const defaultActive = null;
  const [active, setActive] = React.useState(defaultActive);
  const filterTitleRef = React.useRef(null);
  const [getFilterItemRef, setFilterItemRef] = useDynamicRefs();

  const handleFilterKeyPress = (e) => {
    if (onToggle && e.key === 'Enter') {
      return onToggle();
    }
  };

  const handleFilterKeyDown = (e) => {
    if (open && e.key === 'ArrowDown') {
      const firstFilterItemRef = getFilterItemRef(0);
      if (!firstFilterItemRef) return;
      firstFilterItemRef.current.focus();
      setActive(0);
    }
  };

  const handleFilterItemKeyPress = (tag, tagType) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (onToggle && e.key === 'Enter') {
      return onToggleFilter(tag, tagType);
    }
  };

  const handleIsFilterSelected = (tag, tagType) => {
    switch (tagType) {
      case knownInsightTagIdentifiers.name:
        if (Array.isArray(selected)) {
          const foundTags = selected.filter((t) => {
            return (
              t[knownInsightTagIdentifiers.name] ===
              tag[knownInsightTagIdentifiers.name]
            );
          });
          return !!foundTags.length;
        }
        break;
      case knownInsightTagIdentifiers.id:
      default:
        if (Array.isArray(selected)) {
          const foundTags = selected.filter((t) => {
            return (
              t[knownInsightTagIdentifiers.id] ===
              tag[knownInsightTagIdentifiers.id]
            );
          });
          return !!foundTags.length;
        }
        break;
    }
  };

  const handleFilterItemKeyDown = (e) => {
    if (!open) return;
    const nextActive =
      e.key === 'ArrowDown'
        ? filterItems.length - 1
          ? active + 1
          : active
        : e.key === 'ArrowUp'
        ? active > 1
          ? active - 1
          : 0
        : active;
    setActive(nextActive);
    const nextActiveRef = getFilterItemRef(nextActive);
    if (!nextActiveRef) return;
    nextActiveRef.current.focus();
  };

  const handleToggleFilterItem = (tag, tagType) => (e) => {
    e.preventDefault();
    if (!onToggleFilter) return;
    onToggleFilter(tag, tagType);
    setActive(defaultActive);
  };

  React.useLayoutEffect(() => {
    if (open && active !== null) {
      const filterItemRef = getFilterItemRef(active);
      if (!filterItemRef) return;
      filterItemRef.current.focus();
    } else if (open && filterTitleRef) {
      filterTitleRef.current.focus();
    }
  }, [active, open]);

  return (
    <div
      className={classNames({
        filter: true,
        active: open,
        'filter--hidden': hidden,
      })}
    >
      <a
        className="title"
        aria-label="menu"
        tabIndex="1"
        ref={filterTitleRef}
        onClick={onToggle}
        onKeyPress={handleFilterKeyPress}
        onKeyDown={handleFilterKeyDown}
      >
        {name}
        <svg
          width="5"
          height="5"
          viewBox="0 0 5 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.5875 0.958008L2.5 2.90341L4.4125 0.958008L5 1.55692L2.5 4.10548L0 1.55692L0.5875 0.958008Z"
            fill="black"
          ></path>
        </svg>
      </a>

      <div className="break"></div>

      <div className="items">
        {filterItems.map((tag, index) => (
          <div
            className={classNames({
              item: true,
              active: active === index,
              selected: handleIsFilterSelected(
                tag,
                knownInsightTagIdentifiers.id
              ),
            })}
            key={index}
          >
            <a
              href="#"
              className="item__link"
              onFocus={(e) => {}}
              ref={setFilterItemRef(index)}
              onKeyPress={handleFilterItemKeyPress(tag)}
              onKeyDown={handleFilterItemKeyDown}
              onClick={handleToggleFilterItem(
                tag,
                knownInsightTagIdentifiers.id
              )}
            >
              {tag.tagName}
              <img
                className="item__close-img"
                src={getImagePath('images/close-burgundy.svg')}
              />
            </a>

            {'children' in tag && (
              <div className="item__children-items">
                {tag.children.map((childTag, childIndex) => (
                  <div
                    className={classNames({
                      'item__children-item': true,
                      'item__children-item--active': active === childIndex,
                      'item__children-item--selected': handleIsFilterSelected(
                        childTag,
                        knownInsightTagIdentifiers.name
                      ),
                    })}
                    key={childIndex}
                  >
                    <a
                      href="#"
                      className="item__link"
                      onFocus={(e) => {}}
                      ref={setFilterItemRef(childIndex)}
                      onClick={handleToggleFilterItem(
                        childTag,
                        knownInsightTagIdentifiers.name
                      )}
                    >
                      {childTag.tagName}
                      <img
                        className="item__close-img"
                        src={getImagePath('images/close-burgundy.svg')}
                      />
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const DesktopFilter = ({
  filters,
  filterItems = [],
  onToggleFilter,
  selected,
}) => {
  const defaultActiveFilter = null;
  const [activeFilter, setActiveFilter] = React.useState(null);
  const handleFilterToggle = (name) => {
    setActiveFilter(activeFilter !== name ? name : defaultActiveFilter);
  };

  return (
    <div tabIndex="-1" className="consultant-directory--sidebar-filter">
      {filterItems.map((filterItem, index) => (
        <FilterItem
          hidden={filterItem.hidden}
          name={filterItem.name}
          open={activeFilter === filterItem.name}
          filterItems={filterItem.filterItems}
          selected={selected}
          onToggle={() => handleFilterToggle(filterItem.name)}
          onToggleFilter={(tag, tagType) => {
            onToggleFilter(filterItem, tag, tagType);
          }}
          key={index}
        />
      ))}
    </div>
  );
};

class SearchBar extends React.Component {
  inputWrapperRef = React.createRef();

  inputRef = React.createRef();

  shouldComponentUpdate(nextProps) {
    return nextProps.html !== this.inputRef.current.innerHTML;
  }

  componentDidUpdate() {
    if (this.props.html !== this.inputRef.current.innerHTML) {
      this.inputRef.current.innerHTML = DOMPurify.sanitize(this.props.html);
    }
  }

  emitChange(e) {
    const { onChange } = this.props;
    if (
      !this.inputRef ||
      !this.inputRef.current ||
      !this.inputWrapperRef ||
      !this.inputWrapperRef.current
    )
      return;
    if (this.inputRef.current.innerHTML)
      this.inputWrapperRef.current.classList.add('has-content');
    else this.inputWrapperRef.current.classList.remove('has-content');
    if (!onChange) return;
    const html =
      this.inputRef.current.innerHTML.indexOf('<br') === 0
        ? ''
        : DOMPurify.sanitize(this.inputRef.current.innerHTML);
    if (html === this.lastHtml) return;
    onChange({ target: { value: html } });
    this.lastHtml = html;
  }

  render() {
    const { html } = this.props;
    return (
      <div className="consultant-directory--input-search">
        <div className="input--animation-container">
          <div className="name input--wrapper" ref={this.inputWrapperRef}>
            <label htmlFor="name">Search by name</label>
            <div
              className="input"
              id="name"
              contentEditable="true"
              autoFocus
              tabIndex="1"
              onInput={(e) => this.emitChange(e)}
              onBlur={(e) => this.emitChange(e)}
              onKeyPress={(e) => {
                if (e.key !== 'Enter') return;
                e.preventDefault();
                e.stopPropagation();
              }}
              dangerouslySetInnerHTML={{ __html: html }}
              ref={this.inputRef}
            ></div>
            <img src={getImagePath('images/search.svg')} className="icon" />
          </div>
        </div>
      </div>
    );
  }
}

export const ConsultantDirectory = ({
  datasourceId,
  defaultFilters = {},
  scrollIntoViewOnSearch = false,
  language = "en",
}) => {
  let url = new URL(location.href);
  let params = new URLSearchParams(url.search);

  const defaultPage = params.has('page') ? Number(params.get('page')) : 1;
  const firstPage = 1;
  const defaultSelectedTags = [];
  const defaultActiveFilter = null;
  const [searchText, setSearchText] = React.useState('');
  const [page, setPage] = React.useState(defaultPage);
  const [loaded, setLoaded] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [selectedTags, setSelectedTags] = React.useState(defaultSelectedTags);
  const [data, setData] = React.useState(null);
  const [filters, setFilters] = React.useState(defaultFilters);
  const contentContainerRef = React.useRef(null);
  const dataRowContainerRef = React.useRef(null);
  const consultantsContainerRef = React.useRef(null);
  const [firstLoad, setFirstLoad] = React.useState(true);

  async function loadData() {
    try {
      const hasBeenLoaded = loaded;
      setLoaded(false);
      const response = await api.getConsultantDirectoryData(
        page,
        filters,
        datasourceId,
        language
      );
      //const response = consultantDirectoryData;
      setData(response);
      setLoaded(true);      
      setError(null);

      if(firstLoad){
        const initialSelectedTags = getPreselectedTagsFromFacets(
          response,
          filters,
          selectedTags
        );
        
        if (initialSelectedTags.length) setSelectedTags(initialSelectedTags);
        setFirstLoad(false);
      }

      if (scrollIntoViewOnSearch && hasBeenLoaded && consultantsContainerRef)
        consultantsContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    } catch (e) {
      setError(e);
    }
  }

  const handleSearchTextChange = debounce((e) => {
    const freeText = e.target.value;
    setSearchText(freeText);
    setFilters({ ...filters, freeText });
    setPage(firstPage);
  }, 500);

  const handleMultipleFilterChange = (type) => (tag, tagType) => {
    setFilters(toggleMultipleFilter(filters, type, tag, tagType));
    setSelectedTags(selectTags(selectedTags, tag, tagType));
    setPage(firstPage);
    setUrl(type, tag, firstPage);
  };

  const handleSingleFilterChange = (type) => (tag, tagType) => {
    setFilters(toggleSingleFilter(filters, type, tag, tagType));
    setSelectedTags(selectTags(selectedTags, tag, tagType));
    setPage(firstPage);
    setUrl(type, tag, firstPage);
  };

  const handleRemoveFilter = (tag) => {
    console.log(filters);
    setFilters(removeFilter(filters, tag));
    setSelectedTags(selectTags(selectedTags, tag));
    setPage(firstPage);
    removeUrlParam(tag);
  };

  const handleClearFilters = () => {
    for (const key in defaultFilters){
      delete defaultFilters[key];
    }
    defaultSelectedTags.length = 0;
    setFilters(defaultFilters);
    setSelectedTags(defaultSelectedTags);
    setPage(firstPage);
    
    let location = window.location;
    window.history.pushState(null, 'Clear Filters', location.pathname);
  };

  function setUrl(type, tag, currentPage){
    let tagIds = [];

    let url = new URL(location.href);
    let params = new URLSearchParams(url.search);

    if(params.has(type) && params.get(type).length > 0){
      tagIds = params.get(type).split(',');
    }

    if(!tagIds.includes(tag.tagId)){
      tagIds.push(tag.tagId);
    } else {
      tagIds.splice(tagIds.indexOf(tag.tagId), 1);
    }

    params.set(type, tagIds.toString());
    params.set('page', currentPage);

    window.history.pushState(null, 'filter', location.pathname + '?' + decodeURIComponent(params.toString()));
  }

  function removeUrlParam(tag){
    let url = new URL(location.href);
    let params = new URLSearchParams(url.search);

    params.forEach((key, value) => {
      let tagIds = [];
      tagIds = params.get(value).split(',');

      if(tagIds.includes(tag.tagId)){
        tagIds.splice(tagIds.indexOf(tag.tagId), 1);
      }

      if(tagIds.length > 0){
        params.set(value, tagIds.toString());
      } else {
        params.delete(value);
      }
    })

    params.set('page', firstPage);

    window.history.pushState(null, 'filter', location.pathname + '?' + decodeURIComponent(params.toString()));
  }

  React.useEffect(() => {
    loadData();
  }, [filters, page]);

  const isValidData = !!data;

  React.useLayoutEffect(() => {
    if (!loaded || !isValidData) return;
    setTimeout(() => {
      initializeAnimation(contentContainerRef.current);
      initializeAnimation(dataRowContainerRef.current);
      decorateConsultantDirectory();
    }, 250);
  }, [loaded, filters]);

  function sortTags(a, b) {
    return a.tagName.toLowerCase().localeCompare(b.tagName.toLowerCase());
  }

  const filterItems = data
    ? [
        {
          name: data.areaOfExpertiseTags.description,
          hidden: false,
          single: false,
          filterType: 'areaOfExpertiseTags',
          filterItems: data.areaOfExpertiseTags.facetValues.sort(sortTags),
        },
        {
          name: data.calculatedIndustryTags.description,
          hidden: false,
          single: false,
          filterType: 'calculatedIndustryTags',
          filterItems: data.calculatedIndustryTags.facetValues.sort(sortTags),
        },
        {
          name: data.calculatedSubIndustryDescriptions.description,
          hidden: true,
          single: false,
          filterType: 'calculatedSubIndustryDescriptions',
          filterItems:
            data.calculatedSubIndustryDescriptions.facetValues.sort(sortTags),
        },
        {
          name: data.capabilityTags.description,
          hidden: false,
          single: false,
          filterType: 'capabilityTags',
          filterItems: data.capabilityTags.facetValues.sort(sortTags),
        },
        {
          name: data.cities?.description ? data.cities.description : 'Cities',
          hidden: false,
          single: true,
          filterType: 'city',
          filterItems: (data.cities?.facetCities || [])
            .map((city) => ({
              tagName: city.cityName,
              tagId: city.cityId,
            }))
            .sort(sortTags),
        },
        // Hide Title filter since title is hidden per Amy decision on 9/23
        // {
        //   name: 'Title',
        //   single: true,
        //   filterType: 'titleTag',
        //   filterItems: data.titleTags.sort(sortTags),
        // },
      ]
    : [];

  const filtersLabel =
    data && data.labels && Array.isArray(data.labels)
      ? data.labels.find((o) => o.key === 'label.filters')?.value || 'Filters'
      : 'Filters';
  const clearAllLabel =
    data && data.labels && Array.isArray(data.labels)
      ? data.labels.find((o) => o.key === 'label.clear.all')?.value ||
        'clear all'
      : 'clear all';
  const clearAllFiltersLabel =
    data && data.labels && Array.isArray(data.labels)
      ? data.labels.find((o) => o.key === 'label.clear.all.filters')?.value ||
        'clear all filters'
      : 'clear all filters';
  const closeLabel =
    data && data.labels && Array.isArray(data.labels)
      ? data.labels.find((o) => o.key === 'label.close')?.value || 'close'
      : 'close';

  return (
    <>
      {isValidData && (
        <div className="consultant-directory--wrapper">
          <div className="consultant-directory--container">
            <div
              className="consultant-directory--content"
              data-animation="false"
              ref={contentContainerRef}
            >
              <MobileFilter
                hasTags={!!selectedTags.length}
                filterItems={filterItems}
                closeLabel={closeLabel}
                clearAllLabel={clearAllLabel}
                filtersLabel={filtersLabel}
                onToggleFilter={(filterItem, tag, tagType) => {
                  if (filterItem.single) {
                    handleSingleFilterChange(filterItem.filterType)(
                      tag,
                      tagType
                    );
                  } else {
                    if (
                      filterItem.filterType === 'calculatedIndustryTags' &&
                      tagType === knownInsightTagIdentifiers.name
                    ) {
                      handleMultipleFilterChange(
                        'calculatedSubIndustryDescriptions'
                      )(tag, tagType);
                    } else {
                      handleMultipleFilterChange(filterItem.filterType)(
                        tag,
                        tagType
                      );
                    }
                  }
                }}
                onClearFilters={handleClearFilters}
              />

              <SearchBar html={searchText} onChange={handleSearchTextChange} />

              <div
                className="consultant-directory--data-row"
                data-animation="false"
                ref={dataRowContainerRef}
              >
                <DesktopFilter
                  filters={filters}
                  filterItems={filterItems}
                  onToggleFilter={(filterItem, tag, tagType) => {
                    if (filterItem.single) {
                      handleSingleFilterChange(filterItem.filterType)(
                        tag,
                        tagType
                      );
                    } else {
                      if (
                        filterItem.filterType === 'calculatedIndustryTags' &&
                        tagType === knownInsightTagIdentifiers.name
                      ) {
                        handleMultipleFilterChange(
                          'calculatedSubIndustryDescriptions'
                        )(tag, tagType);
                      } else {
                        handleMultipleFilterChange(filterItem.filterType)(
                          tag,
                          tagType
                        );
                      }
                    }
                  }}
                  selected={selectedTags}
                />

                <div
                  className="consultant-directory--consultants"
                  ref={consultantsContainerRef}
                >
                  {!!selectedTags.length && (
                    <div className="consultant-directory--selected-filters">
                      {selectedTags.map((selectedTag, index) => (
                        <div className="filter" key={index}>
                          <a
                            href="#"
                            tabIndex="1"
                            onClick={(e) => {
                              e.preventDefault();
                              handleRemoveFilter(selectedTag);
                            }}
                          >
                            <img
                              src={getImagePath('/images/close.svg')}
                              alt=""
                            />
                          </a>
                          {selectedTag.tagName}
                        </div>
                      ))}
                      <div className="clear-filters-wrapper">
                        <a
                          href="#"
                          className="clear-filters link--button"
                          tabIndex="1"
                          onClick={(e) => {
                            e.preventDefault();
                            handleClearFilters();
                          }}
                        >
                          {clearAllFiltersLabel}
                        </a>
                      </div>
                    </div>
                  )}
                  {(data.profiles || []).map((profile, index) => (
                    <div className="consultant" key={index}>
                      <a href={profile.url} tabIndex="1">
                        <div className="image">
                          <img
                            src={profile.imageUrl || placeholder1xImagePath}
                            alt={profile.imageAlt || profile.fullName}
                          />
                        </div>
                        <div className="name">{profile.fullName}</div>
                        {/* Removing title per Amy for MVP per decision on 9/23 */}
                        {/* <div className="roles">{profile.titleTag?.tagName}</div> */}
                        <div className="location-wrapper">
                          <div className="location">
                            <img
                              src={getImagePath('/images/map-marker.svg')}
                              alt=""
                            />
                            {/*<span>{profile.location.city.cityName}</span> */}
                            {(profile.cities || []).map((city, index) => (
                              <span key={index}>{city.cityName}</span>
                            ))}
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <Pager
            page={page}
            pageSize={data.pageSize}
            totalResults={data.totalNumberOfResults}
            onChange={(pageNumber) => setPage(pageNumber)}
            updateUrl={true}
          />
        </div>
      )}
    </>
  );
};
