export function initializeGatedContentFlat() {
  const els = document.querySelectorAll('.gated-content--form');
  for (let index = 0; index < els.length; index += 1) {
    const el = els[index];
    if (!el || el.parentElement.classList.contains('gated-content--side'))
      return;

    const formEl = el.querySelector('form');
    const { marketoFormId: formId } = formEl.dataset;

    document.addEventListener(`marketo-form-loaded-${formId}`, () => {
      const rows = el.querySelectorAll('.mktoFormRow');
      if (!rows.length) return;

      const placeholders = [];

      rows.forEach((row) => {
        const logicField = row.querySelector('.mktoLogicalField');
        if (logicField) {
          row.classList.add('marketo-logic-field');
          const input = logicField.querySelector('input');
          input.addEventListener('change', (e) => {
            setTimeout(() => {
              placeholders.forEach((placeholder) => {
                const fieldWrap = placeholder.querySelector(
                  '.mktoFieldDescriptor .mktoFieldWrap'
                );
                if (fieldWrap && fieldWrap.querySelector('select')) {
                  fieldWrap.classList.add('has-value');
                }
                if (fieldWrap) {
                  const inputs = Array.from(
                    fieldWrap.querySelectorAll('input, textarea')
                  );
                  inputs.forEach((currentInput) => {
                    currentInput.addEventListener('change', (evt) => {
                      if (evt.target.value.length > 0) {
                        fieldWrap.classList.add('has-value');
                      } else {
                        fieldWrap.classList.remove('has-value');
                      }
                    });
                  });
                }
              });
            }, 150);
          });
        } else if (row.querySelector('.mktoPlaceholder')) {
          placeholders.push(row);
        }
      });
    });
  }
}
