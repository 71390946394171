import * as React from 'react';
import { render } from 'react-dom';
import { SimilarConsultants } from './components/SimilarConsultants';

export function initializeSimilarConsultants() {
  const root = document.getElementById('similar-consultants-root');
  if (typeof root?.dataset?.datasourceId === 'undefined') return;
  if (typeof root?.dataset?.profileId === 'undefined') return;

  render(
    <SimilarConsultants
      title={root?.dataset?.title}
      datasourceId={root.dataset.datasourceId}
      profileId={root.dataset.profileId}
      language={root.dataset.language}
    />,
    root
  );
}
