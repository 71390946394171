export function initializeInputs() {
  let loaded;

  const onInput = function (e) {
    const parent = e.target.parentNode;
    const data = e.data;

    if (data) {
      parent.classList.add('has-content');
    } else {
      parent.classList.remove('has-content');
    }
  };

  const onKeydown = function (e) {
    const isTextarea = e.target.classList.contains('text-area');

    if (!isTextarea) {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    }
  };

  const fn = function () {
    const inputs = document.querySelectorAll('.input');

    if (loaded) return;

    for (let index = 0; index < inputs.length; index++) {
      const input = inputs[index];

      input.addEventListener('input', onInput);
      input.addEventListener('keydown', onKeydown);
    }

    loaded = true;
  };

  document.addEventListener('DOMContentLoaded', fn, false);
}
