import { setEngagementGoal, ENGAGEMENT_GOAL_TYPES } from './api/request';

export function initializeContactHr() {
  const els = document.querySelectorAll('.contact-hr--container');
  for (let index = 0; index < els.length; index += 1) {
    const el = els[index];
    if (!el) return;

    const formEl = el.querySelector('form');
    const { marketoFormId: formId } = formEl.dataset;

    let form;

    document.addEventListener(`marketo-form-loaded-${formId}`, () => {
      form = MktoForms2.getForm(formId);
      if (!form) return;

      form.onSuccess(function (values, followUpUrl) {
        // API call to sitecore backend to set user engagement score for successfully filling out a form
        setEngagementGoal(ENGAGEMENT_GOAL_TYPES.FILLED_OUT_FORM);
        el.querySelector('.contact-hr--confirmation').style.display = 'block';
        el.querySelector('.contact-hr--form').style.display = 'none';
        return false;
      });

      // eslint-disable-next-line func-names
      form.onSubmit(function (evt) {
        form.submittable(true);
        return true;
      });
    });
  }
}
