import * as React from 'react';
import { render } from 'react-dom';
import gsap from 'gsap';
import { getDefaultFilters, knownDirectoryTagTypes } from './components/Filter';
import { ConsultantDirectory } from './components/ConsultantDirectory';

function filterOnClick(evt, filterEls) {
  if (!evt.srcElement.classList.contains('title')) return;
  const path = evt.path || (evt.composedPath && evt.composedPath());
  const target = path[1];
  const shouldAdd = !target.classList.contains('active');

  filterEls.forEach((filterEl) => {
    filterEl.classList.remove('active');
  });

  if (shouldAdd) target.classList.add('active');
}

function itemOnMouseenter(target, filterEls) {
  filterEls.forEach((el) => {
    if (el !== target) {
      el.classList.add('not-hover');
    }
  });
}

function itemOnMouseleave(target, filterEls) {
  filterEls.forEach((el) => {
    el.classList.remove('not-hover');
  });
}

export function decorateConsultantDirectory(demo = false) {
  const el = document.querySelector('.consultant-directory--container');

  if (!el) return;

  if (demo) {
    const sidebar = el.querySelector('.consultant-directory--sidebar-filter');
    if (sidebar) {
      const filterEls = sidebar.querySelectorAll('.filter');

      filterEls.forEach((filterEl, filterIndex) => {
        filterEl.addEventListener('click', (evt) =>
          filterOnClick(evt, filterEls)
        );

        filterEl.querySelector('a.title').addEventListener('focus', (e) => {
          filterEl.classList.add('active');
        });

        let focusingEl;
        filterEl.querySelector('a.title').addEventListener('keydown', (e) => {
          if (!filterEl.classList.contains('active')) return;
          if (!['ArrowDown'].includes(e.key)) return;
          e.preventDefault();
          e.stopPropagation();
          focusingEl = filterEl.querySelector('.items .item a');
          focusingEl.focus();
        });

        filterEl.addEventListener('focusout', (e) => {
          if (filterEl.contains(focusingEl)) return;

          filterEl.classList.remove('active');
        });

        let itemsEl = filterEl.querySelectorAll('.items .item');

        itemsEl.forEach((itemEl, i) => {
          itemEl.querySelector('a').addEventListener('keydown', (e) => {
            if (!filterEl.classList.contains('active')) return;
            if (!['Enter', 'ArrowDown', 'ArrowUp', 'Tab'].includes(e.key))
              return;

            let index = e.key === 'ArrowDown' ? i + 1 : i - 1;

            if (e.key === 'Tab') {
              let nextEl = filterEls[filterIndex + 1];
              if (!nextEl) {
                nextEl = el.querySelector(
                  '.consultant-directory--selected-filters .filter a'
                );
                if (nextEl) nextEl.focus();
                else el.querySelector('.consultant a').focus();
              } else {
                nextEl.querySelector('a.title').focus();
              }
            } else if (e.key === 'Enter') {
              itemEl.classList.toggle('active');

              if (itemEl.classList.contains('active')) {
                const img = document.createElement('img');
                img.src = getImagePath('images/close-burgundy.svg');
                itemEl.appendChild(img);
              } else {
                const img = itemEl.querySelector('img');
                itemEl.removeChild(img);
              }
            } else {
              if (index < 0) return;
              focusingEl = itemsEl[index].querySelector('a');
              focusingEl.focus();
            }

            e.preventDefault();
            e.stopPropagation();
          });
        });
      });

      const itemEls = sidebar.querySelectorAll('.items .item');

      itemEls.forEach((itemEl) => {
        itemEl.addEventListener('mouseenter', (evt) =>
          itemOnMouseenter(itemEl, itemEls)
        );
        itemEl.querySelector('a').addEventListener('focus', (evt) => {
          itemOnMouseenter(itemEl, itemEls);
        });
        itemEl.addEventListener('mouseleave', (evt) =>
          itemOnMouseleave(itemEl, itemEls)
        );
        itemEl
          .querySelector('a')
          .addEventListener('focusout', (evt) =>
            itemOnMouseleave(itemEl, itemEls)
          );
      });
    }

    // toggle mobile filter items
    const mobileFilterItems = document.querySelectorAll(
      '.consultant-directory__mobile-nav__item'
    );

    mobileFilterItems.forEach((item) => {
      if (item.dataset.decorated) return;
      item.addEventListener('click', (e) => {
        item.classList.toggle('active');
      });
      item.dataset.decorated = true;
    });

    // toggle filter items
    const filterItem = document.querySelectorAll('.consultant-directory__item');

    filterItem.forEach((item) => {
      item.addEventListener('click', (e) => {
        item.classList.toggle('consultant-directory__item--selected');
      });
    });
  }

  // animate mobile filter flyout menu
  const mobileNavOpen = document.querySelector(
    '.consultant-directory__mobile-nav-link'
  );
  const mobileNavClose = document.querySelector(
    '.consultant-directory__mobile-nav-close'
  );
  const mobileNav = document.querySelector('.consultant-directory__mobile-nav');
  const mobileNavItems = mobileNav.querySelectorAll(
    '.consultant-directory__mobile-nav__item'
  );

  let tl = gsap.timeline({ paused: true });

  tl.to(mobileNav, {
    duration: 0.35,
    opacity: 1,
    width: '100%',
    ease: 'expo.inOut',
  });
  tl.to(mobileNavClose, {
    duration: 0.35,
    opacity: 1,
    visibility: 'visible',
    ease: 'expo.inOut',
  });
  tl.from(mobileNavItems, {
    duration: 0.35,
    opacity: 0,
    y: '2rem',
    stagger: 0.2,
    ease: 'expo.inOut',
  });

  if (!mobileNavOpen.dataset.decorated) {
    mobileNavOpen.addEventListener('click', (e) => {
      e.preventDefault();
      tl.play();
    });
    mobileNavOpen.dataset.decorated = true;
  }

  if (!mobileNavClose.dataset.decorated) {
    mobileNavClose.addEventListener('click', (e) => {
      e.preventDefault();
      tl.reverse();
    });
    mobileNavClose.dataset.decorated = true;
  }
}

export function initializeConsultantDirectory() {
  const root = document.getElementById('consultant-directory-root');
  if (typeof root?.dataset?.datasourceId === 'undefined') return;
  if (root)
    render(
      <ConsultantDirectory 
        datasourceId={root.dataset.datasourceId} 
        defaultFilters={getDefaultFilters(knownDirectoryTagTypes)}
        language={root.dataset.language}
      />,
      root
    );
}
