import GLightbox from 'glightbox';

export default function () {
  function createCloseButton() {
    // create new close button markup
    const closeButton = document.querySelector('.gclose');
    closeButton.innerHTML = `<span class="close-inner">
          <span>Close</span>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.646447" y1="12.6673" x2="12.6673" y2="0.646445" stroke="white"/>
            <line x1="0.353553" y1="0.66726" x2="12.3744" y2="12.6881" stroke="white"/>
          </svg>
        </span>`;
  }

  const lightbox = GLightbox({
    selector: '.glightbox',
  });

  lightbox.on('open', (target) => {
    createCloseButton();
  });
}
