export function hoverLink(container, page) {
  const target = container.querySelector('.pagination__underline');
  const links = container.querySelectorAll('.pagination__items a');

  for (let i = 0; i < links.length; i++) {
    if (links[i].parentNode.classList.contains('active')) {
      links[i].parentNode.classList.remove('active');
    }
  }

  links.forEach((link) => {
    if (link.innerHTML !== String(page)) return;
    link.parentNode.classList.add('active');
    const width = link.getBoundingClientRect().width;
    const left = link.getBoundingClientRect().left;
    target.style.width = `${width}px`;
    target.style.left = `${left}px`;
    target.classList.add('hover');
  });
}

export function initializePagination() {
  const pagers = document.querySelectorAll('.pagination');
  if (!pagers || !pagers.length) return;

  pagers.forEach((pager) => {
    const target = pager.querySelector('.pagination__underline');
    const links = pager.querySelectorAll('.pagination__items a');

    for (let i = 0; i < links.length; i++) {
      links[i].addEventListener('mouseenter', () =>
        hoverLink(pager, links[i].innerHTML)
      );
    }

    function windowResize() {
      const active = document.querySelector('.pagination__items li.active');

      if (active) {
        const left = active.getBoundingClientRect().left + window.pageXOffset;
        const top = active.getBoundingClientRect().top + window.pageYOffset;

        target.style.left = `${left}px`;
        target.style.top = `${top}px`;
      }
    }

    window.addEventListener('resize', windowResize);
  });
}
