export function initializeShareFlyout() {
  const shareFlyouts = document.querySelectorAll('.share-flyout');
  const body = document.body;

  if (!shareFlyouts) {
    return;
  }

  function shareFlyoutHover() {
    shareFlyouts.forEach((shareFlyout) => {
      shareFlyout.classList.toggle('show-links');
    });
  }

  shareFlyouts.forEach((shareFlyout) => {
    shareFlyout.addEventListener('click', (e) => {
      e.stopPropagation();
      shareFlyoutHover();
    });
  });

  body.addEventListener('click', (e) => {
    shareFlyouts.forEach((shareFlyout) => {
      shareFlyout.classList.remove('show-links');
    });
  });
}
