import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default function () {
  const el = document.querySelector('.header-subnav--container');

  if (!el) {
    return;
  }

  const classes = {
    active: 'active',
  };

  /**
   * Toggle the header subnav filters on mobile.
   */
  function headerSubnavFiltersMobile() {
    // The mobile label is the first element in the container and our trigger.
    const filterButton = document.querySelector(
      '.header-subnav--filters .mobile-label'
    );
    // The trigger is our parent.
    if (!filterButton) return;
    const filterContainer = filterButton.parentElement;
    // We dynamically get the height of the container so we can reset back to it.
    const filterHeight = window.getComputedStyle(filterContainer).height;

    // Toggle the filter container height on click.
    filterButton.addEventListener('click', () => {
      if (!filterContainer.classList.contains(classes.active)) {
        filterContainer.classList.add(classes.active);
        gsap.to(filterContainer, { height: 'auto' });
      } else {
        filterContainer.classList.remove(classes.active);
        gsap.to(filterContainer, { height: filterHeight });
      }
    });
  }

  headerSubnavFiltersMobile();
}
