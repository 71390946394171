import Glide from '@glidejs/glide';

export function initializeEvents() {
  const eventsCarousel = document.querySelector('.events--carousel');

  if (!eventsCarousel) {
    return;
  }

  /**
   * Instantiate the events carousel.
   */
  new Glide('.events--carousel', {
    type: 'carousel',
    perView: 3,
    gap: 72,
    breakpoints: {
      768: {
        perView: 1,
      },
    },
  }).mount();
}
