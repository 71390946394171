import { initializeAnimation } from './global-animation';

export function initializePodcastText() {
  const podcastText = document.querySelector('.podcast-text--wrapper');

  if (!podcastText) {
    return;
  }

  const podcastTranscript = document.querySelector(
    '.podcast-text--transcript-body'
  );
  const openClass = 'podcast-text--transcript-body--open';
  const morePanel = document.querySelector('.podcast-text--transcript');
  const moreButton = document.querySelector('.transcript-btn-more');
  const lessButton = document.querySelector('.transcript-btn-less');

  const readMoreLess = () => {
    if (podcastTranscript.classList.contains(openClass)) {
      podcastTranscript.classList.remove(openClass);
    } else {
      podcastTranscript.classList.add(openClass);
      morePanel.dataset.animation = false;
      initializeAnimation(morePanel);
    }
  };

  moreButton.addEventListener('click', readMoreLess);
  lessButton.addEventListener('click', readMoreLess);
}
