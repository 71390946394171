import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
let prevScrollpos = window.pageYOffset;
let scrollUp = 0;
const navigationMainContainer = document.querySelector('.navigation-main-container');

let heroContainer;
let heroArray = ['.header-container','.header-subnav--wrapper','.feature-carousel--wrapper'];

heroArray.every(el => {
  heroContainer = document.querySelector(el);
  if(heroContainer){
    return false;
  }
  return true;
})

let heroImageContainer;
let heroException;
let hasException = false;
let isHeroImage = false;
let heroImageArray = [
  '.header-subnav--wrapper',
  '.feature-carousel--wrapper',
  '.header-carousel--wrapper',
  '.event-heading-wrapper',
  '.header-feature-consultant-directory--wrapper',
  '.freeform-light',
  '.podcast-carousel--wrapper',
  '.postcard-landing--container.transparent',
];

let exceptions = [
  '.event-heading--content + .event-heading--image-container',
];

heroImageArray.every(el => {
  heroImageContainer = document.querySelector(el);
  if(heroImageContainer){
    isHeroImage = true;
    exceptions.every(item => {
      heroException = heroImageContainer.querySelector(item);
      if(heroException) hasException = true;
    })
    if(!hasException) navigationMainContainer.classList.add('white-over-image');
    return false;
  }
  return true;
})

const alldropDownInputs = Array.from(
  document.querySelectorAll('.menu--dropdown input')
);

const menuMarker = document.querySelector('.menu-marker');

alldropDownInputs.forEach((dropownInput) => {
  dropownInput.addEventListener('click', (e) => {
    if(!e.target.checked){
      menuMarker.setAttribute('data-section', e.target.parentElement.getAttribute('data-section'));
    }
  })
});

const hasStickyBar = document.body.contains(document.querySelector('.sticky-bar'));
console.log(hasStickyBar);

let heroHeight = heroContainer ? heroContainer.offsetHeight : 281;
heroHeight = hasStickyBar ? heroHeight - document.querySelector('.sticky-bar').offsetHeight : heroHeight;

const scrollMax = heroHeight / 2;
const navTimeline = gsap
  .timeline({
    onReverseComplete: () => {
      const logo = document.querySelector('.icon.logo');
      navigationMainContainer.classList.remove('full-width');
      logo.classList.remove('is-black');
    },
  })
  .pause();

function useDarkColors() {
  const navigationMenu = document.querySelector('.navigation');
  navigationMenu.classList.remove('sticky-nav--white');
  navigationMenu.classList.add('sticky-nav--white--hold');
}

function handleSectionWhiteHold() {
  const navigationMenu = document.querySelector('.navigation');
  if (navigationMenu.classList.contains('sticky-nav--white--hold')) {
    navigationMenu.classList.add('sticky-nav--white');
    navigationMenu.classList.remove('sticky-nav--white--hold');
  }
}

function isNavWhite() {
  const navigationMenu = document.querySelector('.navigation');
  return (
    navigationMenu.classList.contains('sticky-nav--white') ||
    navigationMenu.classList.contains('sticky-nav--white--hold')
  );
}

function handleWhiteNav(isNavOpen) {
  if (isNavOpen && isNavWhite()) {
    //useDarkColors(); // to contrast with bg white
  } else {
    // menu was closed
    handleSectionWhiteHold();
  }
}

function closeAllUtilityNavigationExpanded() {
  alldropDownInputs.forEach((dropownInput) => {
    dropownInput.checked = true;
  });
}

function showInitialNavigationHeader(scrollPos) {
  const navigation = document.querySelector('.navigation');
  const nav = document.querySelector('nav');
  if (nav) {
    nav.style.visibility = 'visible';
    nav.style.opacity = '1';
  }
  if (navigation) {
    navigation.classList.remove('show-afterElement');
    navigation.classList.add('show-initialAfterElement');
  }
  if(navigationMainContainer){
    navigationMainContainer.classList.remove('hide-nav')
  }
  if(scrollPos > 0 & isHeroImage){
    navigationMainContainer.classList.remove('white-over-image');
  } else if (isHeroImage && !hasException) {
    navigationMainContainer.classList.add('white-over-image');
  }
}

function showScrollingNavigationHeader(scrollPos) {
  const navigation = document.querySelector('.navigation');
  const nav = document.querySelector('nav');

  if (nav) {
    nav.style.visibility = 'visible';
    nav.style.opacity = '1';
  }
  if (navigation) {
    navigation.classList.remove('show-initialAfterElement');
    navigation.classList.add('show-afterElement');
  }
  if(navigationMainContainer){
    navigationMainContainer.classList.remove('hide-nav')
  }

  // if(isHeroImage && scrollPos <= heroHeight){
  //   navigationMainContainer.classList.add('white-over-image');
  // } else {
  //   navigationMainContainer.classList.remove('white-over-image');
  // }
  if(scrollPos > 0 & isHeroImage){
    navigationMainContainer.classList.remove('white-over-image');
  } else if (isHeroImage  && !hasException) {
    navigationMainContainer.classList.add('white-over-image');
  }
}

function hideNavigationHeader() {
  const navigation = document.querySelector('.navigation');
  const nav = document.querySelector('nav');
  const marker = document.querySelector('.menu-marker');

  if (nav) {
    nav.style.visibility = 'hidden';
    nav.style.opacity = '0';
  }
  if (navigation) {
    navigation.classList.remove('show-initialAfterElement');
    navigation.classList.remove('show-afterElement');
  }
  if (marker) {
    marker.style.display = 'none';
  }
  if(navigationMainContainer){
    navigationMainContainer.classList.add('hide-nav');
    navigation.classList.remove('mega-menu--open','language--open','login--open');
  }

  closeAllUtilityNavigationExpanded();
}

function hideDisplayMenuonScroll() {
  window.addEventListener('scroll', () => {
    const currentScrollPos = window.pageYOffset;

    if(currentScrollPos < prevScrollpos){ //scrolling up
      scrollUp += prevScrollpos - currentScrollPos;
    } else {
      scrollUp = 0;
    }

    let subnavSticky = document.querySelector('.sticky-bar.fixed-bar');
    let isSubnavSticky = subnavSticky ? true : false;

    if (currentScrollPos < heroHeight) { //show over hero
      showInitialNavigationHeader(currentScrollPos);
    } else if ((scrollUp >= scrollMax) && (!isSubnavSticky)) { // scroll up
      showScrollingNavigationHeader(currentScrollPos);
    } else { // hide scroll
      hideNavigationHeader();
    }

    prevScrollpos = currentScrollPos;
  });
}

function hideSubnavonScroll(){
  const subnav = document.querySelector('.sticky-bar');

  if(subnav){
    const subnavOffset = subnav.offsetTop;

    window.addEventListener('scroll', () =>{
      if(window.pageYOffset >= subnavOffset){
        subnav.classList.add('fixed-bar');
      } else {
        subnav.classList.remove('fixed-bar');
      }
    });
  }
}

function handleNavigationListeners() {
  const navigation = document.querySelector('.navigation');
  const navLinks = document.querySelectorAll('.menu--dropdown');

  // navigation.addEventListener('mouseleave', (e) => {
  //   handleSectionWhiteHold();
  //   if (e.pageY > 308) {
  //     hideNavigationHeader();
  //   }
  // });

  navLinks.forEach((el) => {
    const marker = document.querySelector('.menu-marker');

    el.addEventListener('mouseover', (e) => {
      const markerSection = marker.getAttribute('data-section');
      if(markerSection != el.getAttribute('data-section')){
        marker.style.display = 'block';
        marker.style.left = `${el.offsetLeft}px`;
        marker.style.width = `${el.offsetWidth}px`;
        menuMarker.setAttribute('data-section', el.getAttribute('data-section'));
      }
    });

    el.addEventListener('mouseleave', (e) => {
      const megaMenu = document.querySelector('.mega-menu--open');
      if(!megaMenu){
        marker.style.display = 'none';
        menuMarker.setAttribute('data-section', '');
      }
    });
  });
}

function handleNavigationBorder() {
  const navigationHeader = document.querySelector('.navigation-header');
  // if mega menu is opened
  if (
    document.body.clientWidth >= 960 &&
    navigationHeader.style.display !== 'flex'
  ) {
    hideNavigationHeader();
  } else {
    setTimeout(() => {
      if (window.pageYOffset > MIN_Y_MENU) {
        hideNavigationHeader();
      } else {
        showNavigationHeader();
      }
    }, 1700);
  }
}

function changeMarkerPosition(currentElement) {
  const navigation = document.querySelector('.navigation');
  const marker = document.querySelector('.menu-marker');
  // open
  console.log(currentElement.parentElement);
  if (currentElement.checked) {
    marker.style.display = 'none';
    navigation.classList.remove('mega-menu--open','language--open','login--open');
  } else {
    marker.style.display = 'block';
    marker.style.left = `${currentElement.parentElement.offsetLeft}px`;
    marker.style.width = `${currentElement.offsetWidth}px`;
    navigation.classList.add('mega-menu--open');
    if(currentElement.parentElement.classList.contains('language')){
      navigation.classList.add('language--open');
    } else if(currentElement.parentElement.classList.contains('login')){
      navigation.classList.add('login--open');
    } else {
      navigation.classList.remove('language--open','login--open');
    };
  }
}

function closeOthersDropdownInput(event) {
  const dropdownInputs = document.querySelectorAll('.menu--dropdown input');
  Object.keys(dropdownInputs).forEach((key) => {
    let parentEl = dropdownInputs[key].parentElement;
    parentEl.querySelectorAll('.level-2 > li.hasChild').forEach((item, index) => {
      item.classList.toggle('active', index === 0);
    })
    if (dropdownInputs[key] !== event.target) {
      dropdownInputs[key].checked = true;
    }
  });
  changeMarkerPosition(event.target);
}

function openMenuAnimation() {
  navTimeline.play();
}

function closeMenuAnimation() {
  navTimeline.reverse();
}

function hideAllUtilityNavs() {
  const utilityMenus = document.querySelectorAll(
    '.navigation-header--utility-navigation-container'
  );

  Object.keys(utilityMenus).forEach((key) => {
    if (utilityMenus[key].classList.contains('nhun-active')) {
      utilityMenus[key].classList.remove('nhun-active');
    }
  });
}

function resetUtilityNavs() {
  hideAllUtilityNavs();
  document.querySelector('#nhun-menu-main').classList.add('nhun-active');
}

function buildNavAnimation() {
  const searchTrigger = document.querySelector('.search--trigger svg');
  const searchTriggerOpenMenu = document.querySelector(
    '.search--trigger--open-menu'
  );
  const menuTriggerButton = document.querySelector('.menu--trigger');
  const menuTrigger = document.querySelectorAll('.menu--trigger span');
  const crossTriggerButton = document.querySelectorAll('.menu--trigger--close');
  const crossTriggerSpan = document.querySelectorAll(
    '.menu--trigger--close span'
  );

  const navigationHeader = document.querySelector('.navigation-header');
  const nhImage = document.querySelector('.navigation-header--image');
  const nhUtilityNavigation = document.querySelectorAll('.nhun-active ul li');
  const nhDefaultNav = document.querySelectorAll(
    '.navigation-header--default-nav li'
  );

  const nhContact = document.querySelector('.navigation-header--contact');

  navTimeline
    .to(navigationHeader, {
      display: 'flex',
      backgroundSize: '100% 100%',
      duration: 0.49,
      ease: 'power2',
    })
    .to(menuTrigger, { width: 0, stagger: 0.14, duration: 0.2 }, 0)
    .to(
      menuTriggerButton,
      {
        visibility: 'hidden',
        display: 'none',
      },
      '>'
    )
    .to(crossTriggerButton, { visibility: 'visible', display: 'block' }, 0.49)
    .to(crossTriggerSpan, { height: '20px', stagger: 0.14 }, '>') // height
    .to(searchTrigger, { display: 'none' }, 0)
    .to(searchTriggerOpenMenu, { display: 'block' }, '>')
    .fromTo(
      nhImage,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.35,
      },
      0.28
    )
    .from(
      [...nhUtilityNavigation, ...nhDefaultNav, nhContact],
      {
        opacity: 0,
        y: '50',
        stagger: 0.1,
      },
      0.1
    )
    .to(document.documentElement, { overflow: 'hidden' }, 0);
}

function toggleNavigationHeader(event) {
  console.log('toggleNavigationHeader');
  const navigationHeader = document.querySelector('.navigation-header');
  const logo = document.querySelector('.icon.logo');
  const navigationMainContainer = document.querySelector('.navigation-main-container');

  if (navigationHeader) {
    if (navigationHeader.style.display === 'flex') {
      closeMenuAnimation(navTimeline);
    } else {
      resetUtilityNavs();
      openMenuAnimation(navTimeline);
      navigationMainContainer.classList.add('full-width');
      logo.classList.add('is-black');
    }
    handleNavigationBorder();
  }
}

export default function () {
  const menuTriggerButton = document.querySelector('.menu--trigger');
  const menuTriggerCloseButton = document.querySelector(
    '.menu--trigger--close'
  );
  const navigation = document.querySelector('.navigation');

  let level2child = document.querySelectorAll('.level-2 .hasChild > .link-wrapper');

  if (!navigation) {
    return;
  }

  buildNavAnimation();

  if (
    menuTriggerButton &&
    menuTriggerButton.getAttribute('listener') !== 'true'
  ) {
    menuTriggerButton.addEventListener('click', toggleNavigationHeader);
    menuTriggerButton.setAttribute('listener', 'true');
  }

  if (
    menuTriggerCloseButton &&
    menuTriggerCloseButton.getAttribute('listener') !== 'true'
  ) {
    menuTriggerCloseButton.addEventListener('click', toggleNavigationHeader);
    menuTriggerCloseButton.setAttribute('listener', 'true');
  }

  const dropdownInputs = document.querySelectorAll('.menu--dropdown input');
  if (dropdownInputs) {
    Object.keys(dropdownInputs).forEach((key) =>
      dropdownInputs[key].addEventListener('change', (e) => {
        handleWhiteNav(!e.target.checked); // false means there's a menu opened
        closeOthersDropdownInput(e, dropdownInputs);
      })
    );
  }

  level2child.forEach((el) => {
    el.addEventListener('click', function(e){
      if(!e.currentTarget.parentElement.classList.contains('active')){
        Array.from(document.querySelectorAll('.hasChild.active')).forEach((el) => el.classList.remove('active'));
      }
      e.currentTarget.parentElement.classList.toggle('active');
    })
  });

  //MOBILE NAV
  const mobileNav = document.querySelectorAll('.sticky-bar__mobile-nav__header > a');

  if(mobileNav){
    mobileNav.forEach(el => {
      el.addEventListener('click', function(e){
        e.preventDefault();
        el.classList.toggle('open');
      })
    })
  }

  hideDisplayMenuonScroll();
  hideSubnavonScroll();
  handleNavigationListeners();

  // Close the dropdown menu if the user clicks outside of it
  window.addEventListener('click', function(event) {
    if ((event.target.type != 'checkbox') && (!event.target.matches('.link-wrapper'))) {
      closeAllUtilityNavigationExpanded();
      document.querySelector('.navigation').classList.remove('mega-menu--open','language--open','login--open');
    }
  })
}
