import * as React from 'react';
import { render } from 'react-dom';
import { RelatedConsultants } from './components/RelatedConsultants';

export function initializeRelatedConsultants() {
  const root = document.getElementById('related-consultants-root');
  if (typeof root?.dataset?.datasourceId === 'undefined') return;

  render(
    <RelatedConsultants
      title={root?.dataset?.title}
      datasourceId={root.dataset.datasourceId}
      language={root.dataset.language}
    />,
    root
  );
}
