import Glide from '@glidejs/glide';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default function () {
  const eventsCarousel = document.querySelector('.header-carousel--container');

  function onMouseEnter() {
    const targets = eventsCarousel.querySelectorAll('.active-target');
    targets.forEach((el) => {
      el.classList.add('active');
    });
  }

  function onMouseLeave() {
    const targets = eventsCarousel.querySelectorAll('.active-target');
    targets.forEach((el) => {
      el.classList.remove('active');
    });
  }

  if (!eventsCarousel) {
    return;
  }

  eventsCarousel.addEventListener('mouseenter', onMouseEnter);
  eventsCarousel.addEventListener('mouseleave', onMouseLeave);

  /**
   * Reveal the insights content.
   */
  // When we are 10% scrolled on an insight, initiate the reveal animation.
  // See https://greensock.com/docs/v3/Plugins/ScrollTrigger for details on this plugin.
  ScrollTrigger.create({
    trigger: eventsCarousel,
    start: 'top bottom',
    end: 'bottom 80%',
    onEnter: () => onMouseEnter(),
    onLeave: () => onMouseLeave(),
    onEnterBack: () => onMouseEnter(),
    onLeaveBack: () => onMouseLeave(),
  });

  /**
   * Instantiate the events carousel.
   */
  new Glide('.header-carousel--container', {
    type: 'carousel',
    perView: 1,
    autoplay: 6000,
  }).mount();
}
