export default function () {
  const el = document.querySelector('.tabination--wrapper');
  if (!el) return;

  let nav = el.querySelector('nav');
  let line = el.querySelector('.line');

  function updateLine() {
    let activeElNow = el.querySelector('.active');
    const activePos = activeElNow.getBoundingClientRect();
    const navPos = nav.getBoundingClientRect();
    const { right: lineRight } = line.getBoundingClientRect();

    const relRight = navPos.right - activePos.right;
    const relLineRight = navPos.right - lineRight;

    if (relRight < relLineRight) {
      line.style.right = `${relRight}px`;
      setTimeout(() => {
        line.style.left = `${activeElNow.offsetLeft}px`;
      }, 400);
    } else {
      line.style.left = `${activeElNow.offsetLeft}px`;
      setTimeout(() => {
        line.style.right = `${relRight}px`;
      }, 400);
    }
  }

  updateLine();

  function checkClick(evt) {
    const { srcElement: target } = evt;
    const { tagName } = target;
    if (tagName !== 'LI') return;
    const activeElNow = el.querySelector('.active');
    activeElNow.classList.remove('active');
    target.classList.add('active');

    const contentTarget = target.getAttribute('data-target');

    el.querySelector('.tab.active').classList.remove('active');
    el.querySelector(`.tab[data-value="${contentTarget}"]`).classList.add(
      'active'
    );

    const a = el
      .querySelector(`.tab[data-value="${contentTarget}"]`)
      .querySelectorAll('[data-animation="true"]');

    a.forEach((element) => {
      element.dataset.animation = false;
    });

    setTimeout(() => {
      a.forEach((element) => {
        element.dataset.animation = true;
      });
    }, 100);

    updateLine();
  }

  nav.querySelector('ul').addEventListener('click', checkClick);
}
