import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const SECTION_MENU_WHITE = 'section-menu--white';
const STICKY_NAV_WHITE = 'sticky-nav--white';

function getElementsByClass(classNames = []) {
  return classNames.map((className) => document.querySelector(`.${className}`));
}

function injectClass() {
  const className = SECTION_MENU_WHITE;
  const selectors = [
    '.office-strip', // about us
    '.landing-hero-image--container', // capabilities
    '.header-subnav--container', // careers*
    '.video-header-subnav--container',
    '.cta-spotlight', // careers, home
    '.quote-container', // consultant, consultant alternate
    '.header-feature-consultant-directory--content', // cons.Directory*
    '.event-heading-container .event-heading--image-container', // event
    '.map-container', // home
    '.feature-carousel--wrapper .glide__slides', // insights-article*
    '.landing-hero-tall--wrapper', // leadership
    '.article-heading--image-container', // long article, short article
    '.header--callout-img-outer',
    '.related-article', // long article, long article summary, short article
    '.longform-heading--image-container', // long article summary
    '.article-press--image-container', // press release article
    '.podcast-carousel', // podcast carousel
    '.header-carousel--wrapper', // header carousel
    '.freeform-landing-container', // freeform landing page
    '.postcard-landing--container.transparent',
    //'.podcast-carousel--wrapper',
  ];
  const elements = Array.from(document.querySelectorAll(selectors));
  elements.forEach((element) => {
    element?.classList.add(className);
  });
}

function createColorTrigger() {
  var sections = gsap.utils.toArray(`.${SECTION_MENU_WHITE}`);

  const navigation = document.querySelector('.navigation');
  if (!navigation) return;

  const onEnter = () => {
    const sticky = Number(navigation.dataset.sticky || 0) + 1;
    navigation.dataset.sticky = sticky;
    navigation.classList.add(STICKY_NAV_WHITE);
  };
  const onEnterBack = onEnter;

  const onLeave = () => {
    const sticky = Number(navigation.dataset.sticky || 0) - 1;
    const newSticky = sticky < 0 ? 0 : sticky;
    navigation.dataset.sticky = newSticky;
    if (newSticky === 0) navigation.classList.remove(STICKY_NAV_WHITE);
  };
  const onLeaveBack = onLeave;

  sections.forEach((section) => {
    ScrollTrigger.create({
      onEnter,
      onEnterBack,
      onLeave,
      onLeaveBack,
      trigger: section,
      start: 'top top+=60',
      end: 'bottom top+=60',
      markers: false,
    });
  });
}

function topFunction() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

export function initializeStickyNav() {
  injectClass();
  createColorTrigger();

  const backtotop = document.querySelector('.backtotop');
  if(backtotop) backtotop.addEventListener('click', topFunction);
}
